WiseMetering.Views.ShowNextbittIntegration = WiseMetering.Views.Modal.Show.extend({
    className: 'mbx-info text-left',
    template: 'templates/integrations/nextbitt/show',
    title: () => i18next.t('modal.title.integrations.show'),

    edit: function() {
        new WiseMetering.Views.EditNextbittIntegration({ model: this.model });
    },

    serializeData: function() {
        return {
            ...this.model.toJSON(),
            building: this.model.building(),
            configurationName: this.model.configuration().get('name'),
            indicator: this.model.indicator(),
            integrationAssets: this.model.get('assets')
        };
    }
});
