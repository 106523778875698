WiseMetering.Views.Generate = WiseMetering.Views.Modal.Custom.extend({
    className: 'mbx-info',
    buttons: ['yes', 'no'],
    template: 'templates/tenant_invoices/generate_all',
    title: () => i18next.t('modal.title.tenant_invoices.regenerate'),

    yes: function() {
        this.block();
        const models = _.map(this.ids, id => this.collection.get(id));

        $.when.apply($, _.map(models, model => model.reGenerate({ wait: true }))).then(function() {
            WiseMetering.layout.showTipper('success', i18next.t('ui.table.regeneration_requested'));
        }).fail(function() {
            WiseMetering.layout.showTipper('error', i18next.t('ui.table.regeneration_failed'));
        }).always(function() {
            this.close();
        }.bind(this));
    }
});
