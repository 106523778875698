WiseMetering.Views.ShowTenantsByOrganization = WiseMetering.Views.Content.extend({
    initialize: function() {
        this.icon = 'tenants';
        this.name = i18next.t('glossary.tenants');
        this.tabs = [
            {
                id: 'details',
                name: i18next.t('common.details'),
                view: WiseMetering.Views.IndexTenantsByOrganization
            },
            {
                id: 'invoices',
                name: i18next.t('glossary.proforma_invoices'),
                view: WiseMetering.Views.IndexTenantInvoices
            },
            {
                id: 'history',
                name: i18next.t('common.history'),
                view: WiseMetering.Views.IndexInactiveTenantsByOrganization
            }
        ];
    }
});
