WiseMetering.Views.Objectives = Backbone.Marionette.Layout.extend({
    template: 'templates/zones/objectives',
    className: 'dashboard full-height',

    regions: {
        table: '#table'
    },

    bindObjectiveChanges: function() {
        const doBind = type => data => this.listenTo(data, type, () => this.redrawWidget());

        doBind('add')(WiseMetering.objectives);
        doBind('remove')(WiseMetering.objectives);
        getCurrentObjectives(this.model.id).forEach(doBind('change'));
    },

    unbindObjectiveChanges() {
        this.stopListening(WiseMetering.objectives, 'add');
        this.stopListening(WiseMetering.objectives, 'remove');

        getCurrentObjectives(this.model.id).forEach(model => {
            this.stopListening(model, 'change');
        });
    },

    onRender: function() {
        this.table.show(new WiseMetering.Views.ObjectivesAccordion({ model: this.model }));
        this.prepareWidget();
    },

    onShow: function() {
        this.renderWidget();
        this.bindObjectiveChanges();
    },

    onClose: function() {
        Object.values(this.widgets).forEach(w => w.close());
        this.unbindObjectiveChanges();
    },

    prepareWidget: function() {
        this.widgets = {};

        const buildWidget = (data, title, unit) => {
            const el = `#widgets`;
            return new WiseMetering.Views.Ui.Widget.Objective({ el, data, title, unit, period: 'ytd' });
        };

        const objectives = WiseMetering.objectives.where({
            building_id: this.model.id,
            year: moment().year()
        });

        objectives.forEach(function(objective) {
            const
                data = WiseMetering.Analytics.getObjectiveAnalysis(objective),
                indicator = objective.indicator(),
                name = indicator.get('name'),
                type = objective.get('analysis_type'),
                unit = type === 'cost' ? null : indicator.get('unit');

            if (_.isEmpty(data)) return;

            this.widgets[`${name}_objective_${type}`] = buildWidget(data, `${name} - ${i18next.t(`common.${type}`)}`, unit);
        }.bind(this));
    },

    renderWidget: function() {
        Object.values(this.widgets).forEach(w => w.render(false));
        this.$('#widgets').append('<div class="ghost-item">');
        Lmit.Utils.triggerResize();
        Object.values(this.widgets).forEach(w => w.drawChart());
    },

    redrawWidget: function() {
        this.$el.find('#widgets').empty();
        this.prepareWidget();
        this.renderWidget();
    }

});

const getCurrentObjectives = indicator_id => {
    const year = moment().year();
    return WiseMetering.objectives.where({ year, indicator_id });
};
