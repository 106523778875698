import { Card, CardActions, CardContent, Typography } from '@mui/material';
import * as React from 'react';

const Component = props => {
    const { human_bucket_size } = props.data;

    return (
        <Card>
            <CardContent sx={{ height: 260 }}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Buckets
                </Typography>
                <Typography variant="body2">
                    Size: {human_bucket_size}
                </Typography>
            </CardContent>
            <CardActions sx={{ height: 20 }}>
            </CardActions>
        </Card>
    );
};

export default Component;
