WiseMetering.Views.IndicatorDetailsAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/seus/indicator_details',

    modelEvents: {
        'change': 'render'
    },

    editAttributes: function(event) {
        event.stopPropagation();
        new WiseMetering.Views.EditSeuIndicator({ model: this.model });
    },

    serializeData: function() {
        let json = this.model.toJSON(),
            indicator = WiseMetering.indicators.get(this.options.id);

        json.type = this.options.type === 'indicator' ? 'Indicator' : 'Independent Variable';
        json.name = indicator.get('name');
        return json;
    }
});
