WiseMetering.Views.LocationEdit = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/zones/address',
    className: 'mbx-info text-left',
    form_prefix: 'address',
    title: () => i18next.t('modal.title.address.edit'),
    input_readers: [{
        type: 'default',
        func: function(el) {
            return el.val() === '' ? null : el.val();
        }
    }],
    width: '75%',
    map: null,
    marker: null,

    modelEvents: {
        'change': 'render'
    },

    events: {
        'keyup #address': 'search'
    },

    onShow: function() {
        this.mapOptions = {
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControlOptions: { mapTypeIds: [] }
        };
        this.mapOptions.center = new google.maps.LatLng(
            this.model.get('latitude'),
            this.model.get('longitude')
        );
        this.codeAddress(this.getLatLng());
    },

    afterSave: function() {
        return this.options.building.fetch();
    },

    codeAddress: function(address) {
        let geocoder = new google.maps.Geocoder(),
            params = {};

        if (this.isValidLatLong(address)) {
            const latLng = JSON.parse(`[${address}]`);
            params['location'] = { lat: latLng[0], lng: latLng[1] };
        } else {
            params['address'] = address;
        }

        geocoder.geocode(params, function(results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var position = results[0].geometry.location;
                this.createMap(position);
            } else {
                alert('Geocode was not successful for the following reason: ' + status);
            }
        }.bind(this));
    },

    createMap: function(center) {
        var that = this;
        that.map = new google.maps.Map(document.getElementById('map_canvas'), that.mapOptions);
        google.maps.event.addListener(that.map, 'zoom_changed', function() {
            that.mapOptions.zoom = that.map.getZoom();
        });
        that.map.setCenter(center);
        that.marker = new google.maps.Marker({
            icon: WiseMetering.mapIcons.building.default,
            map: that.map,
            position: center,
            draggable: true
        });
        that.setLatLng(that.marker.getPosition());
        google.maps.event.addListener(that.marker, 'dragend', function() {
            center = that.marker.getPosition();
            that.map.setCenter(center);
            that.setLatLng(center);
        });
    },

    getLatLng: function() {
        return `${this.model.get('latitude')}, ${this.model.get('longitude')}`;
    },

    isValidLatLong: function(latlong) {
        const
            latLongRegex = /^(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)$/,
            match = latLongRegex.exec(latlong);

        if (!match) {
            return false;
        }

        const
            latitude = parseFloat(match[1]),
            longitude = parseFloat(match[3]);

        return latitude >= -90 && latitude <= 90 && longitude >= -180 && longitude <= 180;
    },

    search: function(event) {
        if ((event.keyCode || event.which) === 13) {
            event.stopPropagation();
            event.preventDefault();

            const address = document.getElementById('address').value;
            this.codeAddress(address);
        }
    },

    setLatLng: function(position) {
        this.$('input#zone_latitude').val(position.lat());
        this.$('input#zone_longitude').val(position.lng());
    }
});
