WiseMetering.Model.Zone = WiseMetering.Model.Building.extend({
    defaults: {
        area: null,
        capacity: null,
        description: '',
        latitude: null,
        longitude: null,
        name: ''
    },
    urlRoot: '/zones',

    activeEnergy() {
        return WiseMetering.indicators.findWhere({
            parent_id: this.id,
            kind_id: WiseMetering.indicatorKinds.findWhere({ name: 'Active Energy' }).id
        });
    },

    building() {
        return WiseMetering.zones.get(this.get('building_id'));
    },

    category() {
        return WiseMetering.zoneCategories.get(this.get('category_id'));
    },

    children: function(rootObjectSlugName) {
        let children = new Backbone.Collection;

        if (this.isBuilding()) {
            if (rootObjectSlugName) {
                children.add(this[rootObjectSlugName]().models.reverse());
            } else {
                let rootObjects = WiseMetering.rootObjects.where({ building_id: this.id });
                children.add(rootObjects.sort((a, b) => b.get('order') - a.get('order')));
                children.add(this.indicators().models.reverse());
            }
        } else {
            children.add(this.circuits().models.reverse());
            children.add(this.folders().models.reverse());
            children.add(this.zones().models.reverse());
            children.add(this.indicators().models.reverse());
        }

        return children;
    },

    circuits() {
        return new Backbone.Collection(WiseMetering.circuits.where({ parent_id: this.id }));
    },

    cooled_water_energy_tariff() {
        return this.tariff('Cooled Water Energy');
    },

    currentMonth(greatness = 'electricity', date = new Date(), type = 'consumption') {
        return this.get('dashboard_data')?.[greatness]?.[type]?.[date.getFullYear()]?.[date.getMonth()];
    },

    currentYear(greatness = 'electricity', date = new Date(), type = 'consumption') {
        return this.get('dashboard_data')?.[greatness]?.[type]?.[date.getFullYear()]?.reduce((acc, value) => acc + value, 0);
    },

    electricity_tariff() {
        return this.tariff('Electricity');
    },

    folders() {
        return new Backbone.Collection(WiseMetering.folders.where({ parent_id: this.id }));
    },

    fullPath() {
        let path = [this.get('name')];
        let parent = this.parent();
        while (parent) {
            path.unshift(parent.get('name'));
            parent = parent.parent();
        }
        return path.join(' > ');
    },

    gas_energy_tariff() {
        return this.tariff('Gas Energy');
    },

    gas_volume_tariff() {
        return this.tariff('Gas Volume');
    },

    // Realtime cost params: { from: from, to: to, rate: rate, tariff_id: tariff_id }
    getCost(params, success, error) {
        return $.ajax({
            url: this.url() + '/costs',
            type: 'GET',
            dataType: 'json',
            contentType: 'application/json',
            data: params,
            success: success,
            error: error
        });
    },

    groups() {
        return WiseMetering.groups.reduce((groups, group) => {
            if (group.get('building_ids').includes(this.id)) {
                groups.add(group);
            }
            return groups;
        }, new WiseMetering.Collection.Groups);
    },

    hot_water_energy_tariff() {
        return this.tariff('Hot Water Energy');
    },

    icon() {
        let name = this.category().get('name');
        if (name === 'Other') {
            return this.isBuilding() ? 'buildings' : 'zones';
        } else if (name === 'Shopping Mall') {
            return 'shopping';
        } else if (name === 'CarPark') {
            return 'parking';
        } else {
            return name.toLowerCase();
        }
    },

    inactiveTenants() {
        let inactiveTenants = [];

        WiseMetering.tenants.where({ parent_id: this.id }).forEach(tenant => {
            if (WiseMetering.allocations.where({ tenant_id: tenant.id, active: true }).length === 0) {
                inactiveTenants.push(tenant);
            }
        });

        return new Backbone.Collection(inactiveTenants);
    },

    indicators() {
        return new WiseMetering.Collection.Indicators(WiseMetering.indicators.where({ parent_id: this.id }));
    },

    inductiveEnergy() {
        return WiseMetering.indicators.findWhere({
            parent_id: this.id,
            kind_id: WiseMetering.indicatorKinds.findWhere({ name: 'Inductive Energy' }).id
        });
    },

    isSingleBuilding() {
        return WiseMetering.zones.filter(z => !z.get('parent_id')).length === 1;
    },

    nodeUrl(skipSearch) {
        if (this.isBuilding() && this.get('state') !== 'active') {
            return `/buildings/${this.id}/details`;
        } else {
            return `/zones/${this.get('id')}/dashboard${skipSearch ? '' : location.search}`;
        }
    },

    objectives() {
        return new WiseMetering.Collection.Objectives(WiseMetering.objectives.where({ zone_id: this.id }));
    },

    oil_tariff() {
        return this.tariff('Oil');
    },

    parent() {
        const rootFolder = WiseMetering.rootObjects.findWhere({ building_id: this.get('parent_id'), slug: 'zones' });
        return rootFolder || this.zone();
    },

    requestedPower() {
        let requestedPowerAttribute = WiseMetering.zone_attributes.requestedPower();
        if (!requestedPowerAttribute) return requestedPowerAttribute;
        return WiseMetering.zone_attribute_values.findWhere({
            zone_id: this.id,
            zone_attribute_id: requestedPowerAttribute.id
        });
    },

    rootObjects: function() {
        let rootObjects = new Backbone.Collection;
        const availableRootObjects = ['zones', 'folders', 'circuits', 'tenants', 'seus', 'utilities'];
        availableRootObjects.forEach(function(slug, index) {
            let model = new WiseMetering.Model.RootObject({
                id: `${this.id}-${slug}`,
                building_id: this.id,
                name: i18next.t(`glossary.${slug}`).capitalize(),
                slug: slug,
                order: index
            });
            rootObjects.add(model);
        }.bind(this));

        return rootObjects;
    },

    rebuildDashboard(options) {
        const defaults = {
            type: 'POST',
            url: this.url() + '/rebuild_dashboards'
        };

        return (this.sync || Backbone.sync).call(this, null, this, Object.assign({}, defaults, options));
    },

    seus() {
        return new Backbone.Collection(WiseMetering.seus.where({ parent_id: this.id }));
    },

    tariff(utility_kind_name) {
        if (!WiseMetering.utilityKinds.pluck('name').includes(utility_kind_name)) {
            throw 'Tariff must receive a valid utility_kind name';
        }
        const utilityKind = WiseMetering.utilityKinds.findWhere({ name: utility_kind_name });
        if (!utilityKind) {
            return null;
        }
        //const tariff = this.has('utility_tariffs') ? this.get('utility_tariffs').find(i => i.utility_id === utility.id) : null;
        const tariffId = this.utilities().findWhere({ utility_id: utilityKind.id, default: true })?.get('tariff_id');

        if (!tariffId) {
            return null;
        }
        return WiseMetering.tariffs.get(tariffId);
    },

    tenants() {
        let activeTenants = [];

        WiseMetering.tenants.where({ parent_id: this.id }).forEach(tenant => {
            if (WiseMetering.allocations.where({ tenant_id: tenant.id, active: true }).length > 0) {
                activeTenants.push(tenant);
            }
        });

        return new Backbone.Collection(activeTenants);
    },

    type() {
        return this.get('parent_id') ? 'zone' : 'building';
    },

    users() {
        return WiseMetering.groups.reduce((users, group) => {
            if (group.get('building_ids').includes(this.id)) {
                users.add(group.users().models);
            }
            return users;
        }, new WiseMetering.Collection.Users);
    },

    utilities: function() {
        return new WiseMetering.Collection.Utilities(WiseMetering.utilities.where({ building_id: this.id }));
    },

    water_tariff() {
        return this.tariff('Water');
    },

    ytd(greatness = 'electricity', year = moment().year(), type = 'consumption') {
        try {
            return [...this.get('dashboard_data')[greatness][type][year]];
        } catch (e) {
            return [];
        }
    },

    zone() {
        return WiseMetering.zones.get(this.get('parent_id'));
    },

    zones() {
        return new Backbone.Collection(WiseMetering.zones.where({ parent_id: this.id }));
    },

    zone_attributes() {
        return new WiseMetering.Collection(WiseMetering.zone_attribute_values.where({ zone_id: this.id }));
    }
});
