module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'basic-widget table-widget grid-' +
((__t = ( widgetPreferences.gridSize )) == null ? '' : __t) +
' hidden-' +
((__t = ( widgetPreferences.hidden )) == null ? '' : __t) +
'\' data-id="' +
((__t = ( widgetPreferences.id )) == null ? '' : __t) +
'" id="wrapper-' +
((__t = ( widgetPreferences.id )) == null ? '' : __t) +
'">\n    <div class=\'options-region\' id=\'region-' +
((__t = ( widgetPreferences.id )) == null ? '' : __t) +
'\'></div>\n    ';
 if(widgetPreferences.hidden){ ;
__p += '\n        <div class="widget-hidden">\n            <div class="widget-hidden-icon">\n                ' +
((__t = ( WiseMetering.icon('hidden') )) == null ? '' : __t) +
'\n            </div>\n        </div>\n    ';
 } ;
__p += '\n\n    <div class=\'title\'>\n        ' +
((__t = ( title )) == null ? '' : __t) +
'\n        <span class=\'basic-widget-options\' id=\'options-' +
((__t = ( widgetPreferences.id )) == null ? '' : __t) +
'\'>' +
((__t = ( WiseMetering.icon('edit') )) == null ? '' : __t) +
'</span>\n    </div>\n\n    ';
 if (rows.length === 0) { ;
__p += '\n        <div class=\'widget-content\'>\n            <div class=\'no-data\'>\n                <div class=\'no-data-icon\'>\n                    ' +
((__t = ( WiseMetering.icon('exclamation-triangle') )) == null ? '' : __t) +
'\n                </div>\n                <div class=\'no-data-text\'>\n                    ' +
((__t = ( i18next.t('ui.no_data') )) == null ? '' : __t) +
'\n                </div>\n            </div>\n        </div>\n    ';
 } else { ;
__p += '\n        <div class=\'widget-table-displayer\'>\n            <div class=\'widget-table\' style="height: ' +
((__t = ( widgetPreferences.height )) == null ? '' : __t) +
'px">\n                ';
 if (headers) { ;
__p += '\n                    <div class=\'widget-table-row header\'>\n                        ';
 headers.forEach(function(item) { ;
__p += '\n                            <div class=\'widget-table-cell\'>' +
((__t = ( item )) == null ? '' : __t) +
'</div>\n                        ';
 }) ;
__p += '\n                    </div>\n                ';
 } ;
__p += '\n                ';
 rows.forEach(function(row) { ;
__p += '\n                    <div class=\'widget-table-row\'>\n                        ';
 _.each(_.keys(row), function(key) { ;
__p += '\n                            ';
 if (key === 'icon') { ;
__p += '\n                                <div class=\'widget-table-cell\' style="max-width: fit-content;">\n                                    <div class=\'widget-table-icon\' style="color: #8BC53F; background-color: #224C4F;">\n                                        ' +
((__t = ( WiseMetering.icon(row[key]) )) == null ? '' : __t) +
'\n                                    </div>\n                                </div>\n                            ';
 } else { ;
__p += '\n                                <div class=\'widget-table-cell\' style="text-align: ' +
((__t = ( row[key].text_align || 'left' )) == null ? '' : __t) +
'; flex: ' +
((__t = ( row[key].width || 1 )) == null ? '' : __t) +
'">\n                                    <h3 class="table-main">' +
((__t = ( row[key].main )) == null ? '' : __t) +
'</h3>\n                                    <p class="table-sub">' +
((__t = ( row[key].sub )) == null ? '' : __t) +
'</p>\n                                </div>\n                            ';
 } ;
__p += '\n                        ';
 }) ;
__p += '\n                    </div>\n                ';
 }) ;
__p += '\n            </div>\n        </div>\n    ';
 } ;
__p += '\n</div>\n\n';

}
return __p
}