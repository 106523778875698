WiseMetering.Model.Alarm = WiseMetering.Model.extend({
    urlRoot: '/alarms',

    indicator: function() {
        return WiseMetering.indicators.get(this.get('indicator_id'));
    },

    last_checked_humanized: function() {
        let last_run = this.get('last_checked') * 1000;
        return last_run === 0 ? i18next.t('alarms.never_executed') : moment.duration(moment().diff(last_run)).humanize() + ' ' + i18next.t('common.ago');
    },

    notifications: function() {
        return this.get('user_ids').reduce((array, user_id) => {
            const user = WiseMetering.users.get(user_id)?.get('name');
            return user ? array.concat(user) : array;
        }, []);
    },

    schedule: function() {
        return WiseMetering.alarmSchedules.get(this.get('schedule_id'));
    },

    valid_alarm_integration: function() {
        const
            indicator_id = this.get('indicator_id'),
            assets = _.find(WiseMetering.integrations.models, function(integration) {
                return integration.get('indicator_id') === indicator_id;
            });

        if (!assets || this.get('average')) {
            return false;
        }

        return true;
    }
});
