module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<table class=\'interval\'>\n    <tr class="spacer"></tr>\n    <tr>\n        <th class="capitalize head" colspan="2">' +
((__t = ( i18next.t('common.interval') )) == null ? '' : __t) +
'</th>\n    </tr>\n    <tr>\n        <th class="capitalize">' +
((__t = ( i18next.t('common.from') )) == null ? '' : __t) +
'</th>\n        <th class="capitalize">' +
((__t = ( i18next.t('common.to') )) == null ? '' : __t) +
'</th>\n    </tr>\n    <tr>\n        <td>\n            <input class="from" disabled name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][from]" type="text" value="' +
((__t = ( interval.from )) == null ? '' : __t) +
'">\n        </td>\n        <td>\n            <input class="to" name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][to]" type="text" value="' +
((__t = ( interval.to )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n\n    <tr class=\'spacer\'></tr>\n\n    <tr>\n        <th>Componente Fixa\n            <b>(' +
((__t = ( WiseMetering.getCurrency() )) == null ? '' : __t) +
'/dia)</b>\n        </th>\n        <td>\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][fixed]" type="text" value="' +
((__t = ( interval.values?.fixed )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n\n    <tr class=\'spacer\'></tr>\n\n    <tr>\n        <th class=\'head capitalize\' colspan=\'2\'>' +
((__t = ( i18next.t('glossary.ranges') )) == null ? '' : __t) +
'</th>\n    </tr>\n\n    ';
 ranges.forEach(function(range) { ;
__p += '\n        <tr>\n            <th class="capitalize">' +
((__t = ( i18next.t('common.from') )) == null ? '' : __t) +
'</th>\n            <td>\n                <input value="' +
((__t = ( range.from )) == null ? '' : __t) +
'" type="text">\n            </td>\n        </tr>\n\n        <tr>\n            <th class="capitalize">' +
((__t = ( i18next.t('common.to') )) == null ? '' : __t) +
'</th>\n            <td>\n                <input type="text" value="' +
((__t = ( range.to )) == null ? '' : __t) +
'">\n            </td>\n        </tr>\n\n        <tr>\n            <th class="capitalize">' +
((__t = ( i18next.t('common.value') )) == null ? '' : __t) +
'</th>\n            <td>\n                <input type="text" value="' +
((__t = ( range.value )) == null ? '' : __t) +
'">\n            </td>\n        </tr>\n\n        <tr class=\'spacer\'></tr>\n    ';
 }) ;
__p += '\n\n    <tr class=\'spacer last\'></tr>\n\n    <th class=\'top delRange\' colspan=\'2\'>Remover Escalão</th>\n\n    <tr class=\'spacer\'></tr>\n\n    <tr>\n        <th class=\'head\' colspan=\'2\'>RSU</th>\n    <tr>\n        <th>\n            Componente Fixa <b>(' +
((__t = ( WiseMetering.getCurrency() )) == null ? '' : __t) +
'/dia)</b>\n        </th>\n        <th>\n            Componente Variável <b>(' +
((__t = ( WiseMetering.getCurrency() )) == null ? '' : __t) +
'/' +
((__t = ( unit )) == null ? '' : __t) +
')</b>\n        </th>\n    </tr>\n    <tr>\n        <td>\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][rsu][fixed]" type="text" value="' +
((__t = ( interval.values?.rsu.fixed )) == null ? '' : __t) +
'">\n        </td>\n        <td>\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][rsu][variable]" type="text" value="' +
((__t = ( interval.values?.rsu.variable )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n</table>\n';

}
return __p
}