WiseMetering.Views.EditV2CIntegration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration',
    template: 'templates/integrations/v2_charge/edit',
    title: () => i18next.t('modal.title.integrations.edit'),

    afterSave: function() {
        WiseMetering.indicators.fetch();
    }
});
