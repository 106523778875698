WiseMetering.Model.UserPreference = WiseMetering.Model.extend({
    urlRoot: '/user_preferences',

    alarmsNotifications: function() {
        return this.get('alarms_notifications');
    },

    buildingWidgets: function() {
        const
            availableWidgets = Object.keys(WiseMetering.AvailableWidgets),
            preferences = Object.assign({
                period: 'ytd',
                widgets: availableWidgets,
                active: availableWidgets
            }, this.get('building_widgets'));

        if (!preferences.active) {
            preferences.active = availableWidgets;
        }

        const mergedWidgets = preferences.widgets.concat(availableWidgets);
        let set = new Set(mergedWidgets);
        preferences.widgets = Array.from(set.values());

        return preferences;
    },

    creator: function() {
        return WiseMetering.users.get(this.get('user_id'));
    },

    dailyReportNotifications: function() {
        return this.get('daily_report_notifications');
    },

    dashboardWidgetsDefault: function() {
        const utilityKinds = WiseMetering.utilityKinds.activeUtilityKinds();

        let widgetPreferences = {
            all: this.defaultPreferences('all')
        };

        utilityKinds.forEach(utility => {
            widgetPreferences[utility.get('slug')] = this.defaultPreferences(utility);
        });

        return widgetPreferences;
    },

    defaultPreferences: function(scope) {
        const baseTypes = {
            all: ['opportunities', 'events', 'co2', 'totalCostPerSqM', 'totalCostByUtility', 'objectiveCostAnalisys'],
            utilities: ['opportunities', 'consumption', 'distribution', 'events', 'totalCostPerSqM', 'objectiveCostAnalisys', 'objectiveConsumptionAnalisys']
        };
        const selectedTypes = scope === 'all' ? baseTypes.all : baseTypes.utilities;

        return selectedTypes.map(type => ({
            building_ids: [],
            gridSize: 'half',
            height: 300,
            hidden: false,
            id: _.uniqueId('widget-'),
            period: 'ytd',
            type: type,
            normalization: false
        }));
    },

    language: function() {
        return this.get('language');
    }
});
