import * as React from 'react';

export default ({ message, onMessageClicked, selected }) => {
    let classes = 'message-item';
    if (selected) {
        classes += ' selected';
    }

    const status = message.status();
    let date = WiseMetering.utils.formatDate(message.get('created_at'));

    return (
        <div onClick={() => onMessageClicked(message.id)} className={classes}>
            <div className="message-item-dot" data-status={status}>&nbsp;</div>
            <div className="message-item__subject truncate">{message.get('title')}</div>
            <div className="message-item__details">
                <span className="message-item__time truncate">{date}</span>
            </div>
        </div>
    );
};
