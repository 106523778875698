module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'wise-layout\'>\n    <div id=\'content-menu\'>\n        <div class=\'navbar\'>\n            <div id="navbar-inner" class=\'navbar-inner\'>\n                <ul class=\'nav content\'>\n                    <div class=\'brand\'>\n                        <div id=\'content-icon\' class=\'svg_icon\'>\n                            ' +
((__t = ( WiseMetering.icon(icon) )) == null ? '' : __t) +
'\n                        </div>\n                        <div id=\'content-name\' style=\'float: left; text-transform: capitalize\'>\n                            ' +
((__t = ( name )) == null ? '' : __t) +
'\n                        </div>\n                    </div>\n                    ';
 _.each(tabs, function(tab){ ;
__p += '\n                        <li class="' +
((__t = ( tab === activeTab ? 'active' : '' )) == null ? '' : __t) +
'">\n                            <a id=\'' +
((__t = ( tab.id )) == null ? '' : __t) +
'-tab\'>' +
((__t = ( tab.name )) == null ? '' : __t) +
'</a>\n                        </li>\n                    ';
 }) ;
__p += '\n                </ul>\n                ';
 if (options && options.length !== 0) { ;
__p += '\n                    <div class=\'dropdown\' style="margin-right: 5px;">\n                        <div class=\'dropdown-main content\'>\n                            ' +
((__t = ( WiseMetering.icon('options') )) == null ? '' : __t) +
'\n                        </div>\n                        <div class=\'dropdown-section content\'>\n                            ';
 _.each(options, function(option){ ;
__p += '\n                                <a id=\'' +
((__t = ( option.id )) == null ? '' : __t) +
'-tab\'>\n                                    <div class=\'dropdown-section-item\'>\n                                        ' +
((__t = ( option.name )) == null ? '' : __t) +
'\n                                    </div>\n                                </a>\n                            ';
 }) ;
__p += '\n                        </div>\n                    </div>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n    </div>\n\n    ';
 if (bannerInfo) { ;
__p += '\n        <div id=\'content-banner\'>\n            ' +
((__t = ( WiseMetering.icon(bannerInfo.icon) )) == null ? '' : __t) +
'\n            <span class="">' +
((__t = ( bannerInfo.message )) == null ? '' : __t) +
'</span>\n        </div>\n    ';
 } ;
__p += '\n\n    <div id=\'content-body\'></div>\n</div>\n';

}
return __p
}