WiseMetering.Collection.GlobalReportTypes = WiseMetering.Collection.extend({
    hasOrganization: true,
    model: WiseMetering.Model.GlobalReportType,
    url: '/report_types/global_report_types',

    grouped: function() {
        return _.groupBy(this.toJSON(), function(model) {
            return model.category;
        });
    },

    filterTypes: function() {
        const isSouthOpe = WiseMetering.currentOrganization.get('subdomain') === 'southope',
            types = isSouthOpe ? this : this.standardGlobalReportSuite();

        types.each(report => {
            let active = true;
            if (['GlobalDetailedReport', 'GlobalOptimiserReport'].includes(report.get('type'))) {
                active = WiseMetering.currentOrganization.hasStandardPermissions();
            }
            report.set({ active });
        });

        return types.grouped();
    },

    standardGlobalReportSuite: function() {
        let reports = this.filter((report) => report.get('type') !== 'GlobalInvoiceReport');
        return new WiseMetering.Collection.GlobalReportTypes(reports);
    }
});
