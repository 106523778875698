module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <div style=\'width:200px; max-width:200px; min-width:200px; float:left; padding:25px 15px 0 10px;border-right:1px solid #CCCCCC;\'>\n        <dl class=\'stepped zero\'>\n            <dt>' +
((__t = ( i18next.t('glossary.indicator') )) == null ? '' : __t) +
'</dt>\n            <dd>' +
((__t = ( indicatorName )) == null ? '' : __t) +
'</dd>\n            <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</dt>\n            <dd>\n                <input class=\'text\' id=\'alarm-name\' name=\'alarm[name]\' type=\'text\'>\n            </dd>\n            <dt>' +
((__t = ( i18next.t('common.type') )) == null ? '' : __t) +
'</dt>\n            <dd>\n                <select class=\'text\' id=\'alarm-type\' name=\'alarm[type]\'>\n                    ';
 alarmTypes.forEach(function(type) { ;
__p += '\n                        <option value=\'' +
((__t = ( type )) == null ? '' : __t) +
'\'>' +
((__t = ( type.capitalize() )) == null ? '' : __t) +
'</option>\n                    ';
 }) ;
__p += '\n                </select>\n                ';
 if (alarmTypes.includes('cumulative')) { ;
__p += '\n                    <div style="padding: 5px">\n                        ' +
((__t = ( i18next.t('alarms.cumulative_description') )) == null ? '' : __t) +
'\n                    </div>\n                ';
 } ;
__p += '\n            </dd>\n            <dt>' +
((__t = ( i18next.t('glossary.notifications') )) == null ? '' : __t) +
'</dt>\n            <dd id="notifications"></dd>\n            <dt>\n                <ul class="toolbar regular" style="background: none; border:none; padding-left: 0 !important;">\n                    <div class="wmui-btn" id=\'add-notification\' style="float: left; margin:0 !important;">\n                        ' +
((__t = ( i18next.t('alarms.add_notification') )) == null ? '' : __t) +
'\n                    </div>\n                </ul>\n            </dt>\n        </dl>\n    </div>\n    <div style=\'float:left; padding-left:25px; max-width: 889px;\'>\n        <label for=\'alarm-template\'>\n            ' +
((__t = ( i18next.t('ui.table.alarm_schedule') )) == null ? '' : __t) +
'\n        </label>\n        <select class=\'text\' id=\'alarm-template\' name=\'alarm[schedule_id]\'>\n            <option></option>\n            ';
 WiseMetering.alarmSchedules.each(function(schedule) { ;
__p += '\n                <option value="' +
((__t = ( schedule.id )) == null ? '' : __t) +
'">' +
((__t = ( schedule.get('name') )) == null ? '' : __t) +
'</option>\n            ';
 }) ;
__p += '\n        </select>\n        <dl class=\'stepped\' id=\'alarm-schedule\'>\n        </dl>\n    </div>\n</form>\n';

}
return __p
}