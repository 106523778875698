module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class="stepped zero">\n        <div id=\'report-description-section\' class=\'mbx-info green\' style=\'margin: 0; text-align: justify;\'></div>\n        <dt>\n            <label for=\'report-language\'>' +
((__t = ( i18next.t('common.language') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'report-language\' name=\'report[language]\'>\n                <option value=\'\' disabled selected>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                <option value=\'pt\'>' +
((__t = ( i18next.t('languages.pt') )) == null ? '' : __t) +
'</option>\n                <option value=\'en\'>' +
((__t = ( i18next.t('languages.en') )) == null ? '' : __t) +
'</option>\n                <option value=\'es\'>' +
((__t = ( i18next.t('languages.es') )) == null ? '' : __t) +
'</option>\n            </select>\n        </dd>\n\n        <dt>\n            <label for=\'report-type\'>' +
((__t = ( i18next.t('common.type') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'report-type\' name=\'report[type]\'>\n                <option value=\'\' disabled selected>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                ';
 Object.keys(reports).forEach(function(type) { ;
__p += '\n                    <optgroup label=\'' +
((__t = ( type )) == null ? '' : __t) +
'\'>\n                        ';
 reports[type].forEach(function(report) { ;
__p += '\n                            <option value=\'' +
((__t = ( report.type )) == null ? '' : __t) +
'\' ';
 if (!report.active) { ;
__p += 'disabled';
 } ;
__p += '>\n                                ' +
((__t = ( report.title )) == null ? '' : __t) +
'\n                                ';
 if (!report.active) { ;
__p += '(' +
((__t = ( i18next.t('reports.not_available') )) == null ? '' : __t) +
')';
 } ;
__p += '\n                            </option>\n                        ';
 }) ;
__p += '\n                    </optgroup>\n                ';
 }) ;
__p += '\n            </select>\n        </dd>\n\n        <div id=\'report-utility-kind-section\' style=\'display:none\'>\n            <dt>\n                <label for=\'report-utility-kind\'>' +
((__t = ( i18next.t('glossary.utility_kind') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <select class=\'text\' id=\'report-utility-kind\' name=\'report[options][utility_kind]\'>\n                    <option value=\'\' disabled selected>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                    ';
 utilityKinds.forEach(function(utilityKind) { ;
__p += '\n                        <option value=\'' +
((__t = ( utilityKind.slug )) == null ? '' : __t) +
'\'>' +
((__t = ( utilityKind.name )) == null ? '' : __t) +
'</option>\n                    ';
 }) ;
__p += '\n                </select>\n            </dd>\n        </div>\n\n        <div id=\'report-folder-section\' style=\'display:none\'>\n            <dt>\n                <label for=\'report-folder\'>' +
((__t = ( i18next.t('glossary.folder') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <select class=\'text\' id=\'report-folder\' name=\'report[folder_id]\'>\n                    <option value=\'\' disabled selected>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                    ';
 folders.forEach(function(folder) { ;
__p += '\n                        <option value=\'' +
((__t = ( folder.id )) == null ? '' : __t) +
'\'>' +
((__t = ( folder.name )) == null ? '' : __t) +
'</option>\n                    ';
 }) ;
__p += '\n                </select>\n            </dd>\n        </div>\n\n        <div id=\'report-comparison-section\' style=\'display:none\'>\n            <dt>\n                <label for=\'report-comparison\'>' +
((__t = ( i18next.t('reports.comparison_criteria') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <select class=\'text\' id=\'report-comparison\' name=\'report[options][comparison]\'>\n                    <option value=\'\' disabled selected>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                    <option value=\'consumption\'>' +
((__t = ( i18next.t('common.consumption') )) == null ? '' : __t) +
'</option>\n                    <option value=\'cost\'\n                    ';
 if (!WiseMetering.currentOrganization.hasStandardPermissions()) { ;
__p += ' disabled\n                            ';
 } ;
__p += '\n                    >\n                        ' +
((__t = ( i18next.t('common.cost') )) == null ? '' : __t) +
'\n                        ';
 if (!WiseMetering.currentOrganization.hasStandardPermissions()) { ;
__p += '(' +
((__t = ( i18next.t('reports.not_available') )) == null ? '' : __t) +
')\n                        ';
 } ;
__p += '\n                    </option>\n                </select>\n            </dd>\n        </div>\n\n        <div id=\'report-details-section\' style=\'display:none\'>\n            <dt>\n                <label for=\'report-details\'>' +
((__t = ( i18next.t('reports.detail_level') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <select class=\'text\' id=\'report-details\' name=\'report[options][details]\'>\n                    <option value=\'\' disabled selected>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                    <option value=\'brief\'>' +
((__t = ( i18next.t('reports.brief_version') )) == null ? '' : __t) +
'</option>\n                    <option value=\'extended\'>' +
((__t = ( i18next.t('reports.extended_version') )) == null ? '' : __t) +
'</option>\n                </select>\n            </dd>\n        </div>\n\n        <div id=\'report-survey-section\' style=\'display:none\'>\n            <dt>\n                <label for=\'report-survey\'>' +
((__t = ( i18next.t('reports.report_options') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <select class=\'text\' id=\'report-survey\' name=\'report[options][survey]\'>\n                    <option value=\'\' disabled selected>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                    <option value=\'findings\'>' +
((__t = ( i18next.t('reports.findings_report') )) == null ? '' : __t) +
'</option>\n                    <option value=\'impact\'>' +
((__t = ( i18next.t('reports.impact_analysis') )) == null ? '' : __t) +
'</option>\n                    <option value=\'full\'>' +
((__t = ( i18next.t('reports.full_analysis') )) == null ? '' : __t) +
'</option>\n                </select>\n            </dd>\n        </div>\n\n        <div id=\'report-file-section\' style=\'display:none\'>\n            <dt>\n                <label for=\'report-file\'>' +
((__t = ( i18next.t('common.file') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <select class=\'text\' id=\'report-file\' name=\'report[options][file]\'>\n                    <option value=\'\' disabled selected>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                    <option value=\'pdf\'>pdf</option>\n                    <option value=\'xls\'>xls</option>\n                </select>\n            </dd>\n        </div>\n\n        <input id=\'report-from\' name=\'report[from]\' type=\'hidden\' value=\'\'>\n        <input id=\'report-to\' name=\'report[to]\' type=\'hidden\' value=\'\'>\n\n        <div id=\'report-period-section\' style=\'display: none\'>\n            <dt>\n                <label for=\'report-period\'>' +
((__t = ( i18next.t('common.period') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <select id=\'report-period\' name=\'period\' class=\'text\'>\n                    <option value=\'\' disabled selected>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                    ';
 frequencies.forEach(function(frequency) { ;
__p += '\n                        <option value=\'' +
((__t = ( frequency.value )) == null ? '' : __t) +
'\'>' +
((__t = ( frequency.title )) == null ? '' : __t) +
'</option>\n                    ';
 }) ;
__p += '\n                </select>\n            </dd>\n        </div>\n\n        <div id=\'report-year-section\' style=\'display: none\'>\n            <dt>\n                <label for=\'report-year\'>' +
((__t = ( i18next.t('common.year') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <select class=\'text\' id=\'report-year\' name=\'year\'>\n                    <option value=\'\' disabled selected>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                    ';
 years.forEach(function(value) { ;
__p += '\n                        <option ' +
((__t = ( value === year ? 'selected' : '' )) == null ? '' : __t) +
' value=\'' +
((__t = ( value )) == null ? '' : __t) +
'\'>' +
((__t = ( value )) == null ? '' : __t) +
'</option>\n                    ';
 }) ;
__p += '\n                </select>\n            </dd>\n        </div>\n\n        <div id=\'report-month-section\' style=\'display: none\'>\n            <dt>\n                <label for=\'report-month\'>' +
((__t = ( i18next.t('common.month') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <select class=\'text\' id=\'report-month\' name=\'month\'>\n                    <option value=\'\' disabled selected>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                    ';
 Date.monthNames.forEach(function(name, index) { ;
__p += '\n                        <option value=\'' +
((__t = ( index + 1 )) == null ? '' : __t) +
'\'>\n                            ' +
((__t = ( name )) == null ? '' : __t) +
'\n                        </option>\n                    ';
 }) ;
__p += '\n                </select>\n            </dd>\n        </div>\n\n        <div id=\'report-quarter-section\' style=\'display: none\'>\n            <dt>\n                <label for=\'report-quarter\'>' +
((__t = ( i18next.t('common.quarter') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <select class=\'text\' id=\'report-quarter\' name=\'quarter\'>\n                    <option value=\'\' disabled selected>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                    ';
 quarters.forEach(function(value) { ;
__p += '\n                        <option value=\'' +
((__t = ( value )) == null ? '' : __t) +
'\'>Q' +
((__t = ( value )) == null ? '' : __t) +
'</option>\n                    ';
 }) ;
__p += '\n                </select>\n            </dd>\n        </div>\n\n        <div id=\'report-end-section\' style=\'display: none\'>\n            <dt>\n                <label for=\'report-year-end\'>' +
((__t = ( i18next.t('reports.end_year') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <select class=\'text\' id=\'report-year-end\'>\n                    ';
 years.forEach(function(value) { ;
__p += '\n                        <option ' +
((__t = ( value === year ? 'selected' : '' )) == null ? '' : __t) +
' value=\'' +
((__t = ( value )) == null ? '' : __t) +
'\'>' +
((__t = ( value )) == null ? '' : __t) +
'</option>\n                    ';
 }) ;
__p += '\n                </select>\n            </dd>\n\n            <dt>\n                <label for=\'report-month-end\'>' +
((__t = ( i18next.t('reports.end_month') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <select class=\'text\' id=\'report-month-end\'>\n                    ';
 Date.monthNames.forEach(function(name, index) { ;
__p += '\n                        <option ' +
((__t = ( index + 1 === month ? 'selected' : '' )) == null ? '' : __t) +
' value=\'' +
((__t = ( index + 1 )) == null ? '' : __t) +
'\'>\n                            ' +
((__t = ( name )) == null ? '' : __t) +
'\n                        </option>\n                    ';
 }) ;
__p += '\n                </select>\n            </dd>\n        </div>\n    </dl>\n</form>\n';

}
return __p
}