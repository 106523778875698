WiseMetering.Views.Circuit = WiseMetering.Views.Content.extend({
    initialize: function() {
        this.icon = this.model.icon();
        this.tabs = [
            {
                id: 'details',
                name: i18next.t('common.details'),
                view: WiseMetering.Views.CircuitDetailsTab
            }
        ];
        if (this.model.building().hasActuators()) {
            this.tabs.push(
                {
                    id: 'data',
                    name: i18next.t('common.data'),
                    view: WiseMetering.Views.CircuitDataTab
                },
                {
                    id: 'automation',
                    name: i18next.t('glossary.automation'),
                    view: WiseMetering.Views.CircuitAutomation
                }
            );
        }
    }
});
