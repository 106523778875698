module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id=\'crud-header\'>\n    <div id=\'crud-title\'>\n        ' +
((__t = ( title )) == null ? '' : __t) +
'\n    </div>\n</div>\n\n<div id=\'crud-container\'>\n    <div id=\'crud-errors\'></div>\n    <div id=\'crud-body\'></div>\n    <div id=\'crud-footer\'>\n        <div class=\'crud-btns\'>\n            <div class="left-side">\n                ';
 buttonsList.forEach(function(button) { ;
__p += '\n                    ';
 if(leftButtons.indexOf(button) != -1){ ;
__p += '\n                        <input class=\'toolbar-button item-float\' id=\'' +
((__t = ( button )) == null ? '' : __t) +
'\' type=\'button\' value=\'' +
((__t = ( i18next.t(`ui.buttons.${button}`) )) == null ? '' : __t) +
'\'>\n                    ';
 } ;
__p += '\n                ';
 }) ;
__p += '\n            </div>\n            <div class="right-side">\n                ';
 buttonsList.forEach(function(button) { ;
__p += '\n                    ';
 if(rightButtons.indexOf(button) != -1){ ;
__p += '\n                        <input class=\'toolbar-button item-float\' id=\'' +
((__t = ( button )) == null ? '' : __t) +
'\' type=\'button\' value=\'' +
((__t = ( i18next.t(`ui.buttons.${button}`) )) == null ? '' : __t) +
'\'>\n                    ';
 } ;
__p += '\n                ';
 }) ;
__p += '\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}