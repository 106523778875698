module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form xmlns=\'http://www.w3.org/1999/html\'>\n    <dl class=\'stepped zero\'>\n        <div style=\'width:250px;height:500px; max-width:300px; min-width:200px; float:left; padding-left:25px;\'>\n            ';
 if (type === 'edit') { ;
__p += '\n                ';
 if (WiseMetering.currentUser.isSuperUser()) { ;
__p += '\n                    <dt>ID</dt>\n                    <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n                ';
 } ;
__p += '\n                <dt>' +
((__t = ( i18next.t('common.year') )) == null ? '' : __t) +
'</dt>\n                <dd>' +
((__t = ( year )) == null ? '' : __t) +
'</dd>\n                <dt>' +
((__t = ( i18next.t('glossary.utility_kind') )) == null ? '' : __t) +
'</dt>\n                <dd>' +
((__t = ( utilityKindName )) == null ? '' : __t) +
'</dd>\n                <dt>' +
((__t = ( i18next.t('objectives.analysis_type') )) == null ? '' : __t) +
'</dt>\n                <dd>' +
((__t = ( analysis_type )) == null ? '' : __t) +
'</dd>\n                <dt>' +
((__t = ( i18next.t('glossary.unit') )) == null ? '' : __t) +
'</dt>\n                <dd>' +
((__t = ( unit )) == null ? '' : __t) +
'</dd>\n                <dt>' +
((__t = ( i18next.t('glossary.created_by') )) == null ? '' : __t) +
'</dt>\n                <dd>' +
((__t = ( created_by )) == null ? '' : __t) +
', ' +
((__t = ( created_at )) == null ? '' : __t) +
'</dd>\n                <dt>' +
((__t = ( i18next.t('glossary.updated_by') )) == null ? '' : __t) +
'</dt>\n                <dd>' +
((__t = ( updated_by )) == null ? '' : __t) +
', ' +
((__t = ( updated_at )) == null ? '' : __t) +
'</dd>\n            ';
 } ;
__p += '\n            <dt>\n                <label for="notifications.user">\n                    ' +
((__t = ( i18next.t('common.notifications') )) == null ? '' : __t) +
' <a title=\'' +
((__t = ( i18next.t('objectives.notifications_help') )) == null ? '' : __t) +
'\'>(' +
((__t = ( i18next.t('common.how') )) == null ? '' : __t) +
'?)</a>\n                </label>\n            </dt>\n            <dd id="notifications"></dd>\n            <dt>\n                <ul class="toolbar regular" style="background: none; border:none; padding-left: 0 !important;">\n                    <div class="wmui-btn" id=\'add-notification\' style="float: left; margin:0 !important;">\n                        ' +
((__t = ( i18next.t('objectives.add_notification') )) == null ? '' : __t) +
'\n                    </div>\n                </ul>\n            </dt>\n\n        </div>\n        <div style=\'float:left; width:250px; min-width:100px ;padding-left:25px; border-left:1px solid #CCCCCC;\'>\n\n            ';
 if (type !== 'edit') { ;
__p += '\n                <dt>\n                    <label for="objective_year">' +
((__t = ( i18next.t('common.year') )) == null ? '' : __t) +
'</label>\n                </dt>\n                <dd>\n                    <select class="text" id="objective_year" name="objective[year]">\n                        <option selected=\'selected\' disabled>' +
((__t = ( i18next.t('objectives.select_year') )) == null ? '' : __t) +
'</option>\n                        <option value="' +
((__t = ( year )) == null ? '' : __t) +
'">' +
((__t = ( year )) == null ? '' : __t) +
'</option>\n                        <option value="' +
((__t = ( year + 1 )) == null ? '' : __t) +
'">' +
((__t = ( year + 1 )) == null ? '' : __t) +
'</option>\n                        <option value="' +
((__t = ( year + 2 )) == null ? '' : __t) +
'">' +
((__t = ( year + 2 )) == null ? '' : __t) +
'</option>\n                    </select>\n                </dd>\n                <dt>\n                    <label for="objective_consumption">' +
((__t = ( i18next.t('common.type') )) == null ? '' : __t) +
'</label>\n                </dt>\n                <dd>\n                    <select class="text" id="analysis_type" name="objective[analysis_type]">\n                        <option selected=\'selected\' disabled>' +
((__t = ( i18next.t('objectives.select_analysis_type') )) == null ? '' : __t) +
'</option>\n                        <option value="consumption">' +
((__t = ( i18next.t('common.consumption') )) == null ? '' : __t) +
'</option>\n                        <option value="cost">' +
((__t = ( i18next.t('common.cost') )) == null ? '' : __t) +
'</option>\n                    </select>\n                </dd>\n                <dt>\n                    <label for="">' +
((__t = ( i18next.t('objectives.insertion_type') )) == null ? '' : __t) +
'</label>\n                </dt>\n                <dd>\n                    <select class="text" id="insertion-type" name="insertion_type">\n                        <option selected=\'selected\' disabled>' +
((__t = ( i18next.t('objectives.select_insertion_type') )) == null ? '' : __t) +
'</option>\n                        <option id="manual" value="manual">' +
((__t = ( i18next.t('common.manual') )) == null ? '' : __t) +
'</option>\n                        <option id="auto" value="auto" disabled>' +
((__t = ( i18next.t('common.auto') )) == null ? '' : __t) +
'</option>\n                    </select>\n                </dd>\n                <div style="display:none" id=\'percentage\'>\n                    <dt>\n                        <label for="percentage-value">' +
((__t = ( i18next.t('objectives.percentage_of_previous_year') )) == null ? '' : __t) +
'</label>\n                    </dt>\n                    <dd>\n                        <input class="text" id="percentage-value" type="number" step="0.1" min="0" placeholder="' +
((__t = ( i18next.t('objectives.insert_percentage') )) == null ? '' : __t) +
'"/>\n                    </dd>\n                </div>\n            ';
 } ;
__p += '\n\n            <div id="spinner" style="display:none">\n                <i class="fas fa-spinner fa-spin"></i>\n            </div>\n\n            <div id=\'monthly-insertions\' ';
 if (type !== 'edit') { ;
__p += 'style="display:none"\n                    ';
 } ;
__p += '\n            >\n                ';
 values.forEach(function(value, index) { ;
__p += '\n                    <dt>\n                        <label for="objective_' +
((__t = ( shortMonthsArray[index] )) == null ? '' : __t) +
'">' +
((__t = ( monthsArray[index] )) == null ? '' : __t) +
'</label>\n                    </dt>\n                    <dd>\n                        <input class="text" id="objective_' +
((__t = ( shortMonthsArray[index] )) == null ? '' : __t) +
'" name="objective[' +
((__t = ( shortMonthsArray[index] )) == null ? '' : __t) +
']" type="text" value="' +
((__t = ( value )) == null ? '' : __t) +
'"/>\n                    </dd>\n                ';
 }) ;
__p += '\n            </div>\n        </div>\n    </dl>\n</form>\n\n';

}
return __p
}