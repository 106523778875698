module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div style=\'color: #999; font-size: 10px; float: left; margin-right: 3px;\'>' +
((__t = ( min )) == null ? '' : __t) +
'</div>\n';
 for(var i = 2; i < 7; i++){ ;
__p += '\n    <div class=\'q' +
((__t = ( i )) == null ? '' : __t) +
'\' style=\'width: 13px; height: 13px; float: left; margin-right: 3px;\'></div>\n';
 } ;
__p += '\n<div style=\'color: #999; font-size: 10px; float: left; margin-left: 3px;\'>' +
((__t = ( max )) == null ? '' : __t) +
'</div>\n';

}
return __p
}