module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'flow\'>\n    ';
 if (buttons.pending) { ;
__p += '\n        <input class=\'workflow-buttons\' id=\'pending\' type=\'button\' value=\'pending\' ';
 if ( selected === 'pending') { ;
__p += 'disabled\n                ';
 } ;
__p += '\n        >\n        <i class=\'' +
((__t = ( WiseMetering.icons.caretRight )) == null ? '' : __t) +
' workflow-caret\'></i>\n    ';
 } ;
__p += '\n\n    ';
 if (buttons.ongoing) { ;
__p += '\n        <input class=\'workflow-buttons\' id=\'ongoing\' type=\'button\' value=\'ongoing\' ';
 if ( selected === 'ongoing') { ;
__p += 'disabled\n                ';
 } ;
__p += '\n        >\n        <i class=\'' +
((__t = ( WiseMetering.icons.caretRight )) == null ? '' : __t) +
' workflow-caret\'></i>\n    ';
 } ;
__p += '\n\n    ';
 if (buttons.active) { ;
__p += '\n        <input class=\'workflow-buttons\' id=\'active\' type=\'button\' value=\'active\' ';
 if ( selected === 'active') { ;
__p += 'disabled\n                ';
 } ;
__p += '\n        >\n    ';
 } ;
__p += '\n\n    ';
 if (buttons.completed) { ;
__p += '\n        <input class=\'workflow-buttons\' id=\'completed\' type=\'button\' value=\'completed\' ';
 if ( selected === 'completed') { ;
__p += 'disabled\n                ';
 } ;
__p += '\n        >\n    ';
 } ;
__p += '\n</div>\n\n';
 if (buttons.discarded) { ;
__p += '\n    <input class=\'workflow-buttons\' id=\'discarded\' type=\'button\' value=\'discarded\' ';
 if ( selected === 'discarded') { ;
__p += 'disabled\n            ';
 } ;
__p += '\n    >\n';
 } ;
__p += '\n\n';

}
return __p
}