import * as React from 'react';

import MessageListItem from './MessageListItem';

export default ({ messages, onMessageSelected, selectedMessageId }) => {
    if (messages.length === 0) {
        return (
            <div className="message-list empty">
                {i18next.t('messages.no_unread_left')}
            </div>
        );
    }

    return (
        <div className="message-list">
            {
                messages.map(message =>
                    <MessageListItem
                        key={message.id}
                        message={message}
                        onMessageClicked={id => onMessageSelected(id)}
                        selected={selectedMessageId === message.id}
                    />
                )
            }
        </div>
    );
};
