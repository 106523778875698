WiseMetering.Views.NewDegreeDayIntegration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration',
    template: 'templates/integrations/degree_days/new',
    ui: {
        indicators: '#integration_indicator_id'
    },
    title: () => i18next.t('modal.title.integrations.new'),

    afterSave: function() {
        WiseMetering.indicators.fetch();
    },

    onShow: function() {
        const indicators = WiseMetering.utils.optionsFromModels(this.temperatureIndicators());

        this.ui.indicators.html(indicators);
    },

    getFormData: function() {
        const data = this.formSerializer();
        data.partner_id = this.options.license.get('partner_id');
        return data;
    },

    temperatureIndicators: function() {
        return WiseMetering.indicators.filter(indicator => {
            return indicator.get('parent_id') === indicator.get('building_id') && indicator.get('kind_slug') === 'temperature';
        });
    }
});
