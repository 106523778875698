WiseMetering.Views.OrganizationSpecialFeaturesAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/organizations/special_features_accordion',

    modelEvents: {
        'change': 'render'
    },

    events: {
        'click #edit_special_features_attributes': 'editAttributes'
    },

    serializeData: function() {
        const json = this.model.toJSON();
        json.hasSpecialFeatures = this.model.hasSpecialFeatures();
        return json;
    },

    editAttributes: function(event) {
        event.stopPropagation();
        new WiseMetering.Views.EditSpecialFeaturesOrganization({ model: this.model });
    }
});
