module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'ui-schedule\' id=\'schedule_definition\'>\n    <table class=\'ui-schedule-table\'>\n        <thead>\n        <tr>\n            <th class=\'day\' rowspan=\'2\'>' +
((__t = ( i18next.t('common.day') )) == null ? '' : __t) +
'</th>\n            ';
 WiseMetering.weekdays.forEach(function(weekday) { ;
__p += '\n                <th colspan=\'4\'>' +
((__t = ( i18next.t(`weekdays.${weekday}`) )) == null ? '' : __t) +
'</th>\n            ';
 }) ;
__p += '\n        </tr>\n        <tr>\n            ';
 for(var i = 0; i < WiseMetering.weekdays.length; i++) { ;
__p += '\n                ';
 ['00', '15', '30', '45'].forEach(function(interval) { ;
__p += '\n                    <th>' +
((__t = ( interval )) == null ? '' : __t) +
'</th>\n                ';
 }) ;
__p += '\n            ';
 } ;
__p += '\n        </tr>\n        </thead>\n        <tbody>\n        ';
 for(i = 0; i <= 23; i++) { ;
__p += '\n            <tr>\n                <td class=\'day\'>\n                    ' +
((__t = ( WiseMetering.utils.pad2(i) + ':00' )) == null ? '' : __t) +
'\n                    ';
 _.each(WiseMetering.weekdays, function(weekday, index) { ;
__p += '\n                        ';
 for(var j = 0; j <= 3; j++) { ;
__p += '\n                <td\n                        class="input' +
((__t = ( editable ? '' : ' pointer-show' )) == null ? '' : __t) +
'' +
((__t = ( j % 4 === 3 ? ' day-change' : '' )) == null ? '' : __t) +
'"\n                        data-x=\'' +
((__t = ( index * 4 + j )) == null ? '' : __t) +
'\'\n                        data-y=\'' +
((__t = ( i )) == null ? '' : __t) +
'\'\n                        style="background-color: ' +
((__t = ( colors[schedule ? _.indexOf(periods, schedule[weekday][i * 4 + j]) + 1 : 0] )) == null ? '' : __t) +
';"\n                >\n                    <input\n                            id=\'schedule-' +
((__t = ( weekday )) == null ? '' : __t) +
'-' +
((__t = ( i * 4 + j )) == null ? '' : __t) +
'\'\n                            name=\'alarm_schedule[' +
((__t = ( weekday )) == null ? '' : __t) +
'][]\'\n                            type=\'hidden\'\n                            value=\'' +
((__t = ( schedule ? schedule[weekday][i * 4 + j] : 'off' )) == null ? '' : __t) +
'\'\n                    >\n                </td>\n                ';
 } ;
__p += '\n                ';
 }) ;
__p += '\n                </td>\n            </tr>\n        ';
 } ;
__p += '\n        </tbody>\n    </table>\n</div>\n\n<div class=\'schedule-legend\'>\n    <div class=\'schedule-legend-label\'>\n        ' +
((__t = ( i18next.t('common.legend') )) == null ? '' : __t) +
'\n    </div>\n\n    <div id=\'dynamic-periods\' style=\'margin-top: 5px\'>\n        ';
 if (editable) { ;
__p += '\n            ';
 if(addValues) { ;
__p += '\n                <div class=\'wmui-btn\' id=\'add-period\' style=\'margin-bottom: 5px\'>\n                    ' +
((__t = ( i18next.t('alarms.add_period') )) == null ? '' : __t) +
'\n                </div>\n                <a\n                        data-placement=\'bottom\'\n                        data-toggle=\'tooltip\'\n                        href=\'#\'\n                        id=\'period-note\'\n                        style=\'text-decoration: none\'\n                        title=\'' +
((__t = ( i18next.t('alarms.period_note') )) == null ? '' : __t) +
'.\'\n                >\n                    (' +
((__t = ( i18next.t('common.note') )) == null ? '' : __t) +
')\n                </a>\n                <div class=\'color-legend\' name=\'off\'>\n                    <span class=\'color-box\' style=\'background-color: ' +
((__t = ( colors[0] )) == null ? '' : __t) +
'\'></span>\n                    <span class=\'color-text\'>off</span>\n                    <input type=\'hidden\' name=\'alarm_schedule[periods][]\' value=\'off\'>\n                </div>\n            ';
 } else { ;
__p += '\n                <div class=\'color-legend\' name=\'off\'>\n                    <span class=\'color-box\' style=\'background-color: ' +
((__t = ( colors[0] )) == null ? '' : __t) +
'\'></span>\n                    <span class=\'color-text\'>off</span>\n                </div>\n                ';
 periods.forEach(function(period, i) { ;
__p += '\n                    ';
 const id = period.replace(' ', '_').replace('.', '') ;
__p += '\n                    <div class=\'color-legend\' name=\'' +
((__t = ( period )) == null ? '' : __t) +
'\'>\n                        <span class=\'color-box\' style=\'background-color: ' +
((__t = ( colors[i + 1] )) == null ? '' : __t) +
'\'></span>\n                        ';
 if (values) { ;
__p += '\n                            <input class=\'period_values\' id="' +
((__t = ( id )) == null ? '' : __t) +
'_min" type="number" value=\'' +
((__t = ( values[period]['min'] )) == null ? '' : __t) +
'\'>\n                            <input class=\'period-values\' id="' +
((__t = ( id )) == null ? '' : __t) +
'_max" type="number" value=\'' +
((__t = ( values[period]['max'] )) == null ? '' : __t) +
'\'>\n                        ';
 } ;
__p += '\n                        <span class=\'color-text\'>' +
((__t = ( period )) == null ? '' : __t) +
'' +
((__t = ( unit ? ' (' + unit + ')' : '' )) == null ? '' : __t) +
'</span>\n                    </div>\n                ';
 }) ;
__p += '\n            ';
 } ;
__p += '\n        ';
 } else if (editValues) { ;
__p += '\n            ' +
((__t = ( i18next.t('alarms.please_fill_values') )) == null ? '' : __t) +
'\n            <a\n                    data-placement=\'bottom\' data-toggle=\'tooltip\' href=\'#\' id=\'outbetween\' style=\'text-decoration: none\'\n                    title=\'' +
((__t = ( i18next.t('alarms.fill_values_tooltip') )) == null ? '' : __t) +
'.\'\n            >\n                (' +
((__t = ( i18next.t('common.how') )) == null ? '' : __t) +
'?)\n            </a>\n            <br>\n            ';
 periods.forEach(function(period, i) { ;
__p += '\n                ';
 const min = values[period]['min'] ;
__p += '\n                ';
 const max = values[period]['max'] ;
__p += '\n                <div class=\'color-legend\' name=\'' +
((__t = ( period )) == null ? '' : __t) +
'\'>\n                    <input class=\'period-values\' type="number" id="' +
((__t = ( i )) == null ? '' : __t) +
'_min" name=\'alarm[values][' +
((__t = ( period )) == null ? '' : __t) +
'][min]\' placeholder=\'min\' value=\'' +
((__t = ( min )) == null ? '' : __t) +
'\'>\n                    <input class=\'period-values\' type="number" id="' +
((__t = ( i )) == null ? '' : __t) +
'_max" name=\'alarm[values][' +
((__t = ( period )) == null ? '' : __t) +
'][max]\' placeholder=\'max\' value=\'' +
((__t = ( max )) == null ? '' : __t) +
'\'>\n                    <span class=\'color-box\' style=\'background-color: ' +
((__t = ( colors[i + 1] )) == null ? '' : __t) +
'\'></span>\n                    <span class=\'color-text\'>' +
((__t = ( period )) == null ? '' : __t) +
' (' +
((__t = ( unit )) == null ? '' : __t) +
')</span>\n                </div>\n            ';
 }) ;
__p += '\n        ';
 } else { ;
__p += '\n            <div class=\'color-legend\' name=\'off\'>\n                <span class=\'color-box\' style=\'background-color: ' +
((__t = ( colors[0] )) == null ? '' : __t) +
'\'></span>\n                <span class=\'color-text\'>off</span>\n            </div>\n            ';
 periods.forEach(function(period, i) { ;
__p += '\n                <div class=\'color-legend\' name=\'' +
((__t = ( period )) == null ? '' : __t) +
'\'>\n                    <span class=\'color-box\' style=\'background-color: ' +
((__t = ( colors[i + 1] )) == null ? '' : __t) +
'\'></span>\n                    <span class=\'color-text\'>\n                        ';
 if (values) { ;
__p += '\n                            (' +
((__t = ( values[period]['min'] )) == null ? '' : __t) +
' ' +
((__t = ( unit )) == null ? '' : __t) +
' < ' +
((__t = ( values[period]['max'] )) == null ? '' : __t) +
' ' +
((__t = ( unit )) == null ? '' : __t) +
')\n                        ';
 } else { ;
__p += '\n                            ' +
((__t = ( period )) == null ? '' : __t) +
'\n                        ';
 } ;
__p += '\n                    </span>\n                </div>\n            ';
 }) ;
__p += '\n        ';
 } ;
__p += '\n    </div>\n    <div id=\'current_position\'></div>\n</div>\n';

}
return __p
}