import { Table, TableBody, TableCell, TableFooterShad, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Button, Card, CardContent, CardHeader, CardStat, CardWrapper, Container, ContentArea, ModalSave, ModelProperty } from '@/components/wm';
import { Plus } from 'lucide-react';
import * as React from 'react';
import { useState } from 'react';

export default function IntegrationLicenses({ licenses, licenseLogs }) {
    const [modalOpen, setModalOpen] = useState(false);

    const description = `You currently have ${licenses.total} contracted licenses`;

    return (
            <Container variant="full">
                {modalOpen && (
                        <ModalSave
                                onClose={() => setModalOpen(false)}
                                model={licenses}
                                mode="edit"
                                title="Add Licenses"
                                description={description}
                        >
                            <ModelProperty label="Add Licenses" property="new_licenses" type="input" dataType="int"/>
                            <ModelProperty property="comment" type="text"/>
                            <ModelProperty property="partner_id" value={licenses.partner_id} type="hidden"/>
                            <ModelProperty property="organization_id" value={licenses.organization_id} type="hidden"/>
                        </ModalSave>
                )}
                <ContentArea>
                    {/* Small Cards Section */}
                    <CardWrapper cardSize="small">
                        <Card>
                            <CardHeader title="Available"
                                        button={
                                            <Button aria-label="Add License" onClick={() => setModalOpen(true)} size="icon" variant="ghost">
                                                <Plus className="tw-w-5 tw-h-5"/>
                                            </Button>
                                        }/>
                            <CardContent>
                                <CardStat>{licenses.available}</CardStat>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader title="Used"/>
                            <CardContent>
                                <CardStat>{licenses.used}</CardStat>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader title="Contracted Licenses"/>
                            <CardContent>
                                <CardStat>{licenses.total}</CardStat>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader
                                    title="Active since"
                            />
                            <CardContent>
                                <CardStat>{WiseMetering.utils.formatDate(licenses.created_at, null, 'YYYY/MM/DD')}</CardStat>
                            </CardContent>
                        </Card>
                    </CardWrapper>
                    {/* Table Section */}
                    <div className="tw-pt-4 tw-overflow-x-auto tw-w-full">
                        <Table className="tw-min-w-full">
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Created At</TableHead>
                                    <TableHead>Description</TableHead>
                                    <TableHead>Comment</TableHead>
                                    <TableHead>New Total</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {licenseLogs.map((log) => (
                                        <TableRow key={log.id}>
                                            <TableCell>{WiseMetering.utils.formatDate(log.created_at)}</TableCell>
                                            <TableCell>{log.description}</TableCell>
                                            <TableCell>{log.comment}</TableCell>
                                            <TableCell>{log.new_total}</TableCell>
                                        </TableRow>
                                ))}
                            </TableBody>
                            <TableFooterShad>
                                <TableRow>
                                    <TableCell> </TableCell>
                                    <TableCell> </TableCell>
                                </TableRow>
                            </TableFooterShad>
                        </Table>
                    </div>
                </ContentArea>
            </Container>
    );
}
