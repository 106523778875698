module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'content\' id=\'dashboard-content\' style=\'margin: 20px 0 0 27px; position: relative;\'>\n    ';
 if(widgets.zones){ ;
__p += '\n        <div class=\'big\' id=\'' +
((__t = ( widgets.zones.el.substr(1) )) == null ? '' : __t) +
'\' style=\'float: left; margin: 0 5px 5px 0;\'></div>\n    ';
 } ;
__p += '\n    ';
 if(widgets.circuits){ ;
__p += '\n        <div class=\'big\' id=\'' +
((__t = ( widgets.circuits.el.substr(1) )) == null ? '' : __t) +
'\' style=\'float: left; margin: 0 5px 5px 0;\'></div>\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
}