WiseMetering.Views.ShowWaterbeepIntegration = WiseMetering.Views.Modal.Show.extend({
    className: 'mbx-info text-left',
    template: 'templates/integrations/waterbeep/show',
    title: () => i18next.t('modal.title.integrations.show'),

    serializeData: function() {
        return {
            ...this.model.toJSON(),
            building: this.model.building(),
            integrationIndicators: this.model.integrationIndicators()
        };
    }
});
