WiseMetering.Views.EditSeu = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/seus/edit',
    title: () => i18next.t('modal.title.seus.edit'),
    form_prefix: 'seu',
    className: 'mbx-info text-left',

    ui: {
        indicators: '#seu-indicator',
        indicatorsSearch: 'input#indicator-search',
        name: 'input#seu-name',
        variables: '#seu-variable',
        variablesSearch: 'input#variable-search'
    },

    events: {
        'change #seu-indicator': 'onChangeindicator',
        'change #seu-utility-kind': 'onChangeUtilityKind',
        'input #seu-name': 'onChangeName',
        'keyup input#indicator-search': 'onIndicatorSearch',
        'keyup input#variable-search': 'onVariableSearch'
    },

    availableUtilityKinds: function() {
        const building = this.model.building();
        return building.availableUtilityKinds();
    },

    onChangeindicator: function() {
        this.selectedIndicator = this.options.indicators.get(this.$('.indicators option:selected').val());
        this.render();
    },

    onChangeName: function() {
        this.selectedName = this.ui.name.val();
    },

    onChangeUtilityKind: function() {
        let slug = this.$('.utility_kinds option:selected').val();
        this.selectedUtilityKind = WiseMetering.utilityKinds.findWhere({ slug });
        this.updateIndicators();
        this.render();
    },

    onIndicatorSearch: function(event) {
        if (event && (event.keyCode || event.which) !== 13) return;

        let indicators = this.options.indicators.map(indicator => indicator.attributes);

        let options = this.search(indicators, this.ui.indicatorsSearch.val(), 'indicator');
        this.ui.indicators.html(options);
    },

    onVariableSearch: function(event) {
        if (event && (event.keyCode || event.which) !== 13) return;

        let variables = this.options.variables.map(indicator => indicator.attributes);
        let options = this.search(variables, this.ui.variablesSearch.val(), 'variable');
        this.ui.variables.html(options);
    },

    search: function(collection, keyword, type) {
        let indicators = collection;

        if (keyword) {
            keyword = new RegExp(keyword.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
            indicators = collection.filter(indicator => {
                return indicator.name.match(keyword) || indicator.id === this.model.get(`${type}_id`);
            });
        }

        let options;

        if (indicators.length === 0) {
            options = `<option disabled selected>${i18next.t('seus.no_indicators_found')}</option>`;
        } else {
            if (type === 'indicator') {
                options = `<option disabled selected>${i18next.t('seus.choose_filtered_option')}</option>`;
            } else {
                options = `<option value='' selected>${i18next.t('seus.no_variable')}</option>`;
            }
            options += WiseMetering.utils.optionsFromArray(indicators);
        }

        return options;
    },

    serializeData: function() {
        let json = this.model.toJSON(),
            originalVariable = this.options.variables.findWhere({ id: this.model.get('variable_id') }),
            originalIndicator = WiseMetering.indicators.findWhere({ id: this.model.get('indicator_id') }),
            originalUtilityKind = this.getIndicatorUtilityKind(originalIndicator);

        if (this.options.indicators.length === 0) {
            this.updateIndicators(originalUtilityKind);
        }

        json.form_prefix = this.form_prefix;
        json.indicators = this.options.indicators;
        json.name = this.selectedName || this.model.get('name');
        json.selectedIndicator = this.selectedIndicator || originalIndicator;
        json.selectedName = this.selectedName;
        json.selectedVariable = originalVariable || {};
        json.selectedUtilityKindSlug = this.selectedUtilityKind ? this.selectedUtilityKind.get('slug') : originalUtilityKind.get('slug');
        json.utility_kinds = this.availableUtilityKinds();
        json.variables = this.options.variables.groupBy('kind_slug');

        return json;
    },

    updateIndicators: function(originalUtilityKind = null) {
        const utilityKind = new WiseMetering.Model.UtilityKind;
        let indicators = WiseMetering.indicators.where({
            building_id: this.model.get('building_id'),
            kind_slug: this.selectedUtilityKind ? this.selectedUtilityKind.indicatorKindSlug() : originalUtilityKind.indicatorKindSlug()
        });
        this.options.indicators.reset();
        this.options.indicators.add(indicators);
    },

    getIndicatorUtilityKind: function(originalIndicator) {
        const utilityKindName = originalIndicator.utilityKindName();
        return WiseMetering.utilityKinds.findWhere({ name: utilityKindName });
    }
});
