WiseMetering.Views.EditInfraspeakIntegration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration',
    template: 'templates/integrations/infraspeak/edit',
    title: () => i18next.t('modal.title.integrations.edit'),

    ui: {
        assetsList: '#assets-list',
        currentAssetsList: '#current-assets-list',
        name: 'input#integration_name',
        search: 'input#asset-search',
        spinner: '#spinner'
    },

    events: {
        'keyup input#asset-search': 'search'
    },

    initialize: function(options) {
        WiseMetering.Views.Modal.Save.prototype.initialize.call(this, options);

        this.externalAssets = null;
        this.partner_id = WiseMetering.integrationLicenses.get(this.model.get("license_id")).get('partner_id');
    },

    fetchExternalAssets: function(searchText) {
        this.modal.block();

        const payload = {
            license_id: this.model.get('license_id'),
            partner_id: this.model.get('partner_id'),
            building_id: this.model.get('building_id'),
            configuration_id: this.model.get('configuration_id'),
            indicator_id: this.model.get('indicator_id'),
            search_text: searchText
        };

        $.ajax({
            url: `/integrations/external_assets`,
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(payload),
            success: function(data) {
                this.externalAssets = data;
                this.ui.assetsList.empty();
                this.externalAssets.forEach(function(asset, index) {
                    this.ui.assetsList.append(
                        `<input class="vertical-middle" type="checkbox" value="${index}"></input>`,
                        `&nbsp;`,
                        `<label>${asset.name}</label><br/>`
                    );
                }.bind(this));
                this.modal.unblock();
            }.bind(this),
            error: function() {
                WiseMetering.layout.showTipper('error', i18next.t('integrations.validation_failed'));
                $('#save').prop('disabled', true);
                this.modal.unblock();
            }.bind(this)
        });
    },

    getFormData: function() {
        const self = this;
        var data = this.formSerializer();
        const assets_attributes = [];

        this.ui.currentAssetsList.find('input[type="checkbox"]').each(function() {
            const isChecked = $(this).is(':checked');
            const assetId = $(this).val();

            assets_attributes.push({
                id: assetId,
                _destroy: !isChecked
            });
        });

        this.ui.assetsList.find('input[type="checkbox"]').each(function() {
            const isChecked = $(this).is(':checked');

            if(isChecked){
                const asset = self.externalAssets[$(this).val()];

                const asset_info = {
                    name: asset['name'],
                    asset_reference: asset['asset_reference'],
                    local_reference: asset['local_reference'],
                }

                assets_attributes.push(asset_info);
            }
        });

        data.assets_attributes = assets_attributes;

        return data;
    },

    onShow: function() {
        const currentAssets = this.model.get('assets');

        currentAssets.forEach(function(asset) {
            this.ui.currentAssetsList.append(
                `<input class="vertical-middle" type="checkbox" value="${asset.id}" checked></input>`,
                `&nbsp;`,
                `<label>${asset.name}</label><br/>`
            );
        }.bind(this));
    },

    search: function(event) {
        if (event && (event.keyCode || event.which) !== 13) {
            return;
        }

        if (this.ui.search.val().length < 3) {
            return;
        }
        this.externalAssets = this.fetchExternalAssets(this.ui.search.val());
    }
});
