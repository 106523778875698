module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'integration_configuration_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_configuration_name\' name=\'integration_configuration[name]\'>\n        </dd>\n        <dt>\n            <label for=\'integration_configuration_building_id\'>' +
((__t = ( i18next.t('glossary.building') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'integration_configuration_building_id\' name=\'integration_configuration[building_id]\'></select>\n        </dd>\n        <dt>\n            <label for=\'integration_configuration_identifier\'>' +
((__t = ( i18next.t('glossary.identifier') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_configuration_identifier\' name=\'integration_configuration[identifier]\'>\n        </dd>\n        <dt>\n            <label for=\'integration_configuration_username\'>' +
((__t = ( i18next.t('glossary.user') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_configuration_username\' name=\'integration_configuration[username]\'>\n        </dd>\n        <dt>\n            <label for=\'integration_configuration_password\'>' +
((__t = ( i18next.t('common.password') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_configuration_password\' name=\'integration_configuration[password]\'>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}