window.animateLoader = function(tgt) {
    tgt.css({ width: 0 });
    tgt.animate({ width: '100%' }, 20000, 'linear', function() {
        animateLoader(tgt);
    });
};

window.removeLoader = function(tgt) {
    tgt.children('div.ldr_il').remove();
};

window.addLoader = function(target) {
    const
        container = $('<div>').addClass('ldr_il crn_all'),
        loader = $('<div>').css({ 'background': '#eeeeee', width: 0, height: '6px' });

    container.append(loader);
    target.html(container);
    animateLoader(loader);
};

window.formatValue = function(value = '', unit = '', maximumFractionDigits = 2, maxPower) {
    if (value === '') {
        return '';
    }

    if (!unit) {
        return value;
    }

    try {
        value = parseFloat(value);
        if (isNaN(value)) {
            value = 0;
        }
    } catch (e) {
    }

    WiseMetering.numberFormatter ??= new NumberFormatter(WiseMetering.currentLocale);

    if (unit === 's') {
        return WiseMetering.numberFormatter.seconds(value, unit, maximumFractionDigits);
    } else if (['€', 'R$', '£', 'Kz', '$'].includes(unit)) {
        return WiseMetering.numberFormatter.currency(value, unit, maximumFractionDigits, maxPower);
    } else if (['Wh', 'VArh', 'g', 'W', 'VAr', 'VA'].includes(unit)) {
        return WiseMetering.numberFormatter.factorization(value, unit, maximumFractionDigits, maxPower);
    }

    return WiseMetering.numberFormatter.simple(value, unit, maximumFractionDigits);
};

window.generateUUID = function() {
    const rand = function() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };

    return `${rand()}${rand()}-${rand()}-${rand()}-${rand()}-${rand()}${rand()}${rand()}`;
};
