WiseMetering.Model.TenantInvoice = WiseMetering.Model.extend({
    urlRoot: '/tenant_invoices',

    building: function() {
        return WiseMetering.zones.get(this.get('building_id'));
    },

    fileLink: function() {
        return this.get('file') ? WiseMetering.apiUrl + this.url() + '/file' : null;
    },

    reGenerate: function() {
        const url = this.url() + '/regenerate';
        return this.save({}, { url });
    },

    icon: function() {
        return 'invoices';
    },

    timezone: function() {
        return this.building() ? this.building().get('timezone') : WiseMetering.currentOrganization.get('timezone');
    }
});
