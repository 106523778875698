WiseMetering.Views.NewEtl = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'etl',
    template: 'templates/etls/new',
    title: () => i18next.t('modal.title.etls.new'),

    ui: {
        container: '#type-container',
        driver: '#etl_driver'
    },

    events: {
        'change select#etl_driver': 'onDriverChange'
    },

    afterSave: function() {
        WiseMetering.indicators.fetch();
    },

    getFormData: function() {
        let data = this.formSerializer();

        if (this.model.isNew()) {
            data.organization_id = WiseMetering.currentOrganization.id;
        }
        return data;
    },

    onDriverChange: function() {
        let view;

        const selectedOption = this.ui.driver.find('option:selected');

        switch (selectedOption.attr('slug')) {
            case 'lora':
                view = new WiseMetering.Views.NewLoraEtl({ modal: this.modal, model: this.model });
                break;
            default:
                view = new WiseMetering.Views.NewBaseEtl({ modal: this.modal, model: this.model });
                break;
        }

        if (view) {
            view.setElement(this.ui.container).render();
            this.modal.redraw();
        }
    },

    serializeData: function() {
        let json = this.model.toJSON();
        json.etl_rates = WiseMetering.etlRates.reduce((a, v) => ({ ...a, [v]: this.model.getHumanEtlRate(v) }), {});
        json.start_at = moment().format('YYYY-MM-DD');
        return json;
    }
});
