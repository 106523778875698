WiseMetering.Views.NewShopperTrakIntegration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration',
    template: 'templates/integrations/shopper_trak/new',
    ui: {
        configurations: '#integration_configuration_id',
        indicators: '#integration_indicator_id'
    },
    title: () => i18next.t('modal.title.integrations.new'),

    afterSave: function() {
        WiseMetering.indicators.fetch();
    },

    onShow: function() {
        const
            configurations = WiseMetering.utils.optionsFromModels(this.options.license.configurations().models, 'name'),
            indicators = WiseMetering.utils.optionsFromModels(WiseMetering.indicators.utilityIndicators().models);

        this.ui.configurations.html(configurations);
        this.ui.indicators.html(indicators);
    },

    getFormData: function() {
        const data = this.formSerializer();
        data.partner_id = this.options.license.get('partner_id');
        return data;
    }
});
