module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'content\' id=\'dashboard-content\' style=\'position: relative; text-align: center; width: 100%; margin: 0 auto;\'>\n    <div class="navbar" style="margin: 5px 5px 0 5px;">\n        <div class="navbar-inner">\n            <ul class="nav widget-options" style="width:100%;">\n                <li>\n                    <ul class="ctrl" style="line-height:25px;">\n                        <li class="label">' +
((__t = ( i18next.t('common.period') )) == null ? '' : __t) +
':</li>\n                        <li value="mtd" class="period mode" ';
 if (period === 'mtd') { ;
__p += 'style="text-decoration:underline;"\n                                ';
 } ;
__p += '\n                        >' +
((__t = ( i18next.t('glossary.mtd') )) == null ? '' : __t) +
'</li>\n                        <li value="ytd" class="period mode" ';
 if (period === 'ytd') { ;
__p += 'style="text-decoration:underline;"\n                                ';
 } ;
__p += '\n                        >' +
((__t = ( i18next.t('glossary.ytd') )) == null ? '' : __t) +
'</li>\n                    </ul>\n                </li>\n                <li>\n                    <ul class="ctrl" style="line-height:25px;">\n                        <li class="label">' +
((__t = ( i18next.t('glossary.utility_kind') )) == null ? '' : __t) +
':</li>\n                        ';
 utilityKinds.forEach(function (utilityKind) { ;
__p += '\n                            <li value="' +
((__t = ( utilityKind.get('name') )) == null ? '' : __t) +
'" class="utility_kind mode" ';
 if (selectedUtilityKind.name === utilityKind.get('slug')) { ;
__p += 'style="text-decoration:underline;"\n                                    ';
 } ;
__p += '\n                            >\n                                ' +
((__t = ( i18next.t(`utility_kinds.${utilityKind.get('slug')}`).capitalize() )) == null ? '' : __t) +
'\n                            </li>\n                        ';
 }) ;
__p += '\n                        <li value="all" class="utility_kind mode" ';
 if (selectedUtilityKind === 'all') { ;
__p += 'style="text-decoration:underline;"\n                                ';
 } ;
__p += '\n                        >\n                            ' +
((__t = ( i18next.t('utility_kinds.all').capitalize() )) == null ? '' : __t) +
'\n                        </li>\n                    </ul>\n                </li>\n                <div class=\'svg_icon sort-button\'>\n                    ' +
((__t = ( WiseMetering.icon('sort') )) == null ? '' : __t) +
'\n                </div>\n                ';
 if (WiseMetering.currentUser.isSuperUser() && isBuilding) { ;
__p += '\n                    <div class=\'svg_icon rebuild-button\'>\n                        ' +
((__t = ( WiseMetering.icon('refresh') )) == null ? '' : __t) +
'\n                    </div>\n                ';
 } ;
__p += '\n            </ul>\n        </div>\n    </div>\n    <div id="widgets"></div>\n</div>\n';

}
return __p
}