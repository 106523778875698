WiseMetering.Model.IntegrationLicense = WiseMetering.Model.extend({
    urlRoot: '/integration_licenses',

    addLicenses: function(attrs, options = {}) {
        options.type = 'POST';
        options.url = this.isNew() ? '/integration_licenses' : `/integration_licenses/${this.id}/add_license`;

        return Backbone.Model.prototype.save.call(this, attrs, options);
    },

    configurations: function() {
        return new WiseMetering.Collection.IntegrationConfigurations(WiseMetering.integrationConfigurations.where({ license_id: this.id }));
    },

    integrations: function() {
        return new WiseMetering.Collection.Integrations(WiseMetering.integrations.where({ license_id: this.id }));
    },

    partner: function() {
        return WiseMetering.integrationPartners.get(this.get('partner_id'));
    }
});
