module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'integration_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_name\' name=\'integration[name]\' value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n        </dd>\n        <dt>\n            <label for=\'integration_ratio\'>' +
((__t = ( i18next.t('glossary.ratio') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_ratio\' name=\'integration[ratio]\' value="' +
((__t = ( ratio )) == null ? '' : __t) +
'">\n        </dd>\n        <dt>\n            <label for=\'integration_reference\'>' +
((__t = ( i18next.t('common.reference') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_reference\' name=\'integration[reference]\' value="' +
((__t = ( reference )) == null ? '' : __t) +
'">\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}