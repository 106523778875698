module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'integration_building_id\'>' +
((__t = ( i18next.t('glossary.building') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'integration_building_id\' name=\'integration[building_id]\'></select>\n        </dd>\n        <dt>\n            <label for=\'integration_cpe\'>' +
((__t = ( i18next.t('integrations.cpe') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_cpe\' name=\'integration[cpe]\'>\n        </dd>\n        <dt>\n            <label for=\'integration_reactive\'>' +
((__t = ( i18next.t('glossary.reactive') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'vertical-middle\' id=\'integration_reactive\' name=\'integration[reactive]\' type=\'checkbox\'>\n        </dd>\n        <dt>\n            <label for=\'integration_consent\'>' +
((__t = ( i18next.t('integrations.consent') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'vertical-middle\' id=\'integration_consent\' name=\'integration[consent]\' type=\'checkbox\'>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}