module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'integration_consent\'>' +
((__t = ( i18next.t('integrations.consent') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input ' +
((__t = ( consent ? 'checked' : '' )) == null ? '' : __t) +
' class=\'vertical-middle\' id=\'integration_consent\' name=\'integration[consent]\' type=\'checkbox\'>\n        </dd>\n        ';
 if (!reactive) { ;
__p += '\n            <dt>\n                <label for=\'integration_reactive\'>' +
((__t = ( i18next.t('glossary.reactive') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <input ' +
((__t = ( reactive ? 'checked' : '' )) == null ? '' : __t) +
' class=\'vertical-middle\' id=\'integration_reactive\' name=\'integration[reactive]\' type=\'checkbox\'>\n            </dd>\n        ';
 } ;
__p += '\n    </dl>\n</form>\n';

}
return __p
}