module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<table class="interval">\n    <tr class="spacer"></tr>\n    <tr>\n        <th class="capitalize head" colspan="2">\n            ' +
((__t = ( i18next.t('common.interval') )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <th class="capitalize">\n            ' +
((__t = ( i18next.t('common.from') )) == null ? '' : __t) +
'\n        </th>\n        <th class="capitalize">\n            ' +
((__t = ( i18next.t('common.to') )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <td>\n            <input class="from" disabled name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][from]" type="text" value="' +
((__t = ( interval.from )) == null ? '' : __t) +
'">\n        </td>\n        <td>\n            <input class="to" name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][to]" type="text" value="' +
((__t = ( interval.to )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n    <tr class="spacer"></tr>\n    <tr>\n        <th class="head" colspan="2">\n            ' +
((__t = ( i18next.t('tariffs.power_per_day', { currency: WiseMetering.getCurrency() }) )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <th colspan="2">\n            ' +
((__t = ( i18next.t('tariffs.required_power') )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <td colspan="2">\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][power][contracted]" style="width: 436px" type="text" value="' +
((__t = ( interval.values?.power.required )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n    <tr class="spacer"></tr>\n    <tr>\n        <th class="head" colspan="2">\n            Active Energy\n        </th>\n    </tr>\n    <tr class="spacer"></tr>\n    <tr>\n        <th colspan="2">\n            CGS (Custos de operação e gestão do sistema - €/kWh)\n        </th>\n    </tr>\n    <tr>\n        <td colspan="2">\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][cgs]" style="width: 436px" type="text" type="text" value="' +
((__t = ( interval.values?.cgs )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n    <tr class="spacer"></tr>\n    <tr>\n        <th colspan="2">\n            Perdas (Perdas da Rede)\n        </th>\n    </tr>\n    <tr>\n        <td colspan="2">\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][losses]" style="width: 436px" type="text" type="text" value="' +
((__t = ( interval.values?.losses )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n    <tr class="spacer"></tr>\n    <tr>\n        <th colspan="2">\n            FA (Factor de Adequação)\n        </th>\n    </tr>\n    <tr>\n        <td colspan="2">\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][fa]" style="width: 436px" type="text" type="text" value="' +
((__t = ( interval.values?.fa )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n    <tr class="spacer"></tr>\n    <tr>\n        <th colspan="2">\n            Kp (Valor fixo correspondente aos gastos operacionais da Luzboa - €/MWh)\n        </th>\n    </tr>\n    <tr>\n        <td colspan="2">\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][kp]" style="width: 436px" type="text" type="text" value="' +
((__t = ( interval.values?.kp )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n</table>\n';

}
return __p
}