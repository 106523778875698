module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'accordion\' style=\'margin-bottom: 0;\'>\n    <div class=\'accordion-group\'>\n        <div class=\'accordion-heading\'>\n            <div class=\'accordion-toggle\'>\n                <div class=\'svg_icon\'>\n                    ' +
((__t = ( WiseMetering.icon('info') )) == null ? '' : __t) +
'\n                </div>\n                ' +
((__t = ( i18next.t('ui.system_info') )) == null ? '' : __t) +
'\n            </div>\n        </div>\n        <div class=\'accordion-body\'>\n            <div class=\'accordion-inner\'>\n                <div class=\'per-box\'>\n                    <dl>\n                        <dt>ID:</dt>\n                        <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n                        <dt>' +
((__t = ( i18next.t('glossary.created_by') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( creator )) == null ? '' : __t) +
', ' +
((__t = ( created_at )) == null ? '' : __t) +
'</dd>\n                        <dt>' +
((__t = ( i18next.t('glossary.last_change') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( updater )) == null ? '' : __t) +
', ' +
((__t = ( updated_at )) == null ? '' : __t) +
'</dd>\n                    </dl>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}