module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<table class="interval">\n    <tr class="spacer"></tr>\n    <tr>\n        <th class="capitalize head" colspan="2">\n            ' +
((__t = ( i18next.t('common.interval') )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <th class="capitalize">\n            ' +
((__t = ( i18next.t('common.from') )) == null ? '' : __t) +
'\n        </th>\n        <th class="capitalize">\n            ' +
((__t = ( i18next.t('common.to') )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <td>\n            <input class="from" disabled name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][from]" type="text" value="' +
((__t = ( interval.from )) == null ? '' : __t) +
'">\n        </td>\n        <td>\n            <input class="to" name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][to]" type="text" value="' +
((__t = ( interval.to )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n    <tr class="spacer"></tr>\n    <tr>\n        <th class="head" colspan="2">\n            ' +
((__t = ( i18next.t('tariffs.power_per_day', { currency: WiseMetering.getCurrency() }) )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <th colspan="2">\n            ' +
((__t = ( i18next.t('tariffs.required_power') )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <td colspan="2">\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][power][contracted]" style="width: 436px" type="text" value="' +
((__t = ( interval.values?.power.contracted )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n    <tr class="spacer"></tr>\n    <tr>\n        <th class="head" colspan="2">\n            ' +
((__t = ( i18next.t('tariffs.active_per_unit', { currency: WiseMetering.getCurrency() }) )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    ';
 ['Ponta', 'Cheia', 'Vazio'].forEach(function(period) { ;
__p += '\n        <tr>\n            <th>\n                ' +
((__t = ( period )) == null ? '' : __t) +
' (I, IV)\n            </th>\n            <th>\n                ' +
((__t = ( period )) == null ? '' : __t) +
' (II, III)\n            </th>\n        </tr>\n        <tr>\n            <td>\n                <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][winter][' +
((__t = ( period.toLowerCase() )) == null ? '' : __t) +
']" type="text" value="' +
((__t = ( interval.values?.winter[period.toLowerCase()] )) == null ? '' : __t) +
'">\n            </td>\n            <td>\n                <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][summer][' +
((__t = ( period.toLowerCase() )) == null ? '' : __t) +
']" type="text" value="' +
((__t = ( interval.values?.summer[period.toLowerCase()] )) == null ? '' : __t) +
'">\n            </td>\n        </tr>\n    ';
 }) ;
__p += '\n</table>\n';

}
return __p
}