WiseMetering.Views.IndicatorManage = WiseMetering.Views.Modal.Save.extend({

    title: () => i18next.t('modal.title.indicators.add'),
    className: 'mbx-info text-left',
    input_readers: [{
        type: 'checkbox', func: function(el) {
            if (el.prop('checked')) return el.val();
        }
    }],

    initialize: function() {
        if (this.options.zone_id) {
            this.form_prefix = 'zone';
            this.model = WiseMetering.zones.get(this.options.zone_id);
        } else if (this.options.circuit_id) {
            this.form_prefix = 'circuit';
            this.model = WiseMetering.circuits.get(this.options.circuit_id);
        } else if (this.options.folder_id) {
            this.form_prefix = 'folder';
            this.model = WiseMetering.folders.get(this.options.folder_id);
        }
        WiseMetering.Views.Modal.Save.prototype.initialize.apply(this);
    },

    getTemplate: function() {
        return this.options.folder_id ? 'templates/indicators/add_folder' : 'templates/indicators/add';
    },

    serializeData: function() {
        let availableIndicators;
        const building = this.model.building();

        if (this.options.folder_id) {
            availableIndicators = WiseMetering.indicators.models;
            if (building) {
                availableIndicators = WiseMetering.indicators.filter(function(indicator) {
                    return indicator.get('building_id') === building.id || indicator.get('parent_id') === this.model.id || !indicator.get('building_id');
                }.bind(this));
            }
        } else {
            availableIndicators = WiseMetering.indicators.filter(function(indicator) {
                if (indicator.get('parent_id') === this.model.id) {
                    return true;
                } else {
                    return indicator.get('building_id') === building.id && !indicator.get('parent_id');
                }
            }.bind(this));
        }

        availableIndicators = _.chain(availableIndicators)
        .groupBy(function(indicator) {
            return indicator.kind().get('name');
        })
        .map(function(indicators, kind) {
            return { kind: kind, indicators: indicators };
        })
        .sortBy(function(list) {
            return list.kind;
        })
        .value();

        return { availableIndicators, currentIndicators: this.model.indicators(), form_prefix: this.form_prefix };
    },

    afterSave: function() {
        return WiseMetering.indicators.fetch();
    },

    formSerializer: function() {
        if (this.options.folder_id) {
            if (this.input_readers) {
                _.each(this.input_readers, function(input_reader) {
                    Backbone.Syphon.InputReaders.register(input_reader.type, input_reader.func);
                });
            }
            return Backbone.Syphon.serialize(this)[this.form_prefix];
        } else {
            const indicator_ids = [];
            this.$('select :selected').each(function(i, selected) {
                const value = $(selected).val();
                if (value) {
                    indicator_ids.push(value);
                }
            });
            return { indicator_ids };
        }
    }

});
