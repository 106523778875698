import * as React from 'react';

const Content = ({ children }) => {
    return (
            <div className="tw-flex-auto tw-px-6 tw-pb-6">
                {children}
            </div>
    );
};

export { Content };
