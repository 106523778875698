WiseMetering.Views.OrganizationTabs = WiseMetering.Views.Content.extend({
    initialize: function() {
        this.icon = 'cog';
        this.title = i18next.t('ui.menu.setup');
        this.tabs = [
            {
                id: 'details',
                name: i18next.t('common.details'),
                view: WiseMetering.Views.OrganizationDetailsTab,
                active: true
            },
            {
                id: 'active-sites',
                name: i18next.t('organizations.active_sites'),
                view: WiseMetering.Views.IndexOrganizationActiveSites
            },
            {
                id: 'logs',
                name: i18next.t('common.history'),
                view: WiseMetering.Views.IndexOrganizationLogs
            },
            {
                id: 'new-sites',
                name: i18next.t('organizations.new_sites'),
                view: WiseMetering.Views.IndexOrganizationNewSites,
                dropdownTab: true
            },
            {
                id: 'disabled-sites',
                name: i18next.t('organizations.disabled_sites'),
                view: WiseMetering.Views.IndexOrganizationDisabledSites,
                dropdownTab: true
            },
            {
                id: 'api-requests',
                name: i18next.t('glossary.api_requests'),
                view: WiseMetering.Views.IndexApiRequests,
                dropdownTab: true
            }
        ];

        if (WiseMetering.currentUser.isSuperUser()) {
            const menus = [
                {
                    id: 'admin',
                    name: i18next.t('glossary.admin'),
                    view: WiseMetering.Views.Admin,
                    dropdownTab: true
                },
                {
                    id: 'etls',
                    name: i18next.t('glossary.etl'),
                    view: WiseMetering.Views.IndexEtl,
                    dropdownTab: true
                },
                {
                    id: 'holidays',
                    name: i18next.t('glossary.holiday'),
                    view: WiseMetering.Views.IndexHoliday,
                    dropdownTab: true
                },
                {
                    id: 'root-folder',
                    modal: this.rootFolder,
                    name: i18next.t('ui.menu.create_root_folder'),
                    dropdownTab: true
                },
                {
                    id: 'upload-data',
                    modal: this.uploadData,
                    name: i18next.t('ui.menu.upload_data'),
                    dropdownTab: true
                }
            ];

            this.tabs.push(...menus);
        }
    },

    rootFolder: function() {
        let rootFolder = WiseMetering.folders.findWhere({ parent_id: null });
        if (rootFolder) {
            Backbone.history.navigate('/folders/' + rootFolder.get('id'), { trigger: true });
        } else {
            new WiseMetering.Views.FolderManage({
                collection: WiseMetering.folders,
                model: new WiseMetering.Model.Folder({
                    organization_id: WiseMetering.currentOrganization.id
                })
            });
        }
    },

    uploadData: function() {
        new WiseMetering.Views.UploadData();
    }
});
