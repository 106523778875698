WiseMetering.Collection.TenantInvoices = WiseMetering.Collection.extend({
    hasOrganization: true,
    model: WiseMetering.Model.TenantInvoice,
    url: '/tenant_invoices',
    comparator: 'from',

    addToZipUrl: function(ids) {
        const queryParams = ids.map(item => `ids[]=${item}`).join('&');
        return `${WiseMetering.apiUrl}${this.url}/zip?${queryParams}`;
    },

    exportDataUrl: function(ids) {
        const queryParams = ids.map(item => `ids[]=${item}`).join('&');
        return `${WiseMetering.apiUrl}${this.url}/export?${queryParams}`;
    },

    findByTenantId: function(tenantId) {
        let filteredTenantInvoices = this.where({ tenant_id: tenantId });
        return new WiseMetering.Collection.TenantInvoices(filteredTenantInvoices);
    },

    findByBuildingId: function(buildingId) {
        let filteredTenantInvoices = this.where({ building_id: buildingId });
        return new WiseMetering.Collection.TenantInvoices(filteredTenantInvoices);
    }
});
