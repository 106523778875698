import * as React from 'react';

export default ({ message, onDelete }) => {
    if (!message) {
        return (
            <div className="message-wrapper">
                <div className="message-content">
                    <div className="message-content empty">{i18next.t('messages.select_one')}</div>
                </div>
            </div>
        );
    }

    return (
        <div className="message-wrapper">
            <div className="message-content">
                <div className="message-banner">
                    <img src="/images/wisemetering/negative-logo-horizontal.png" alt="Banner"></img>
                </div>
                <div className="message-header">
                    <h3 className="message-title">{message.get('title')}</h3>
                    <div onClick={() => onDelete(message.id)}>
                        <span className="message-delete-btn fas fa-trash">&nbsp;</span>
                    </div>
                </div>
                <div className="message-date">{WiseMetering.utils.formatDate(message.get('created_at'))}</div>
                <div className="message-area" dangerouslySetInnerHTML={{ __html: message.get('body') }}/>
                <footer className="message-footer">
                    <p><b>WiseMetering</b></p>
                    <p>Rua Afonso Annes Penedo, 56</p>
                    <p>1950-014 Lisbon, Portugal</p>
                </footer>
            </div>
        </div>
    );
};
