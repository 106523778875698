module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'accordion-group\'>\n    <div class=\'accordion-heading\'>\n        <div class=\'accordion-toggle\'>\n            <div class=\'svg_icon\'>\n                ' +
((__t = ( WiseMetering.icon('tariffs') )) == null ? '' : __t) +
'\n            </div>\n            ' +
((__t = ( i18next.t('glossary.tariffs') )) == null ? '' : __t) +
'\n            ';
 if(WiseMetering.currentUser.get('manager')){ ;
__p += '\n                <div class=\'wmui-btn\' id=\'edit_tariffs\' style=\'float: right;\'>' +
((__t = ( i18next.t('ui.buttons.manage') )) == null ? '' : __t) +
'</div>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n\n    <div class=\'accordion-body\'>\n        <div class=\'accordion-inner\'>\n            <div class=\'per-box\'>\n                <dl>\n                    ';
 tariffs.forEach(function(tariff) { ;
__p += '\n                        <dt>' +
((__t = ( tariff.label )) == null ? '' : __t) +
':</dt>\n                        <dd class=\'link zone_tariff_details\' data-id=\'' +
((__t = ( tariff.id )) == null ? '' : __t) +
'\'>' +
((__t = ( tariff.name )) == null ? '' : __t) +
'</dd>\n                    ';
 }) ;
__p += '\n                </dl>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}