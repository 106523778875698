module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'widget\'>\n    <div class=\'title\'>\n        ' +
((__t = ( title )) == null ? '' : __t) +
'\n    </div>\n    <div class=\'content value-displayer\'>\n        <div class="value">\n            ' +
((__t = ( data.value )) == null ? '' : __t) +
'\n        </div>\n        <div class="name">\n            ' +
((__t = ( name )) == null ? '' : __t) +
'\n            ';
 if (data.counter) { ;
__p += '\n            <span class="secondary-value">(#' +
((__t = ( data.counter )) == null ? '' : __t) +
')\n                ';
 } ;
__p += '\n            </span>\n        </div>\n    </div>\n</div>\n';

}
return __p
}