module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<table class=\'interval\'>\n    <tr class="spacer"></tr>\n    <tr>\n        <th class="capitalize head" colspan="2">\n            ' +
((__t = ( i18next.t('common.interval') )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <th class="capitalize">\n            ' +
((__t = ( i18next.t('common.from') )) == null ? '' : __t) +
'\n        </th>\n        <th class="capitalize">\n            ' +
((__t = ( i18next.t('common.to') )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <td>\n            <input class="from" disabled name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][from]" type="text" value="' +
((__t = ( interval.from )) == null ? '' : __t) +
'">\n        </td>\n        <td>\n            <input class=\'to\' name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][to]" type="text" value="' +
((__t = ( interval.to )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n    <tr class=\'spacer\'></tr>\n    <tr>\n        <th class=\'head\' colspan=\'2\'>\n            Standing Charge\n        </th>\n    </tr>\n    <tr>\n        <th class=\'top\'>\n            Standing Charge Kind\n        </th>\n        <td>\n            <input ' +
((__t = ( interval.values?.standing_charge_kind === 'day' ? 'checked' : '' )) == null ? '' : __t) +
' name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][standing_charge_kind]" type="radio" value="day">\n            ' +
((__t = ( WiseMetering.getCurrency() )) == null ? '' : __t) +
'/day\n            <br>\n            <input ' +
((__t = ( interval.values?.standing_charge_kind === 'month' ? 'checked' : '' )) == null ? '' : __t) +
' name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][standing_charge_kind]" type="radio" value="month">\n            ' +
((__t = ( WiseMetering.getCurrency() )) == null ? '' : __t) +
'/month\n        </td>\n    </tr>\n    <tr>\n        <th>\n            Standing Charge Value\n        </th>\n        <td>\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][standing_charge_value]" type="text" value="' +
((__t = ( interval.values?.standing_charge_value )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n    <tr class=\'spacer\'></tr>\n    <tr>\n        <th class=\'head\' colspan=\'2\'>\n            Availability\n        </th>\n    </tr>\n    <tr>\n        <th class=\'top\'>\n            Availability Kind\n        </th>\n        <td>\n            <input ' +
((__t = ( interval.values?.availability_kind === 'day' ? 'checked' : '' )) == null ? '' : __t) +
' name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][availability_kind]" type="radio" value="day">\n            ' +
((__t = ( WiseMetering.getCurrency() )) == null ? '' : __t) +
'/day\n            <br>\n            <input ' +
((__t = ( interval.values?.availability_kind === 'month' ? 'checked' : '' )) == null ? '' : __t) +
' name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][availability_kind]" type="radio" value="month">\n            ' +
((__t = ( WiseMetering.getCurrency() )) == null ? '' : __t) +
'/month\n        </td>\n    </tr>\n    <tr>\n        <th>\n            Availability Value\n        </th>\n        <td>\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][availability_value]" type="text" value="' +
((__t = ( interval.values?.availability_value )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n    <tr class=\'spacer\'></tr>\n    <tr>\n        <th class=\'head\' colspan=\'2\'>\n            Consumption Charges (' +
((__t = ( WiseMetering.getCurrency(1 / 100) )) == null ? '' : __t) +
'/kWh)\n        </th>\n    </tr>\n    <tr>\n        <th>\n            Green Unit Charge\n        </th>\n        <td>\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][unit_charge_green]" type="text" value="' +
((__t = ( interval.values?.unit_charge_green )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n    <tr>\n        <th>\n            Amber Unit Charge\n        </th>\n        <td>\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][unit_charge_amber]" type="text" value="' +
((__t = ( interval.values?.unit_charge_amber )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n    <tr>\n        <th>\n            Red Unit Charge\n        </th>\n        <td>\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][unit_charge_red]" type="text" value="' +
((__t = ( interval.values?.unit_charge_red )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n    <tr class=\'spacer shaded\'></tr>\n    <tr>\n        <th>\n            CCL\n        </th>\n        <td>\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][ccl]" type="text" value="' +
((__t = ( interval.values?.ccl )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n</table>\n';

}
return __p
}