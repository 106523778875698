WiseMetering.Views.EditAlarm = WiseMetering.Views.Modal.Save.extend({
    events: {
        'click #add-asset': 'onAddAsset',
        'click #add-notification': 'onAddNotification',
        'click #remove-asset': 'onRemoveAsset',
        'click #remove-notification': 'onRemoveNotification'
    },
    form_prefix: 'alarm',
    scheduleView: null,
    template: 'templates/alarms/edit',
    ui: {
        integrationAssets: '#integration-assets',
        notifications: '#notifications',
        schedule: '#alarm-schedule'
    },
    width: 1150,
    title: () => i18next.t('modal.title.alarms.edit'),

    addAsset: function(assetId = null) {
        const indicatorId = this.model.get('indicator_id');
        let options = [];

        WiseMetering.integrations.forEach(function(integration) {
            if (integration.get('indicator_id') === indicatorId  && integration.get('assets').length > 0) {
                integration.get('assets').forEach(function(asset) {
                    options.push({assetId: asset.id, assetName: asset.name})
                });
            }
        })

        this.ui.integrationAssets.append(JST['templates/alarms/integration_assets']({ options, assetId }));
    },

    addNotification: function(userId = null) {
        this.ui.notifications.append(JST['templates/alarms/notification']({ userId }));
    },

    className: function() {
        if (!this.model.get('schedule_id')) {
            return 'mbx-info text-left';
        }
    },

    getFormData: function() {
        const data = this.formSerializer();

        data.assets ??= [];
        data.user_ids ??= [];

        if (data.values) {
            const indicator = this.model.indicator();

            let ratio = 1;
            if (['W', 'Wh', 'VArh'].includes(indicator.get('unit'))) {
                ratio = 1000;
            }

            for (let period in data.values) {
                ['min', 'max'].forEach(entry => data.values[period][entry] = Number(data.values[period][entry]) * ratio);
            }
        }

        return data;
    },

    onAddAsset: function() {
        this.addAsset();
    },

    onRemoveAsset: function(ev) {
        ev.preventDefault();
        $(ev.currentTarget).parent().remove();
    },

    onAddNotification: function() {
        this.addNotification();
    },

    onRemoveNotification: function(ev) {
        ev.preventDefault();
        $(ev.currentTarget).parent().remove();
    },

    onRender: function() {
        const
            alarmSchedule = this.model.schedule(),
            indicator = this.model.indicator(),
            periods = alarmSchedule.periods(),
            schedule = alarmSchedule.schedule();

        let unit = indicator.get('unit'), values = this.model.get('values');

        if (['W', 'Wh', 'VArh'].includes(unit)) {
            unit = `k${unit}`;
            values = {};
            Object.entries(this.model.get('values')).forEach(([key, hash]) => {
                values[key] = { max: hash['max'] / 1000, min: hash['min'] / 1000 };
            });
        }

        this.scheduleView = new WiseMetering.Views.AlarmSchedule({ editValues: true, periods, schedule, unit, values });
        this.ui.schedule.append(this.scheduleView.el);

        WiseMetering.modalBox.currentView.redraw();

        this.model.get('user_ids').forEach(userId => this.addNotification(userId));
        this.model.get('assets').forEach(assetId => this.addAsset(assetId));
    },

    serializeData: function() {
        return Object.assign(
            this.model.toJSON(), {
                created_at: WiseMetering.utils.formatDate(this.model.get('created_at')),
                indicator: this.model.indicator().get('name'),
                renderIntegrationAssets: this.model.valid_alarm_integration()
            }
        );
    }

});
