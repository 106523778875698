module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dl class=\'stepped zero\'>\n    <div style="display:flex;justify-content: space-between;">\n        <div>\n            <dt>ID</dt>\n            <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n        </div>\n        <div>\n            <div class="state-label" style="background-color:' +
((__t = ( stateColor )) == null ? '' : __t) +
';">' +
((__t = ( state )) == null ? '' : __t) +
'</div>\n        </div>\n    </div>\n    <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('common.description') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( description )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('common.type') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( type )) == null ? '' : __t) +
'</dd>\n    ';
 if (building) { ;
__p += '\n        <dt>' +
((__t = ( i18next.t('glossary.building') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( building )) == null ? '' : __t) +
'</dd>\n    ';
 } ;
__p += '\n    <dt>' +
((__t = ( i18next.t('glossary.indicator') )) == null ? '' : __t) +
'</dt>\n    <dd><a id=\'close\' href="' +
((__t = ( indicator.nodeUrl() )) == null ? '' : __t) +
'">' +
((__t = ( indicator.get('name') )) == null ? '' : __t) +
'</a></dd>\n    <dt>\n        ';
 if (to) { ;
__p +=
((__t = ( i18next.t('common.from') )) == null ? '' : __t) +
'\n        ';
 } else { ;
__p +=
((__t = ( i18next.t('markers.event_at') )) == null ? '' : __t) +
'\n        ';
 } ;
__p += '\n    </dt>\n    <dd>' +
((__t = ( from )) == null ? '' : __t) +
'</dd>\n    ';
 if (to) { ;
__p += '\n        <dt>' +
((__t = ( i18next.t('common.to') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( to )) == null ? '' : __t) +
'</dd>\n        ';
 if (reference_period_start) { ;
__p += '\n            <dt>' +
((__t = ( i18next.t('markers.reference_period_start') )) == null ? '' : __t) +
'</dt>\n            <dd>' +
((__t = ( reference_period_start )) == null ? '' : __t) +
'</dd>\n            <dt>' +
((__t = ( i18next.t('common.results') )) == null ? '' : __t) +
'</dt>\n            <dd>' +
((__t = ( results )) == null ? '' : __t) +
'</dd>\n        ';
 } ;
__p += '\n    ';
 } ;
__p += '\n    <dt>' +
((__t = ( i18next.t('glossary.created_at') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( creator )) == null ? '' : __t) +
', ' +
((__t = ( created_at )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.last_change') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( updater )) == null ? '' : __t) +
', ' +
((__t = ( updated_at )) == null ? '' : __t) +
'</dd>\n</dl>\n';

}
return __p
}