WiseMetering.Views.Ui.Widget.NumberDisplay = Backbone.View.extend({
    templateID: 'templates/ui/widgets/number_display',

    initialize: function(params) {
        this.data = params.data;
        $.extend(this, params);
        this.id = generateUUID();
    },

    build: function() {
    },

    close: function() {
        this.$el.remove();
    },

    redraw: function() {
        if (this.draw && this.response) {
            this.$('#' + this.id).empty();
            this.draw = false;
        }
    },

    render: function() {
        this.$el = $(JST[this.templateID]({
            bgColor: this.options.bgColor,
            icon: this.options.icon,
            legend: this.options.legend,
            mainColor: this.options.mainColor,
            percentageBar: this.options.percentageBar,
            smallIcon: this.options.smallIcon,
            subtitle: this.options.subtitle,
            title: this.options.title,
            value: this.options.value
        }));
        $(this.el).append(this.$el);
    }
});
