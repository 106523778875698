module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class="stepped zero">\n        <dd style="text-align: left;">\n            ';
 utility_kinds.forEach(function(utility_kind) { ;
__p += '\n                <label for="zone_' +
((__t = ( utility_kind.get('slug') )) == null ? '' : __t) +
'_tariff_id">' +
((__t = ( utility_kind.get('name') )) == null ? '' : __t) +
'</label>\n                <select class="text" id="zone_' +
((__t = ( utility_kind.get('slug') )) == null ? '' : __t) +
'_tariff_id" name="zone[' +
((__t = ( utility_kind.get('slug') )) == null ? '' : __t) +
'_tariff_id]">\n                    <option value=""></option>\n                </select>\n            ';
 }) ;
__p += '\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}