WiseMetering.Views.NewElectricityMapsIntegration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration',
    template: 'templates/integrations/electricity_maps/new',
    events: {
        'change #integration_emission_factor_type': 'updateDescription'
    },
    ui: {
        indicators: '#integration_indicator_id'
    },
    title: () => i18next.t('modal.title.integrations.new'),

    afterSave: function() {
        WiseMetering.indicators.fetch();
    },

    getFormData: function() {
        const data = this.formSerializer();
        data.partner_id = this.options.license.get('partner_id');
        return data;
    },

    onShow: function() {
        this.updateDescription();
    },

    serializeData: function() {
        const indicators = WiseMetering.indicators.filter(function(indicator) {
            if (!indicator.get('building_id')) {
                return false;
            }

            const
                slug = indicator.get('kind_slug'),
                timezone = indicator.building()?.get('timezone');

            return slug === 'active_energy' && timezone === 'Europe/Lisbon';
        });

        return {
            indicators: _.groupBy(indicators, indicator => indicator.get('building_name'))
        };
    },

    updateDescription: function() {
        const
            selectedType = this.$('#integration_emission_factor_type').val(),
            description = i18next.t(`integration_partners.partners.electricity_maps.${selectedType}_description`),
            title = i18next.t(`integration_partners.partners.electricity_maps.${selectedType}_title`),
            warningContainer = this.$('#emission-factor-container'),
            warningElement = this.$('#emission-factor-warning');

        if (selectedType === 'direct' && warningElement.length === 0) {
            const warningText = i18next.t(`integration_partners.partners.electricity_maps.warning`);

            warningContainer.prepend(`<p id="emission-factor-warning" class="modal-description-warning">${warningText}</p>`);
        } else {
            warningElement.remove();
        }

        this.$('#emission-factor-title').text(title);
        this.$('#emission-factor-description').text(description);
    }
});
