WiseMetering.Views.NewShopperTrakConfiguration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration_configuration',
    template: 'templates/integration_configurations/shopper_trak/new',
    title: () => i18next.t('modal.title.integration_configurations.new'),
    ui: {
        buildings: '#integration_configuration_building_id'
    },

    onShow: function() {
        const buildings = WiseMetering.utils.optionsFromModels(WiseMetering.zones.buildings().models, 'name');

        this.ui.buildings.html(buildings);
    }
});
