module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'tenant-name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'tenant-name\' name=\'tenant[name]\' type=\'text\'>\n        </dd>\n\n        <dt>\n            <label for=\'tenant-company\'>' +
((__t = ( i18next.t('glossary.company') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'companies text\' id=\'tenant-company\' name=\'tenant[company_id]\'>\n                <option value=\'\' selected=\'selected\' disabled>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                ';
 WiseMetering.companies.each(function(company){ ;
__p += '\n                    <option value=\'' +
((__t = ( company.id )) == null ? '' : __t) +
'\'>' +
((__t = ( company.get('name') )) == null ? '' : __t) +
'</option>\n                ';
 }) ;
__p += '\n            </select>\n        </dd>\n\n        <dt>\n            <label for=\'tenant-area\'>' +
((__t = ( i18next.t('common.area') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'tenant-area\' name=\'tenant[area]\' type=\'text\'>\n        </dd>\n\n        <dt>\n            <label for=\'tenant-day\'>' +
((__t = ( i18next.t('tenants.invoicing_day_of_month') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'tenant-day\' name=\'tenant[day]\' type=\'number\'>\n        </dd>\n\n        <dt>\n            <label for=\'tenant-interval\'>' +
((__t = ( i18next.t('tenants.invoicing_interval') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select id=\'tenant-interval\' name=\'tenant[interval]\' class=\'intervals text\'>\n                <option value=\'\' selected=\'selected\' disabled>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                ';
 WiseMetering.tenantIntervals.forEach(function (tenantInterval) { ;
__p += '\n                    <option value=\'' +
((__t = ( tenantInterval )) == null ? '' : __t) +
'\'>' +
((__t = ( tenantInterval )) == null ? '' : __t) +
'</option>\n                ';
 }) ;
__p += '\n            </select>\n        </dd>\n\n        <dt>\n            <label for=\'tenant-invoicing-notes\'>' +
((__t = ( i18next.t('tenants.invoicing_notes') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <textarea class=\'text\' id=\'tenant-invoicing-notes\' name=\'tenant[invoicing_notes]\'></textarea>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}