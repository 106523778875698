import { Button, Content, ContentHeader, Form, FormInput, FormLabel } from '@/components/wm';
import * as React from 'react';
import { useMemo, useState } from 'react';

export function Profile() {
    const currentUser = WiseMetering.currentUser;

    const
            [name, setName] = useState(currentUser.get('name')),
            [loading, setLoading] = useState(false),
            [email, setEmail] = useState(currentUser.get('email'));

    const hasChanges = useMemo(() => {
        return name !== currentUser.get('name') || email !== currentUser.get('email');
    }, [name, email, currentUser.get('name'), currentUser.get('email')]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const changed = currentUser.changedAttributes({ name, email });

        if (!changed) {
            setTimeout(() => {
                setLoading(false);
            }, 500);
            return;
        }

        try {
            await currentUser.save(changed, { wait: true, patch: true });
            WiseMetering.layout.showTipper('success', i18next.t('ui.successfully_saved'));
        } catch (err) {
            const errors = err.responseJSON.errors || null;
            let errorMessage = '';

            if (errors) {
                Object.keys(errors).forEach((key) => {
                    errorMessage += `${key}: ${errors[key]} <br>`;
                });
            } else {
                errorMessage = 'An error ocurred';
            }


            WiseMetering.layout.showTipper('error', errorMessage);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    return (
            <Content>
                <ContentHeader icon="user" title={i18next.t('modal.title.users.show').capitalize()}/>
                <Form>
                    <FormLabel htmlFor="name">{i18next.t('common.name')}</FormLabel>
                    <FormInput
                            id="name"
                            placeholder="Test Name"
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                    />
                    <FormLabel htmlFor="email">{i18next.t('common.email')}</FormLabel>
                    <FormInput
                            id="email"
                            type="email"
                            placeholder="m@example.com"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className="tw-mt-6">
                        <Button type="submit" loading={loading} className="tw-w-fit" onClick={handleSubmit} disabled={!hasChanges}>
                            {i18next.t('ui.buttons.save')}
                        </Button>
                    </div>
                </Form>
            </Content>
    );
}
