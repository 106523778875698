WiseMetering.Model.SitePackage = WiseMetering.Model.extend({
    urlRoot: '/building_packages',

    getBuilding: function() {
        return WiseMetering.zones.get(this.get('building_id'));
    },

    getCurrentSensorsDeviation: function() {
        const total = this.getPackage().defaultCurrentSensors();
        return this.get('current_sensors_used') / total || 0;
    },

    getDevicesDeviation: function() {
        const total = this.getPackage().get('devices') + this.get('devices_extra_limit');
        return this.get('devices_used') / total || 0;
    },

    getControlOutputsDeviation: function() {
        const total = this.getPackage().defaultOutputs();
        return this.get('control_outputs_used') / total || 0;
    },

    getPackage: function() {
        return WiseMetering.packages.get(this.get('package_id'));
    },

    getUsed: function(key) {
        const deviation = this[`get${key.split('_').map(x => x.capitalize()).join('')}Deviation`]();

        return {
            value: this.get(`${key}_used`),
            deviation: deviation * 100,
            color: deviation === 1 ? WiseMetering.SemaphorColors.red : WiseMetering.SemaphorColors.green
        };
    },

    getWiseboxesDeviation: function() {
        const total = this.getPackage().get('wiseboxes') + this.get('wiseboxes_extra_limit');
        return this.get('wiseboxes_used') / total || 0;
    }
});
