import * as React from 'react';

const ContentArea = ({ children }) => {
    return (
            <div className="tw-basis-3/4 tw-min-w-full">
                {children}
            </div>
    );
};

export { ContentArea };
