module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        ';
 if (hasSpecialFeatures) { ;
__p += '\n            <dt>\n                <label for=\'organization_automation\'>' +
((__t = ( i18next.t('glossary.automation') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <input ' +
((__t = ( automation ? 'checked' : '' )) == null ? '' : __t) +
' class=\'vertical-middle\' id=\'organization_automation\' name=\'organization[automation]\' type=\'checkbox\'>\n            </dd>\n        ';
 } ;
__p += '\n        <dt>\n            <label for=\'organization_password_valid_within\'>' +
((__t = ( i18next.t('organizations.password_valid_within') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'organization_password_valid_within\' name=\'organization[password_valid_within]\'>\n                <option disabled>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                ';
 _.each([[90, '90'], [180, '180'], [365, '365'], [2147483647, i18next.t('organizations.never_expire')]], function(valid_duration){ ;
__p += '\n                    <option ' +
((__t = ( password_valid_within === valid_duration[0] ? 'selected ' : null )) == null ? '' : __t) +
'value=\'' +
((__t = ( valid_duration[0] )) == null ? '' : __t) +
'\'>' +
((__t = ( valid_duration[1] )) == null ? '' : __t) +
'</option>\n                ';
 }) ;
__p += '\n            </select>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}