import { Box, Grid } from '@mui/material';
import * as React from 'react';
import useFetch from 'react-fetch-hook';
import Alarms from './Alarms';
import Buildings from './Buildings';
import Circuits from './Circuits';
import Contracts from './Contracts';
import Devices from './Devices';
import Indicators from './Indicators';
import Organization from './Organization';
import Reports from './Reports';
import Sidebar from './Sidebar';
import Tariffs from './Tariffs';
import Users from './Users';

const Content = () => {
    const { data, isLoading } = useFetch(
        `${WiseMetering.apiUrl}/organizations/${WiseMetering.currentOrganization.id}/summary`,
        { credentials: 'include' }
    );

    if (isLoading) {
        return (<Box sx={{ height: 1, textAlign: 'center', width: 1 }}>Loading...</Box>);
    }

    return (
        <Grid container sx={{ height: '100%' }}>
            <Grid item sx={{ backgroundColor: 'grey.300' }} xs={2}>
                <Sidebar/>
            </Grid>
            <Grid item sx={{ backgroundColor: 'grey.200' }} xs={10}>
                <Grid container spacing={2} sx={{ p: 2 }}>
                    <Grid item xs={3}>
                        <Alarms data={data.alarms}/>
                    </Grid>
                    <Grid item xs={3}>
                        <Buildings data={data.buildings}/>
                    </Grid>
                    <Grid item xs={3}>
                        <Circuits data={data.circuits}/>
                    </Grid>
                    <Grid item xs={3}>
                        <Devices data={data.devices}/>
                    </Grid>
                    <Grid item xs={3}>
                        <Indicators data={data.indicators}/>
                    </Grid>
                    <Grid item xs={3}>
                        <Users data={data.users}/>
                    </Grid>
                    <Grid item xs={3}>
                        <Tariffs data={data.tariffs}/>
                    </Grid>
                    <Grid item xs={3}>
                        <Contracts data={data.contracts}/>
                    </Grid>
                    <Grid item xs={3}>
                        <Reports data={data.reports}/>
                    </Grid>
                    <Grid item xs={3}>
                        <Organization data={data.organization}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Content;
