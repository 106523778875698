import { LoginCard } from '@/components/layout/login-card';
import { Button, Form, FormInput, FormLabel, StandaloneLink } from '@/components/wm';
import { apiRequest } from '@/utils/request';
import * as React from 'react';
import { useEffect, useState } from 'react';

export function ForgotPassword({ loginEmail, onAlert, backToLogin }) {
    const
            [email, setEmail] = useState(loginEmail),
            [formFilled, setFormFilled] = useState(false),
            [loading, setLoading] = useState(false);

    useEffect(() => {
        setFormFilled(email != '');
    }, [email]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const data = {
            email
        };

        try {
            const response = await apiRequest('/reset_password', 'POST', data);

            if (response.status === 201) {
                onAlert('If the provided email is associated with a WiseMetering account, you will receive an email with instructions to reset your password.', 'success', 'Success');
            }
        } catch (err) {
            const errorMessage = err.response?.data?.error?.capitalize() || 'An error occurred';

            onAlert(errorMessage, 'destructive', 'Failed');
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    return (
            <LoginCard>
                <Form>
                    <FormLabel htmlFor="email">enter your email to reset your password</FormLabel>
                    <FormInput
                            id="email"
                            type="email"
                            placeholder="m@example.com"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button type="submit" loading={loading} className="tw-w-full" onClick={handleSubmit} variant="secondary" disabled={!formFilled}>
                        Reset my password
                    </Button>
                </Form>
                <StandaloneLink onAction={backToLogin} icon="arrowLeftCircle" text="Back to Login"/>
            </LoginCard>
    );
}
