WiseMetering.Views.Ui.Widget.Scatter = Backbone.View.extend({
    templateID: 'templates/ui/charts/scatter',

    initialize: function(params) {
        $.extend(this, params);
        this.data = params.data;
        this.regressionLineData = params.regressionLineData;
        this.minX = this.getMinX();
    },

    render: function() {
        this.$el = $(JST[this.templateID]({ title: this.options.title }));
        $(this.el).append(this.$el);
        this.drawChart();
    },

    drawChart: function() {
        this.chart = new Highcharts.Chart({
            chart: {
                renderTo: 'chart'
            },
            title: { text: null },
            subtitle: { text: null },
            legend: { enabled: false },
            credits: { enabled: false },
            xAxis: {
                min: this.minX > 0 ? 0 : null,
                title: {
                    style: {
                        color: WiseMetering.baseColors.dark_green,
                        fontWeight: 'bold'
                    },
                    text: this.options.units.xAxis
                }
            },
            yAxis: {
                title: {
                    style: {
                        color: WiseMetering.baseColors.dark_green,
                        fontWeight: 'bold'
                    },
                    text: this.options.units.yAxis
                }
            },
            series: [
                {
                    type: 'scatter',
                    zoomType: 'xy',
                    data: this.data,
                    marker: {
                        symbol: 'circle'
                    },
                    color: WiseMetering.baseColors.green
                },
                {
                    name: 'linear regression',
                    data: this.regressionLineData,
                    marker: {
                        symbol: 'circle',
                        radius: 0
                    },
                    color: WiseMetering.baseColors.dark_green
                }
            ],
            exporting: { enabled: false }
        });

        this.chart.renderer.label(this.regressionLineFormula, 80, 35).add().css({
            color: WiseMetering.baseColors.dark_green,
            fontWeight: 600,
            fontStyle: 'italic'
        });
    },

    getMinX: function() {
        const values = this.data.map(el => el.at(0));
        return Math.min(...values);
    }
});
