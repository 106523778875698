import { Button as ShadcnButton } from '@/components/ui/button'; // Import the base Button component
import { cn } from '@/utils';
import { cva } from 'class-variance-authority';
import * as React from 'react';

const loaderVariants = cva(
        'tw-w-4 tw-h-4 tw-border-4 tw-border-t-transparent tw-rounded-full tw-animate-spin tw-absolute',
        {
            variants: {
                variant: {
                    default: 'tw-border-secondary',
                    destructive: 'tw-border-destructive',
                    ghost: 'tw-border-primary',
                    info: 'tw-border-primary',
                    link: 'tw-border-primary',
                    outline: 'tw-border-primary',
                    secondary: 'tw-border-primary',
                    secondaryOutline: 'tw-border-primary'
                }
            },
            defaultVariants: {
                variant: 'default'
            }
        }
);

const Button = React.forwardRef(
        ({ children, className, disabled, loading, variant, ...props }, ref) => {
            const [buttonWidth, setButtonWidth] = React.useState(null);

            const buttonRef = React.useRef(null); // Ref for the button element

            // Measure the width of the button when it is mounted or when loading changes
            React.useEffect(() => {
                if (buttonRef.current && !loading) {
                    setButtonWidth(buttonRef.current.offsetWidth); // Get the width of the button before it starts loading
                }
            }, [loading]);

            return (
                    <ShadcnButton
                            className={cn('tw-relative tw-flex tw-items-center', className)}
                            disabled={loading || disabled} // Disable the button when loading
                            ref={buttonRef}
                            style={loading && buttonWidth ? { width: `${buttonWidth}px` } : {}}
                            variant={variant}
                            {...props}
                    >
                        {loading ? (
                                <svg
                                        className={cn(loaderVariants({ variant }))}
                                        viewBox="0 0 24 24"
                                />
                        ) : (
                                children
                        )}
                    </ShadcnButton>
            );
        }
);

Button.displayName = 'Button';

export { Button };
