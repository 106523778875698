import CalHeatMap from 'cal-heatmap';

// Override HeatMap getSubDomainTitle function
CalHeatMap.prototype.getSubDomainTitle = function(d) {
    if (d.v === null && !this.options.considerMissingDataAsZero) {
        return (this.options.subDomainTitleFormat.empty).format({
            date: this.formatDate(new Date(d.t), this.options.subDomainDateFormat)
        });
    } else {
        // don't use let here or the build will throw an error
        var value = d.v;

        // Consider null as 0
        if (value === null && this.options.considerMissingDataAsZero) {
            value = 0;
        }

        if (typeof this.options.subDomainTitleFormat.filled === 'function')
            return this.options.subDomainTitleFormat.filled(d);
        else
            return (this.options.subDomainTitleFormat.filled).format({
                count: this.formatNumber(value),
                name: this.options.itemName[(value !== 1 ? 1 : 0)],
                connector: this._domainType[this.options.subDomain].format.connector,
                date: this.formatDate(new Date(d.t), this.options.subDomainDateFormat)
            });
    }
};

Lmit.Wise.Chart.Calendar = function(_controller, _container, _settings, _options) {
    var _this_ = this;
    this.init(_controller, _container, _settings, _options);
    if (_options.observers) {
        _.each(_options.observers, function(_observer) {
            _this_.register(_observer);
        });
    }
};

Lmit.Wise.Chart.Calendar.prototype = new Lmit.Wise.Chart.Generic();

// build override for coherent chart display
Lmit.Wise.Chart.Calendar.prototype.build = function() {
    $('.wmui-chart-ph').empty();
    this.view = $('<div>').addClass('ui_hc ui_hc_calendar');
    this.view.attr({ id: this.id });
    this.view.css('margin-left', '20px');
    $(this.container).prepend(this.view);
};

Lmit.Wise.Chart.Calendar.prototype.type_of = 'calendar';
Lmit.Wise.Chart.Calendar.prototype.name = 'calendar data';

Lmit.Wise.Chart.Calendar.prototype.destroy = function() {
    var a = $(this.view).find('.chart.calendar');
    if (this.chart || a.length > 0) {
        var thiz = this;
        $(_.first($(a))).css({ display: 'block', 'opacity': 1 }).fadeOut(400, function() {
            $(thiz.view).remove();
            thiz.chart = null;
        }).delay(400);
    }
};

Lmit.Wise.Chart.Calendar.prototype.show = function() {
};

Lmit.Wise.Chart.Calendar.prototype.addSeries = function(data) {
    var that = this;
    var analyticsOperation = new Analytics.Operation({
        operation: this.controller.indicatorController.operation,
        from: this.controller.periodController.period.from.getTime(),
        to: this.controller.periodController.period.to.getTime(),
        data: { data: data }
    });
    that.plotData(analyticsOperation.data.data);
    that.addResumeInfo({
        avg: analyticsOperation.average(),
        color: Lmit.Wise.Chart.Defaults.defaultPlotColors[0],
        id: generateUUID(),
        index: 0,
        max: analyticsOperation.max(),
        min: analyticsOperation.min(),
        sum: analyticsOperation.sum(),
        unit: that.controller.indicatorController.data.unit,
        value: 1234,
        code: data.info.code
    });
};

Lmit.Wise.Chart.Calendar.prototype.addResumeInfo = function(args) {
    this.controller.resumeController.add([args]);
};

Lmit.Wise.Chart.Calendar.prototype.plotData = function(response) {

    const rearrangeTooltip = () => {
        $('.ui_hc_calendar g rect').hover(el => {
            let tooltip = document.querySelector('.ch-tooltip');
            const display = window.getComputedStyle(tooltip).getPropertyValue('display');

            if (display === 'block') {
                const { top, left } = tooltip.getBoundingClientRect();
                $(tooltip).css({ 'position': 'fixed', 'top': top, 'left': left });
            }
        });
    };

    if (response.rate === 86400) {
        var heatMapData = {};
        _.each(response.data, function(array) {
            heatMapData[array[0]] = array[1];
        });

        var values = _.compact(_.values(heatMapData));
        var min = _.min(values);
        var max = _.max(values);

        var legend = [];
        for (var i = 0; i < 5; i++) legend.push(min + (max - min) * i / 5);

        this.chart = new CalHeatMap();
        this.chart.init({
            considerMissingDataAsZero: true,
            itemSelector: '.ui_hc_calendar',
            animationDuration: 0,
            domain: 'month',
            range: (response.to - response.from) / (60 * 60 * 24 * 30) + 1,
            subdomain: 'day',
            tooltip: true,
            subDomainTitleFormat: {
                filled: function(d) {
                    return (d.v ? formatValue(d.v, response.info.unit) : i18next.t('ui.no_data')) + ' @ ' + moment(d.t).format('ddd, DD/MM/YY');
                }
            },
            rowLimit: 7,
            legend: legend,
            displayLegend: false,
            domainGutter: 0,
            cellSize: 15,
            domainLabelFormat: '%b-%Y',
            start: new Date(response.from * 1000),
            data: heatMapData
        });

        var html = JST['templates/ui/components/heatmap_legend']({
            min: formatValue(min, response.info.unit),
            max: formatValue(max, response.info.unit)
        });
        this.view.append(html);
        rearrangeTooltip();

    } else if (response.rate === 3600) {

        var heatMapData = {};
        _.each(response.data, function(value, index) {
            heatMapData[response.from + 3600 * index] = value;
        });

        var values = _.compact(_.values(heatMapData));
        var min = _.min(values);
        var max = _.max(values);

        var legend = [];
        for (var i = 0; i < 5; i++) legend.push(min + (max - min) * i / 5);

        this.chart = new CalHeatMap();
        this.chart.init({
            considerMissingDataAsZero: true,
            itemSelector: '.ui_hc_calendar',
            animationDuration: 0,
            domain: 'day',
            range: (response.to - response.from) / (60 * 60 * 24) + 1,
            subdomain: 'hour',
            rowLimit: 24,
            tooltip: true,
            subDomainTitleFormat: {
                filled: function(d) {
                    return (d.v ? formatValue(d.v, response.info.unit) : i18next.t('ui.no_data')) + ' @ ' + moment(d.t).format('HH:mm DD/MM/YY');
                }
            },
            legend: legend,
            displayLegend: false,
            domainGutter: 0,
            cellSize: 13,
            start: new Date(response.from * 1000),
            domainLabelFormat: function(date) {
                return date;
            },
            data: heatMapData
        });

        const html = JST['templates/ui/components/heatmap_legend']({
            min: formatValue(min, response.info.unit),
            max: formatValue(max, response.info.unit)
        });
        this.view.append(html);
        rearrangeTooltip();

    } else {
        this.view.html('<div style=\'text-align: center; font-size: 15px; color: #999;\'>Unable to display carpet for this combination of period and resolution.</div>');
    }

};
