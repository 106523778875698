WiseMetering.Views.IndicatorAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/indicators/accordion',

    onRender: function() {
        const collection = this.model.indicators();
        let type = `${this.model.type()}_id`;
        if (this.model.type() === 'building') {
            type = 'zone_id';
        }

        this.table = new WiseMetering.Views.Table({
            accordion: true,
            id: 'indicators',
            collection: collection,
            modelSerializer: function(model) {
                const json = model.toJSON();
                json.kind = model.kind().get('name');
                return json;
            },
            columns: {
                name: i18next.t('common.name'),
                type: i18next.t('common.type'),
                kind: i18next.t('glossary.indicator_kind')
            },
            toolbar_actions: {
                manage: {
                    view_call: () => new WiseMetering.Views.IndicatorManage({ [type]: this.model.id })
                },
                show: {
                    view_call: model => Backbone.history.navigate(model.nodeUrl(), { trigger: true })
                }
            },
            disableSelect: true
        });

        this.$('#indicators').html(this.table.el);

        if (type === 'folder_id') {
            this.listenTo(this.model, 'change:indicator_ids', function() {
                this.render();
            });
        } else {
            this.listenTo(this.model, 'change:children:removeIndicator', function(node) {
                collection.remove(node);
            });
            this.listenTo(this.model, 'change:children:addIndicator', function(node) {
                collection.add(node);
            });
        }
    },

    onClose: function() {
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    }
});
