const { Model } = require('backbone');

WiseMetering.Model.Opportunity = WiseMetering.Model.extend({
    states: {
        pending: {
            color: WiseMetering.SemaphorColors['blue'],
            title: 'Pending'
        },
        ongoing: {
            color: WiseMetering.SemaphorColors['orange'],
            title: 'Ongoing'
        },
        completed: {
            color: WiseMetering.SemaphorColors['green'],
            title: 'Completed'
        },
        discarded: {
            color: WiseMetering.SemaphorColors['red'],
            title: 'Discarded'
        }
    },

    urlRoot: '/opportunities',

    building: function() {
        return WiseMetering.zones.get(this.get('building_id'));
    },

    changeState: function(state) {
        const
            action = WiseMetering.workflowActions[state],
            url = `${this.urlRoot}/${this.id}/${action}`;

        return this.save(null, {
            url: url,
            success: function() {
                WiseMetering.layout.showTipper('success', 'State successfully changed');
            },
            error: function() {
                WiseMetering.layout.showTipper('error', 'State unable to change');
            }
        });
    },

    fileLink: function() {
        return this.get('file') ? WiseMetering.apiUrl + this.url() + '/file' : null;
    },

    indicator: function() {
        return WiseMetering.indicators.get(this.get('indicator_id'));
    },

    indicators: function() {
        const kind = this.utilityKind().indicatorKindSlug(),
            models = WiseMetering.indicators.where({ building_id: this.get('building_id'), kind_slug: kind }),
            indicators = new WiseMetering.Collection.Indicators;

        return indicators.add(models);
    },

    payback: function() {
        if (!this.get('payback')) {
            return '-';
        }
        const payback = this.get('payback') >= 1 ? this.get('payback') : this.get('payback') * 12,
            unit = this.get('payback') >= 1 ? 'Y' : 'Mo';

        return `${payback.toFixed(2)} ${unit}`;
    },

    removeFile: function() {
        return this.save({}, {
            url: this.url() + '/remove_file',
            wait: true,
            patch: true
        });
    },

    timezone: function() {
        return this.building().get('timezone');
    },

    uploadFile: function(formData) {
        return this.save({}, {
            url: this.url(),
            contentType: false,
            data: formData,
            processData: false
        });
    },

    utilityKind: function() {
        return WiseMetering.utilityKinds.get(this.get('utility_kind_id'));
    }
});
