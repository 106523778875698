import Admin from '@/pages/Admin';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

WiseMetering.Views.Admin = Backbone.Marionette.View.extend({
    id: 'admin-container',

    onShow: function() {
        const root = createRoot(document.getElementById(this.id));
        root.render(<Admin/>);
    }
});
