WiseMetering.Views.Modal.Destroy = Backbone.Marionette.ItemView.extend({
    buttons: ['yes', 'no'],
    template: 'templates/ui/table/remove',

    initialize: function(attributes) {
        $.extend(this, attributes);
        this.modal = new WiseMetering.Views.Modal.Base({
            afterDestroy: this.afterDestroy,
            buttons: this.buttons,
            collection: this.collection,
            data: this.data,
            form_prefix: this.form_prefix,
            from: this.from,
            from_id: this.from_id,
            group: this.group,
            ids: this.ids,
            indicator_id: this.indicator_id,
            model: this.model,
            parent: this.parent,
            saveIt: this.saveIt,
            target: this.target,
            timestamps: this.timestamps,
            title: this.title,
            reset: this.reset,
            type: this.type,
            user: this.user,
            width: this.width,
            yes: this.yes
        });
        WiseMetering.modalBox.show(this.modal);
        this.modal.body.show(this);
    },

    serializeData: function() {
        return { name: this.model?.get('name') };
    },

    yes: function() {
        const that = this;
        that.block();
        const options = {
            wait: true,
            success: function(model) {
                let complete = function() {
                    let text = i18next.t('modal.destroy.success');
                    try {
                        if (model.get('name')) text += ' ' + model.get('name');
                    } catch (e) {
                    }
                    WiseMetering.layout.showTipper('success', text);
                    that.unblock();
                    that.close();
                };
                that.afterDestroy && typeof (that.afterDestroy) === 'function' ? $.when(that.afterDestroy()).done(function() {
                    complete();
                }) : complete();
            },
            error: function(model, response) {
                let error = i18next.t('modal.destroy.error', { email: model.get('email') });
                try {
                    error = JSON.parse(response.responseText).error;
                } catch (e) {
                }
                WiseMetering.layout.showTipper('error', error);
                that.unblock();
                that.close();
            }
        };
        if (this.data && typeof this.data === 'function') {
            options.data = this.data();
            options.processData = true;
        }
        that.model.destroy(options);
    }
});
