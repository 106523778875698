WiseMetering.Views.SeuDetailsTab = Backbone.Marionette.Layout.extend({
    className: 'dashboard full-height',
    template: 'templates/seus/details_tab',

    regions: {
        details: '#details-accordion',
        analysis: '#analysis-accordion',
        system: '#system-info-accordion'
    },

    onRender: function() {
        this.details.show(new WiseMetering.Views.DetailsAccordion({ model: this.model }));
        this.analysis.show(new WiseMetering.Views.AnalysisAccordion({ model: this.model }));
        this.system.show(new WiseMetering.Views.SystemInfoAccordion({ model: this.model }));
    }
});
