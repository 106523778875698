WiseMetering.Views.Ui.WidgetOptions = Backbone.Marionette.ItemView.extend({
    templateID: 'templates/ui/widgets/widget_options',
    form_prefix: 'user_preferences',

    events: {
        'click #cancel': 'cancel',
        'click #save': 'save'
    },

    render: function() {
        this.$el = $(JST[this.templateID]({
            preferences: this.options.preferences,
            title: this.options.title
        }));
        $(this.el).append(this.$el);

        setTimeout(() => {
            $('.widget-options-wrapper').addClass('active');
        }, 1);
    },

    cancel: function() {
        this.trigger('cancel:preferences');
    },

    formSerializer: function(excludeParams = []) {
        if (this.input_readers) {
            this.input_readers.forEach(ir => Backbone.Syphon.InputReaders.register(ir.type, ir.func));
        }
        return Backbone.Syphon.serialize(this, { exclude: excludeParams })[this.form_prefix];
    },

    getFormData: function() {
        return this.formSerializer();
    },

    save: function() {
        const preferences = this.options.preferences;

        let data = this.getFormData();

        Object.keys(data).forEach(option => preferences[option] = data[option]);
        this.trigger('save:preferences', preferences);
    }
});
