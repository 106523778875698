import percentage from '@/utils/percentage';
import { Card, CardActions, CardContent, Typography } from '@mui/material';
import * as React from 'react';

const Component = props => {
    const { active, event_count, total, with_notification } = props.data;

    return (
        <Card>
            <CardContent sx={{ height: 260 }}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Alarms
                </Typography>
                <Typography variant="h5" component="div">
                    Total: {total}
                </Typography>
                <br/>
                <Typography variant="body2">
                    Active: {active} ({percentage(active, total)})
                    <br/>
                    With Notification: {with_notification} ({percentage(with_notification, total)})
                </Typography>
                <br/>
                <Typography sx={{ mb: 0.5 }} color="text.secondary">
                    Event Count: {event_count}
                </Typography>
            </CardContent>
            <CardActions sx={{ height: 20 }}>
            </CardActions>
        </Card>
    );
};

export default Component;
