module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'integration_cooling_base_temperature\'>' +
((__t = ( i18next.t('glossary.cooling_base_temperature') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_cooling_base_temperature\' min=\'0\' name=\'integration[cooling_base_temperature]\' type=\'number\' value="' +
((__t = ( cooling_base_temperature )) == null ? '' : __t) +
'">\n        </dd>\n        <dt>\n            <label for=\'integration_heating_base_temperature\'>' +
((__t = ( i18next.t('glossary.heating_base_temperature') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_heating_base_temperature\' min=\'0\' name=\'integration[heating_base_temperature]\' type=\'number\' value="' +
((__t = ( heating_base_temperature )) == null ? '' : __t) +
'">\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}