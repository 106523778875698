import UserProfile from '@/pages/UserProfile';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

WiseMetering.Views.UserProfile = Backbone.Marionette.View.extend({
    id: 'react-container',
    className: 'tailwind-container',

    onShow: function() {
        this.reactRoot = createRoot(document.getElementById(this.id));
        this.reactRoot.render(<UserProfile/>);
    },

    onClose: function() {
        if (this.reactRoot) {
            this.reactRoot.unmount(); // Unmount the React component
            this.reactRoot = null; // Clean up reference
        }
    }
});
