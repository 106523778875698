WiseMetering.Views.EditTenant = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/tenants/edit',
    title: () => i18next.t('modal.title.tenants.edit'),
    form_prefix: 'tenant',
    className: 'mbx-info text-left',
    input_readers: [{
        type: 'checkbox', func: function(el) {
            if (el.prop('checked')) return el.val();
        }
    }],

    serializeData: function() {
        let json = this.model.toJSON();
        json.form_prefix = this.form_prefix;
        return json;
    }
});
