WiseMetering.Views.TableIndex = WiseMetering.Views.Content.extend({
    onShow: function() {
        this.table = this.createTable();
        $(this.body.el).html(this.table.el);
    },

    onClose: function() {
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    }
});
