WiseMetering.Collection.Tenants = WiseMetering.Collection.extend({
    hasOrganization: true,
    model: WiseMetering.Model.Tenant,
    url: '/tenants',
    comparator: 'name',

    active: function() {
        let activeTenants = [];

        this.forEach(tenant => {
            if (WiseMetering.allocations.where({ tenant_id: tenant.id, active: true }).length > 0) {
                activeTenants.push(tenant);
            }
        });

        return new Backbone.Collection(activeTenants);
    },

    inactive: function() {
        let inactiveTenants = [];

        this.forEach(tenant => {
            if (WiseMetering.allocations.where({ tenant_id: tenant.id, active: true }).length === 0) {
                inactiveTenants.push(tenant);
            }
        });

        return new Backbone.Collection(inactiveTenants);
    }
});
