WiseMetering.Model.Folder = WiseMetering.Model.extend({
    defaults: {
        name: null,
        description: null
    },

    urlRoot: '/folders',

    type: function() {
        return 'folder';
    },

    icon: function() {
        if (!this.get('parent_id')) {
            return 'rootFolder';
        } else if (this.get('built_in')) {
            return 'builtInFolder';
        } else {
            return 'folders';
        }
    },

    nodeUrl: function(skipSearch) {
        return `/folders/${this.get('id')}${skipSearch ? '' : location.search}`;
    },

    children: function() {
        const children = new Backbone.Collection;
        children.add(this.folders().models.reverse());
        children.add(this.indicators().models.reverse());
        return children;
    },

    indicators: function() {
        const indicators = new WiseMetering.Collection.Indicators;

        this.get('indicator_ids').forEach(function(id) {
            const indicator = WiseMetering.indicators.get(id);
            if (indicator) {
                indicators.add(indicator);
            }
        });

        return indicators;
    },

    folders: function() {
        return new Backbone.Collection(WiseMetering.folders.where({ parent_id: this.id }));
    },

    parent: function() {
        const parentId = this.get('parent_id'),
            rootFolder = WiseMetering.rootObjects.findWhere({ building_id: parentId, slug: 'folders' });

        return rootFolder || WiseMetering.zones.get(parentId) || WiseMetering.folders.get(parentId);
    },

    building: function() {
        return this.parent() ? this.parent().building() : null;
    }
});
