WiseMetering.Views.ZoneEdit = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/zones/edit',
    className: 'mbx-info text-left',
    form_prefix: 'zone',
    title: () => i18next.t('modal.title.zones.edit'),

    ui: {
        imagePreviewImage: '.preview-building-image',
        removeFile: '#remove-file'
    },

    events: {
        'change .input-image': 'onChangeImage',
        'click #remove-file': 'onRemoveFile'
    },

    input_readers: [{
        type: 'default',
        func: function(el) {
            return el.val() === '' ? null : el.val();
        }
    }],

    getFormData: function() {
        const data = this.formSerializer();
        if (data.description == null) {
            data.description = '';
        }
        if (this.imageFile) {
            data.image = this.imageFile;
        }
        if (this.removeImage) {
            data.image = '';
        }
        if (this.model.isBuilding()) {
            const tag_ids = [];
            Object.entries(data.tag_ids).forEach(([key, value]) => {
                if (value) {
                    tag_ids.push(key);
                }
            });
            data.tag_ids = tag_ids;
        }

        return data;
    },

    serializeData: function() {
        return Object.assign(
            {
                address: {
                    street: this.defaultAddress
                },
                isBuilding: this.model.isBuilding()
            },
            this.model.toJSON()
        );
    },

    onChangeImage: function(event) {
        const [file] = event.target.files;
        if (file) {
            this.ui.imagePreviewImage.attr('src', URL.createObjectURL(file));
            this.imageFile = file;
        }
    },

    onRemoveFile: function(event) {
        this.ui.removeFile.hide();
        this.ui.imagePreviewImage.attr('src', '');
        this.ui.imagePreviewImage.hide();
        this.removeImage = true;
    },

    onRender: function() {
        const type = this.model.isBuilding() ? 'building' : 'zone';
        this.$('select#zone_category_id').append(
            WiseMetering.utils.optionsFromModels(
                WiseMetering.zoneCategories.where({ [type]: true }),
                'name',
                this.model.get('category_id')
            )
        );
    },

    onShow: function() {
        this.imageFile = null;
    },

    search: function(event) {
        if ((event.keyCode || event.which) === 13) {
            event.stopPropagation();
            event.preventDefault();
            this.codeAddress();
        }
    },

    submitData: function(data) {
        let formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                if (value.length > 0) {
                    value.forEach(item => formData.append(`${key}[]`, item));
                } else {
                    formData.append(`${key}[]`, []);
                }
            } else {
                formData.append(key, value);
            }
        });

        // https://stopbyte.com/t/how-to-send-multipart-formdata-with-jquery-and-ajax/58/2
        return $.ajax({
            contentType: false,
            data: formData,
            processData: false,
            type: 'PUT',
            url: this.model.url(),
            success: function(response) {
                this.model.set(response);
            }.bind(this)
        });
    }
});
