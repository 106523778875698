WiseMetering.Views.NewAlarmSchedule = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/alarm_schedules/new',
    form_prefix: 'alarm_schedule',
    width: 1020,
    MAX_PERIODS: 5,
    ui: {
        schedule: '#schedule'
    },
    events: {
        'click div#add-period': 'addPeriod',
        'keyup input#period-name': 'onPeriodNameKeyUp',
        'focusout input#period-name': 'onFocusOutPeriodName'
    },
    title: () => i18next.t('modal.title.alarm_schedules.edit'),

    addPeriod: function() {
        if (this.$('div#add-period').hasClass('disabled') || this.periodNames.length >= this.MAX_PERIODS) {
            return;
        }

        this.$('div#add-period').addClass('disabled');
        this.$('div#dynamic-periods').append(JST['templates/ui/schedule/legend']({ index: this.periodNames.length + 1 }));
    },

    addLabel: function(target) {
        let value = target.val().trim();
        let parent = target.parent();

        if (!value) {
            return;
        }

        if (this.periodNames.includes(value)) {
            target.css('border-color', 'red');
            return;
        }

        this.periodNames.push(value);

        target.remove();
        $(parent).append(`<span class='color-text'>${value}</span>`);
        $(parent).append(`<input type='hidden' name='alarm_schedule[periods][]' value='${value}'>`);
        $(parent).attr('name', value);
        this.$('div#dynamic-periods').trigger('periodchange');

        if (this.periodNames.length === this.MAX_PERIODS) {
            this.$('div#add-period').replaceWith('Custom periods maximum reached');
        } else {
            this.$('div#add-period').removeClass('disabled');
        }
    },

    onFocusOutPeriodName: function(e) {
        const target = $(e.currentTarget);
        this.addLabel(target);
    },

    onPeriodNameKeyUp: function(e) {
        if ((e.keyCode || e.which) !== 13) {
            return;
        }
        this.addLabel($(e.currentTarget));
    },

    onRender: function() {
        this.periodNames = [];
        const schedule = new WiseMetering.Views.AlarmSchedule({ addValues: true, editable: true });
        this.ui.schedule.append(schedule.el);
        this.$('a#period-note').tooltip();
    },

    save: function() {
        let data = Backbone.Syphon.serialize(this)[this.form_prefix];
        data.organization_id = WiseMetering.currentOrganization.id;
        this.model.save(data, {
            wait: true,
            patch: true,
            success: model => {
                if (this.collection) {
                    this.collection.add(model);
                }
                WiseMetering.layout.showTipper('success', 'Successfully saved ' + (model.get('name') ? model.get('name') : ''));
                this.modal.close();
            },
            error: (model, xhr) => {
                let errors = {};
                try {
                    errors = JSON.parse(xhr.responseText);
                } catch (e) {
                }
                _.each(errors.errors, (message, param) => {
                    let errorLabel = this.$('label[for="' + this.form_prefix + '_' + param + '"]');
                    errorLabel.addClass('err');
                    errorLabel.mouseover(() => $.stt({ target: errorLabel, text: message }));
                    errorLabel.mouseout(() => $('div.stt').remove());
                });
                if (errors.error) $(this.errors.el).addClass('active').html(errors.error);
            }
        });
    }
});
