WiseMetering.Model.Integration = WiseMetering.Model.extend({
    urlRoot: '/integrations',

    building: function() {
        return WiseMetering.zones.get(this.get('building_id'));
    },

    configuration: function() {
        return WiseMetering.integrationConfigurations.get(this.get('configuration_id'));
    },

    indicator: function() {
        return WiseMetering.indicators.get(this.get('indicator_id'));
    },

    indicatorKind: function() {
        return WiseMetering.indicatorKinds.findWhere({ slug: this.get('kind_slug') });
    },

    integrationIndicators: function() {
        return WiseMetering.indicators.where({ integration_id: this.id });
    }
});
