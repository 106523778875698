module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'total-displayer\' style="background-color: ' +
((__t = ( bgColor )) == null ? '' : __t) +
';">\n    ';
 if(title){ ;
__p += '\n        <div class="total-displayer-header">\n            <div class="total-displayer-title">\n                <div class="title-text">' +
((__t = ( title )) == null ? '' : __t) +
'</div>\n                <p>' +
((__t = ( subtitle )) == null ? '' : __t) +
'</p>\n            </div>\n            <div class="total-displayer-small-icon">\n                ' +
((__t = ( WiseMetering.icon(smallIcon) )) == null ? '' : __t) +
'\n            </div>\n        </div>\n    ';
 } ;
__p += '\n    <div class=\'total-displayer-main-wrapper\'>\n        ';
 if(icon){ ;
__p += '\n            <div class=\'total-displayer-icon\' style="color: ' +
((__t = ( bgColor )) == null ? '' : __t) +
'; background-color: ' +
((__t = ( mainColor )) == null ? '' : __t) +
';">\n                ' +
((__t = ( WiseMetering.icon(icon) )) == null ? '' : __t) +
'\n            </div>\n        ';
 } ;
__p += '\n        ';
 if(percentageBar){ ;
__p += '\n            <div class="percentage-wrapper">\n                <div class="totalizer-progress-bar">\n                    <div class="totalizer-progress-fill" style="width: ' +
((__t = ( value )) == null ? '' : __t) +
'%"></div>\n                </div>\n                <div class="progress-info">\n                    <span class="total-value">' +
((__t = ( value )) == null ? '' : __t) +
'%</span>\n                </div>\n            </div>\n            <div class=\'total-subtitle\'>\n                ' +
((__t = ( legend )) == null ? '' : __t) +
'\n            </div>\n        ';
 } else { ;
__p += '\n            <div class=\'text-wrapper\'>\n                <div class=\'total-value\'>\n                    ' +
((__t = ( value )) == null ? '' : __t) +
'\n                </div>\n                <div class=\'total-subtitle\'>\n                    ' +
((__t = ( legend )) == null ? '' : __t) +
'\n                </div>\n            </div>\n        ';
 } ;
__p += '\n    </div>\n</div>\n';

}
return __p
}