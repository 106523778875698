WiseMetering.Views.OrganizationSummaryInfo = WiseMetering.Views.CardsView.extend({
    cards: function() {
        this.setVariables();

        const widgets = [];
        widgets.push(this.activeSitesObj());
        widgets.push(this.wiseBoxesObj());
        widgets.push(this.meteringDevicesObj());
        if (this.model.showControlOutputs()) {
            widgets.push(this.controlOutputsObj());
        }
        widgets.push(this.apiRequestsYtdObj());
        widgets.push(this.apiRequestsMtdObj());
        return widgets;
    },

    activeSitesObj: function() {
        return {
            label: i18next.t('organizations.active_sites'),
            value: this.activeSites.length
        };
    },

    apiRequestsMtdObj: function() {
        return {
            label: i18next.t('glossary.api_requests'),
            subLabel: { value: 'mtd', style: 'green' },
            value: this.model.calculateRequests('month')
        };
    },

    apiRequestsYtdObj: function() {
        return {
            label: i18next.t('glossary.api_requests'),
            subLabel: { value: 'ytd', style: 'green' },
            value: this.model.calculateRequests('year')
        };
    },

    controlOutputsObj: function() {
        return {
            label: i18next.t('organizations.control_outputs'),
            value: this.outputDevices
        };
    },

    meteringDevicesObj: function() {
        return {
            label: i18next.t('organizations.metering_devices'),
            value: this.meteringDevices
        };
    },

    setVariables: function() {
        this.activeSites = WiseMetering.zones.activeSites();
        this.sitePackages = WiseMetering.sitePackages.filter(bp => this.activeSites.pluck('id').includes(bp.get('building_id')));
        this.wiseboxes = this.sitePackages.map(site => site.get('wiseboxes_used')).reduce((accum, curr) => accum + curr, 0);
        this.allDevices = this.sitePackages.map(site => site.get('devices_used')).reduce((accum, curr) => accum + curr, 0);
        this.outputDevices = this.sitePackages.map(site => site.get('control_outputs_used')).reduce((accum, curr) => accum + curr, 0);
        this.meteringDevices = this.allDevices - this.outputDevices;
    },

    wiseBoxesObj: function() {
        return {
            label: i18next.t('glossary.wise_boxes'),
            value: this.wiseboxes
        };
    }
});
