WiseMetering.Views.IndicatorModalDetails = WiseMetering.Views.Modal.Show.extend({
    className: 'mbx-info text-left',
    form_prefix: 'indicator',
    template: 'templates/ui/layout/empty',
    width: 600,

    buttons: function() {
        let buttons = ['close'];
        if (WiseMetering.currentUser.get('manager')) {
            buttons.push('edit');
        }
        buttons.push('open');
        return buttons;
    },

    edit: function() {
        this.block();
        _.defer(function() {
            new WiseMetering.Views.EditIndicator({ model: this.model });
        }.bind(this));
    },

    onRender: function() {
        this.$el.html(new WiseMetering.Views.IndicatorDetails({ model: this.model }).render().el);
    },

    open: function() {
        this.close();
        Backbone.history.navigate(this.model.nodeUrl(), { trigger: true });
    },

    title: function() {
        return i18next.t('indicators.show_details');
    }
});
