WiseMetering.Views.Ui.GlobalDashboardHeader = Backbone.View.extend({
    templateID: 'templates/ui/global_dashboard_header',
    utilityOrder: [
        'all',
        'electricity',
        'water',
        'gas_energy',
        'hot_water_energy',
        'cooled_water_energy',
        'gas_volume',
        'oil'
    ],

    initialize: function(params) {
        this.data = params.data;
        $.extend(this, params);
    },

    build: function() {
    },

    close: function() {
        this.$el.remove();
    },

    redraw: function() {
        if (this.draw && this.response) {
            this.$('#' + this.id).empty();
            this.draw = false;
        }
    },

    render: function() {
        const orderedUtilities = this.utilityOrder.filter(key => key in this.options.utilities);

        this.$el = $(JST[this.templateID]({
            selectedUtilityKind: this.options.selectedUtilityKind,
            utilities: orderedUtilities
        }));
        $(this.el).append(this.$el);
    }
});
