module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dl class=\'stepped zero\'>\n    <dt>ID</dt>\n    <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.building') )) == null ? '' : __t) +
'</dt>\n    <dd><a href="' +
((__t = ( building.nodeUrl() )) == null ? '' : __t) +
'">' +
((__t = ( building.get('name') )) == null ? '' : __t) +
'</a></dd>\n    <dt>' +
((__t = ( i18next.t('glossary.reference_indicator') )) == null ? '' : __t) +
'</dt>\n    <dd><a href="' +
((__t = ( indicator.nodeUrl() )) == null ? '' : __t) +
'">' +
((__t = ( indicator.get('name') )) == null ? '' : __t) +
'</a></dd>\n    <dt>' +
((__t = ( i18next.t('glossary.integration_indicator') )) == null ? '' : __t) +
'</dt>\n    ';
 integrationIndicators.forEach(function(indicator) { ;
__p += '\n        <dd><a href="' +
((__t = ( indicator.nodeUrl() )) == null ? '' : __t) +
'">' +
((__t = ( indicator.get('name') )) == null ? '' : __t) +
'</a></dd>\n    ';
 }) ;
__p += '\n</dl>\n';

}
return __p
}