WiseMetering.Views.SystemInfoAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/ui/system_info_accordion',

    modelEvents: {
        'change': 'render'
    },

    serializeData: function() {
        const
            json = this.model.toJSON(),
            timezone = this.model.get('timezone') || WiseMetering.currentOrganization.get('timezone');

        json.created_at = WiseMetering.utils.formatDate(json.created_at, timezone, 'YYYY/MM/DD HH:mm');
        json.updated_at = WiseMetering.utils.formatDate(json.updated_at, timezone, 'YYYY/MM/DD HH:mm');
        if (!this.model.has('creator')) {
            json.creator = this.model.creator().get('name');
        }
        if (!this.model.has('updater')) {
            json.updater = this.model.updater().get('name');
        }
        return json;
    }
});
