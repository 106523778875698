WiseMetering.Views.NewNextbittConfiguration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration_configuration',
    template: 'templates/integration_configurations/nextbitt/new',
    title: () => i18next.t('modal.title.integration_configurations.new'),
    buttons: ['validate', 'save', 'cancel'],

    events: {
        'click #validate': 'validate'
    },

    ui: {
        building_reference: '#integration_configuration_building_reference',
        buildings: '#integration_configuration_building_id',
        name: '#integration_configuration_name',
        token: '#integration_configuration_token',
        url: '#integration_configuration_url'
    },

    fetchExternalBuildings: function(token, licenseId, baseUrl) {
        this.modal.block();

        const payload = {
            url: baseUrl,
            token: token,
            license_id: licenseId
        };

        $.ajax({
            url: '/integration_configurations/external_buildings',
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(payload),
            success: function(data) {
                this.externalBuildings = data;
                $('#save').prop('disabled', false);
                this.ui.token.prop('disabled', true);
                this.ui.building_reference.prop('disabled', false);
                this.ui.url.prop('disabled', true);
                this.ui.building_reference.html(WiseMetering.utils.optionsFromArray(data, null, 'building_reference', 'building_reference_name'));
                this.modal.unblock();
            }.bind(this),
            error: function() {
                WiseMetering.layout.showTipper('error', i18next.t('integrations.validation_failed'));
                $('#save').prop('disabled', true);
                this.ui.token.prop('disabled', false);
                this.ui.url.prop('disabled', false);
                this.modal.unblock();
            }.bind(this)
        });
    },

    getFormData: function() {
        const data = this.formSerializer();
        data.building_reference_name = this.ui.building_reference.find(':selected').text();
        return data;
    },

    onShow: function() {
        const buildings = WiseMetering.utils.optionsFromModels(WiseMetering.zones.buildings().models);

        this.ui.buildings.html(buildings);
    },

    validate: function() {
        const baseUrl = this.ui.url.val();
        const token = this.ui.token.val(),
            licenseId = this.model.get("license_id");

        if (baseUrl.length < 1 || baseUrl === i18next.t('integrations.insert_url')) {
            $('#integration_url').val(i18next.t('integrations.insert_url'));
            return;
        }

        if (token.length < 1 || token === i18next.t('integrations.insert_token')) {
            $('#integration_token').val(i18next.t('integrations.insert_token'));
            return;
        }

        this.externalBuildings = this.fetchExternalBuildings(token, licenseId, baseUrl);
    }
});
