WiseMetering.Model.Seu = WiseMetering.Model.extend({
    urlRoot: '/seus',

    defaults: function() {
        return {
            organization_id: WiseMetering.currentOrganization.id
        };
    },

    activities: function() {
        const opportunities = WiseMetering.opportunities.where({ indicator_id: this.get('indicator_id') }),
            activities = new Backbone.Collection;

        const markers = WiseMetering.markers.filter(function(marker) {
            return marker.get('indicator_id') === this.get('indicator_id') && marker.get('marker_type_id') !== 6;
        }.bind(this));

        activities.comparator = function(model) {
            return model.get('name');
        };

        markers.forEach(function(marker) {
            activities.add({
                date: marker.get('marker_at'),
                id: marker.id,
                name: marker.get('name'),
                state: marker.states[marker.get('state')],
                type: 'marker',
                results: marker.results()
            });
        });

        opportunities.forEach(function(opportunity) {
            activities.add({
                date: opportunity.get('created_at'),
                id: opportunity.id,
                name: opportunity.get('title'),
                state: opportunity.states[opportunity.get('state')],
                type: 'opportunity',
                results: ''
            });
        });

        return activities;
    },

    building: function() {
        return WiseMetering.zones.get(this.get('building_id'));
    },

    children: function() {
        let children = new Backbone.Collection;
        children.add(this.seus().models.reverse());
        return children;
    },

    getRegressionChartData: function() {
        return this.fetch({ url: `${this.url()}/regression_data`, silent: true });
    },

    getRSquared: function() {
        if (this.get('analysis') && this.get('analysis').r_squared) {
            const rSquared = this.get('analysis').r_squared * 100;
            return `${rSquared.toFixed(2)}%`;
        } else {
            return 'Not Available';
        }
    },

    icon: function() {
        return 'seus';
    },

    indicator: function() {
        return WiseMetering.indicators.get(this.get('indicator_id'));
    },

    indicators: function() {
        return WiseMetering.indicators.where({ building_id: this.building().id });
    },

    maintenanceActivities: function() {
        const markers = WiseMetering.markers.where({ indicator_id: this.get('indicator_id'), marker_type_id: 6 });
        return new WiseMetering.Collection.Markers(markers);
    },

    nodeUrl: function(skipSearch) {
        return `/seus/${this.get('id')}${skipSearch ? '' : location.search}`;
    },

    parent: function() {
        return WiseMetering.rootObjects.findWhere({ building_id: this.get('building_id'), slug: 'seus' });
    },

    regressionLineFormula: function() {
        const signal = this.get('analysis').a >= 0 ? '+' : '-';
        let a = this.get('analysis').a >= 0 ? this.get('analysis').a : this.get('analysis').a * -1,
            b = this.get('analysis').b;

        if (this.indicator().get('unit') === 'Wh') {
            a /= 1000;
            b /= 1000;
        }

        return `y = ${b.toFixed(4)}x ${signal} ${a.toFixed(4)}`;
    },

    relevance: function() {
        const relevanceValue = this.get('relevance').value;
        return relevanceValue ? relevanceValue * 100 : '';
    },

    seus: function() {
        return new Backbone.Collection(WiseMetering.seus.where({ parent_id: this.id }));
    },

    timezone: function() {
        return this.building().get('timezone');
    },

    type: function() {
        return 'seu';
    },

    variable: function() {
        return WiseMetering.indicators.get(this.get('variable_id'));
    }
});
