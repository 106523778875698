import { CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import * as React from 'react';


export function LoginCard({ title, description, children }) {
    return (
            <div className="tw-shadow-none tw-border-none tw-bg-transparent tw-p-0">
                <CardHeader className="!tw-p-0">
                    {title && <CardTitle className="tw-text-2xl">{title}</CardTitle>}
                    {description && <CardDescription>{description}</CardDescription>}
                </CardHeader>
                <CardContent className="!tw-p-0">
                    {children}
                </CardContent>
            </div>
    );
}
