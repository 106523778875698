module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'offset\' style=\'margin-bottom: 5px;\'>\n    <select name=\'alarm[assets_attributes][]\' class=\'text\' style=\'display:inline-block; width: calc(100% - 31px) !important;\' ' +
((__t = ( assetId ? 'disabled' : '' )) == null ? '' : __t) +
'>\n        <option value=\'\' disabled selected>- Select asset -</option>\n        ';
 options.forEach(function(option) { ;
__p += '\n            <option ' +
((__t = ( (option.assetId === assetId) ? 'selected ' : '' )) == null ? '' : __t) +
' value=\'' +
((__t = ( option.assetId )) == null ? '' : __t) +
'\'>\n                ' +
((__t = ( option.assetName )) == null ? '' : __t) +
'\n            </option>\n        ';
 }) ;
__p += '\n    </select>\n    <div class=\'wmui-btn rm wmui-symbol strong\' id=\'remove-asset\'>✖</div>\n</div>\n\n';

}
return __p
}