module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'etl_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'etl_name\' name=\'etl[name]\'>\n        </dd>\n        <dt>\n            <label for=\'etl_driver\'>' +
((__t = ( i18next.t('glossary.driver') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'etl_driver\' name=\'etl[driver_id]\'>\n                <option disabled selected value=\'\'>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                ';
 WiseMetering.etlDrivers.forEach(function(etl_driver){ ;
__p += '\n                    ';
 if (etl_driver.usable) { ;
__p += '\n                        <option slug="' +
((__t = ( etl_driver.slug )) == null ? '' : __t) +
'" value="' +
((__t = ( etl_driver.id )) == null ? '' : __t) +
'">' +
((__t = ( etl_driver.name )) == null ? '' : __t) +
'</option>\n                ';
 }}) ;
__p += '\n            </select>\n        </dd>\n        <div id=\'type-container\'></div>\n</form>\n';

}
return __p
}