module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'mbx-info green\'>\n    ' +
((__t = ( text )) == null ? '' : __t) +
'\n</div>\n';

}
return __p
}