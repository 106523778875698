WiseMetering.Collection.Markers = WiseMetering.Collection.extend({
    hasOrganization: true,
    model: WiseMetering.Model.Marker,
    url: '/markers',

    activeMarkers: function() {
        let data = this.filter(function(model) {
            return ['ongoing', 'active'].includes(model.get('state'));
        });
        return new WiseMetering.Collection.Markers(data);
    },

    discardedMarkers: function() {
        return new WiseMetering.Collection.Markers(this.where({ state: 'discarded' }));
    },

    pendingMarkers: function() {
        return new WiseMetering.Collection.Markers(this.where({ state: 'pending' }));
    }
});
