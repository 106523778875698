module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'accordion\' style=\'margin-bottom: 0;\'>\n    <div class=\'accordion-group\'>\n        <div class=\'accordion-heading\'>\n            <div class=\'accordion-toggle\'>\n                <div class=\'svg_icon\'>\n                    ' +
((__t = ( WiseMetering.icon('invoices') )) == null ? '' : __t) +
'\n                </div>\n                ' +
((__t = ( i18next.t('tenants.invoicing_options') )) == null ? '' : __t) +
'\n            </div>\n        </div>\n        <div class=\'accordion-body\'>\n            <div class=\'accordion-inner\'>\n                <div class=\'per-box\'>\n                    <dl>\n                        <dt>' +
((__t = ( i18next.t('common.interval') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( interval )) == null ? '' : __t) +
'</dd>\n                        <dt>' +
((__t = ( i18next.t('tenants.invoicing_day_of_month') )) == null ? '' : __t) +
' (1-28):</dt>\n                        <dd>' +
((__t = ( day )) == null ? '' : __t) +
'</dd>\n                        <dt style="margin-top: 5px;">' +
((__t = ( i18next.t('tenants.invoice_per_area') )) == null ? '' : __t) +
':</dt>\n                        <dd style="margin-top: 5px;">\n                            <div style="position: absolute">\n                                <label class="switch">\n                                    <input type="checkbox" id="invoice-mode-switcher" name=\'tenant[mode]\' ' +
((__t = ( isPerArea ? 'checked' : '' )) == null ? '' : __t) +
'>\n                                    <span class="slider round"></span>\n                                </label>\n                            </div>\n                        </dd>\n                        <dt style="margin-top: 5px;">' +
((__t = ( i18next.t('tenants.detailed_invoice') )) == null ? '' : __t) +
':</dt>\n                        <dd style="margin-top: 5px;">\n                            <div style="position: absolute">\n                                <label class="switch">\n                                    <input type="checkbox" id="invoice-is-detailed-switcher" name=\'tenant[is_detailed]\' ' +
((__t = ( is_detailed ? 'checked' : '' )) == null ? '' : __t) +
'>\n                                    <span class="slider round"></span>\n                                </label>\n                            </div>\n                        </dd>\n                        <dt style="margin-top: 5px;">' +
((__t = ( i18next.t('tenants.include_vat') )) == null ? '' : __t) +
':</dt>\n                        <dd style="margin-top: 5px;">\n                            <div style="position: absolute">\n                                <label class="switch">\n                                    <input id="invoice-is-vat-switcher" name=\'tenant[is_vat]\' type="checkbox" ' +
((__t = ( is_vat ? 'checked' : '' )) == null ? '' : __t) +
'>\n                                    <span class="slider round"></span>\n                                </label>\n                            </div>\n                        </dd>\n                        <dt>' +
((__t = ( i18next.t('tenants.invoicing_notes') )) == null ? '' : __t) +
':</dt>\n                        <dd>\n                            ';
 if (invoicing_notes) { ;
__p += '\n                                <span style="white-space:pre-wrap">' +
((__t = ( invoicing_notes )) == null ? '' : __t) +
'</span>\n                            ';
 } else { ;
__p += '\n                                ' +
((__t = ( i18next.t('tenants.no_notes') )) == null ? '' : __t) +
'\n                            ';
 } ;
__p += '\n                        </dd>\n                    </dl>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}