WiseMetering.Model.Allocation = WiseMetering.Model.extend({
    urlRoot: '/allocations',

    icon: function() {
        return 'allocations';
    },

    building: function() {
        return WiseMetering.zones.get(this.get('building_id'));
    },

    getDate: function(dateKey) {
        if (typeof this.get(dateKey) === 'number') {
            return WiseMetering.utils.formatDate(this.get(dateKey), this.timezone(), 'YYYY-MM-DD');
        }

        return this.get(dateKey) || '';
    },

    getDateTime: function(dateKey) {
        if (typeof this.get(dateKey) === 'number') {
            return WiseMetering.utils.formatDate(this.get(dateKey), this.timezone(), 'YYYY-MM-DD HH:mm');
        }

        return this.get(dateKey) || '';
    },

    indicator: function() {
        return WiseMetering.indicators.get(this.get('indicator_id'));
    },

    parent: function() {
        return WiseMetering.tenants.get(this.get('tenant_id'));
    },

    timezone: function() {
        return this.building() ? this.building().get('timezone') : WiseMetering.currentOrganization.get('timezone');
    }
});
