WiseMetering.Views.CardsView = Backbone.Marionette.ItemView.extend({
    template: 'templates/ui/cards_view',
    className: 'wmui-small-card-view',

    initialize: function(attributes) {
        $.extend(this, attributes);
    },

    serializeData: function() {
        const json = this.model.toJSON();
        json.options = this.cards();
        return json;
    }
});
