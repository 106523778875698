import { Button, Card, CardContent, CardDescription, CardFooter, CardHeaderImage, CardText, Icon, ModalSave, ModelProperty } from '@/components/wm';
import * as React from 'react';
import { useState } from 'react';

export function Partner({ partner, license, organizationId }) {
    const
            [modalOpen, setModalOpen] = useState(false);

    const
            image = `/images/integration-partners/${partner.slug}.png`,
            slug = partner.slug;

    const determineButtonState = () => {
        const
                state = partner.state;

        if (state === 'active') {
            if (WiseMetering.currentUser.isSuperUser()) {
                if (!license) {
                    return { label: 'Activate license', disabled: false, onClick: () => createLicense() };
                }
                if (license && license.state === 'request') {
                    return { label: 'Requested', disabled: false, onClick: () => navigateToLicense() };
                }
                if (license && license.state === 'active') {
                    return { label: 'Manage', disabled: false, onClick: () => navigateToLicense() };
                }
            }
            if (WiseMetering.currentUser.isAdmin()) {
                if (!license) {
                    return { label: 'Request activation', disabled: false, onClick: () => createLicense() };
                }
                if (license && license.state === 'request') {
                    return { label: 'Activation requested', disabled: true };
                }
                if (license && license.state === 'active') {
                    return { label: 'Manage', disabled: false, onClick: () => navigateToLicense() };
                }
            }
            return { label: 'Activate', disabled: false };
        }
        return { label: 'Upcoming', disabled: true };
    };

    const buttonState = determineButtonState();

    const createLicense = () => {
        setModalOpen(true);
    };

    const navigateToLicense = () => {
        const href = `/integration_licenses/${license.id}`;
        Backbone.history.navigate(href, { trigger: true });
    };

    return (
            <div>
                {modalOpen && (
                        <ModalSave
                                model={license}
                                mode={license ? 'edit' : 'create'}
                                onClose={() => setModalOpen(false)}
                        >
                            {WiseMetering.currentUser.isSuperUser() ? (
                                    <ModelProperty property="total"/>
                            ) : (
                                    <ModelProperty property="total" value={0} type="hidden"/>
                            )
                            }
                            <ModelProperty property="comment" type="text"/>
                            <ModelProperty property="partner_id" value={partner.id} type="hidden"/>
                            <ModelProperty property="organization_id" value={organizationId} type="hidden"/>
                        </ModalSave>
                )}
                <Card className="tw-h-full">
                    <CardHeaderImage>
                        <img src={image} alt={partner.name}/>
                    </CardHeaderImage>
                    <CardDescription>
                    <span>
                        {partner.name}
                    </span>
                        <span>
                        {partner.homepage && (
                                <a href={partner.homepage} target="_blank" rel="noopener noreferrer">
                                    <Button variant="ghost" size="socialIcon">
                                        <Icon icon="globe"/>
                                    </Button>
                                </a>
                        )}
                            {partner.linkedin && (
                                    <a href={partner.linkedin} target="_blank" rel="noopener noreferrer">
                                        <Button variant="ghost" size="socialIcon">
                                            <Icon icon="linkedIn"/>
                                        </Button>
                                    </a>
                            )}
                    </span>
                    </CardDescription>
                    <CardContent>
                        <CardText title={i18next.t('common.description').capitalize()}>
                            {i18next.t(`integration_partners.partners.${slug}.description`)}
                        </CardText>
                        <CardText title={i18next.t('common.benefits').capitalize()}>
                            {i18next.t(`integration_partners.partners.${slug}.benefits`)}
                        </CardText>
                        <CardText title={i18next.t('integration_partners.default_tier').capitalize()}>
                            {partner.includedInTiers}
                        </CardText>
                        <CardText title={i18next.t('integration_partners.tier_requirement').capitalize()}>
                            {partner.tierRequirement.capitalize()}
                        </CardText>
                    </CardContent>
                    <CardFooter>
                        <Button
                                type="button"
                                className="tw-w-full"
                                disabled={buttonState.disabled}
                                onClick={buttonState.onClick}
                        >
                            {buttonState.label}
                        </Button>
                    </CardFooter>
                </Card>
            </div>
    );
}
