module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        ';
 _.each(availableIndicators, function(list){ ;
__p += '\n            <dt>\n                <label>' +
((__t = ( list.kind )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                ';
 _.each(list.indicators, function(indicator){ ;
__p += '\n                    ';
 if(currentIndicators.contains(indicator)){ ;
__p += '\n                        <input checked=\'checked\' id=\'' +
((__t = ( indicator.id )) == null ? '' : __t) +
'\' name=\'folder[indicator_ids][]\' style=\'margin-left: 0;\' type=\'checkbox\' value=\'' +
((__t = ( indicator.id )) == null ? '' : __t) +
'\'>\n                    ';
 } else { ;
__p += '\n                        <input id=\'' +
((__t = ( indicator.id )) == null ? '' : __t) +
'\' name=\'folder[indicator_ids][]\' style=\'margin-left: 0;\' type=\'checkbox\' value=\'' +
((__t = ( indicator.id )) == null ? '' : __t) +
'\'>\n                    ';
 } ;
__p += '\n                    <label for=\'' +
((__t = ( indicator.id )) == null ? '' : __t) +
'\'>\n                        ' +
((__t = ( indicator.get('name') )) == null ? '' : __t) +
'\n                        <br>\n                    </label>\n                ';
 }) ;
__p += '\n            </dd>\n        ';
 }) ;
__p += '\n    </dl>\n</form>\n';

}
return __p
}