WiseMetering.Views.RemoveIndicatorFrom = WiseMetering.Views.Modal.Destroy.extend({

    title: () => i18next.t('modal.title.indicators.destroy'),

    yes: function() {
        var that = this;
        switch (this.type) {
            case 'building':
            case 'zone':
            case 'circuit':
                this.saveIt(this.model, { zone_id: null, circuit_id: null },
                    {
                        success: that.model.get('name') + ' removed successfully!',
                        error: 'Failed to remove ' + that.model.get('name') + '.'
                    });
                break;
            case 'folder':
                var folder = that.from;
                var indicators = _.reject(folder.get('indicators'), function(id) { return id === that.model.get('id'); });
                this.saveIt(folder, { indicator_ids: indicators },
                    {
                        success: that.model.get('name') + ' removed successfully!',
                        error: 'Failed to remove ' + that.model.get('name') + '.'
                    });
                break;
        }
    },

    saveIt: function(model, data, messages) {
        var that = this;
        model.save(data, {
            sync: true,
            wait: true,
            patch: true,
            success: function() {
                that.close();
                WiseMetering.layout.showTipper('success', messages.success);
            },
            error: function() {
                that.close();
                WiseMetering.layout.showTipper('error', messages.error);
            }
        });
    }
});
