module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <span id=\'spinner\' style=\'color:#224C4F\' hidden>\n            <i class=\'far fa-spinner fa-spin\'></i> i18next.t(\'integrations.fetching_third_party_buildings\')\n        </span>\n        <dt>\n            <label for=\'integration_configuration_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_configuration_name\' name=\'integration_configuration[name]\'>\n        </dd>\n        <dt>\n            <label for=\'integration_configuration_building_id\'>' +
((__t = ( i18next.t('glossary.building') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'integration_configuration_building_id\' name=\'integration_configuration[building_id]\'></select>\n        </dd>\n        <dt>\n            <label for=\'integration_configuration_token\'>' +
((__t = ( i18next.t('glossary.token') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' name=\'integration_configuration[token]\' id=\'integration_configuration_token\'>\n        </dd>\n        <dt>\n            <label for=\'integration_configuration_building_reference\'>' +
((__t = ( i18next.t('integrations.infraspeak_building') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'integration_configuration_building_reference\' name=\'integration_configuration[building_reference]\' disabled>\n                <option value=\'\'>' +
((__t = ( i18next.t('ui.please_validate') )) == null ? '' : __t) +
'</option>\n            </select>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}