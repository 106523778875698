module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'accordion-group\'>\n    <div class=\'accordion-heading\'>\n        <div class=\'accordion-toggle\'>\n            <div class=\'svg_icon\'>\n                ' +
((__t = ( WiseMetering.icon('configuration') )) == null ? '' : __t) +
'\n            </div>\n            ' +
((__t = ( i18next.t('zones.configuration_status') )) == null ? '' : __t) +
'\n        </div>\n    </div>\n\n    <div class=\'accordion-body\'>\n        <div class=\'accordion-inner\' id=\'zones\'>\n            <div class="progress">\n                <div class="progress-bar ';
 if (status < 100) { ;
__p += 'yellow';
 } ;
__p += '" role="progressbar"\n                     aria-valuenow="' +
((__t = ( status )) == null ? '' : __t) +
'" aria-valuemin="0" aria-valuemax="100" style="width:' +
((__t = ( status )) == null ? '' : __t) +
'%;"\n                >\n                    ' +
((__t = ( status )) == null ? '' : __t) +
'%\n                </div>\n            </div>\n            ';
 if (missingConfigurations.length > 0) { ;
__p += '\n                <div class="missing-configurations">\n                    <div class="title">' +
((__t = ( i18next.t('configuration_status.title') )) == null ? '' : __t) +
'</div>\n                    ';
 _.each(missingConfigurations, function(item) { ;
__p += '\n                        <div>' +
((__t = ( WiseMetering.icon('exclamation') )) == null ? '' : __t) +
' ' +
((__t = ( i18next.t(`configuration_status.${item}`) )) == null ? '' : __t) +
'</div>\n                    ';
 }) ;
__p += '\n                </div>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n</div>\n';

}
return __p
}