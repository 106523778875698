WiseMetering.Views.Tenant = WiseMetering.Views.Content.extend({
    initialize: function() {
        this.icon = this.model.icon();
        this.tabs = [
            {
                id: 'details',
                name: i18next.t('common.details'),
                view: WiseMetering.Views.TenantDetailsTab
            },
            {
                id: 'invoices',
                name: i18next.t('glossary.proforma_invoices'),
                view: WiseMetering.Views.IndexTenantInvoicesByTenant
            },
            {
                id: 'allocations-history',
                name: i18next.t('common.history'),
                view: WiseMetering.Views.IndexAllocationsHistory
            }
        ];
    }
});
