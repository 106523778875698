WiseMetering.Views.ObjectivesAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/ui/layout/empty',

    columns: function() {
        const columns = {};
        columns.year = i18next.t('common.year');
        columns.deviation = {
            title: i18next.t('glossary.deviation'),
            mRender: function(deviation) {
                const color = WiseMetering.SemaphorColors[deviation > 0 ? 'red' : 'green'],
                    formattedValue = formatValue(deviation * 100, '%');

                return `<span style='color: ${color}'>${deviation > 0 ? '+' : ''}${formattedValue}</span>`;
            }
        };
        if (this.model.get('type') === 'building') {
            columns.indicatorName = i18next.t('glossary.indicator');
        }
        columns.unit = { title: i18next.t('glossary.unit'), className: 'center' };
        this.months().forEach((month, index) => {
            columns[month] = {
                title: this.monthNames()[index],
                className: 'align-right'
            };
        });
        return columns;
    },

    monthNames: () => i18next.t('months', { returnObjects: true }),

    months: () => Lmit.Wise.Chart.Defaults.month.map(month => month.toLowerCase()),

    onClose: function() {
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    onRender: function() {
        const collection = this.model.objectives();

        this.table = new WiseMetering.Views.Table({
            id: 'objectives',
            collection: collection,
            modelSerializer: objective => {
                const json = objective.toJSON(),
                    utilityKind = WiseMetering.utilityKinds.get(json.utility_kind_id);

                json.utilityKindName = i18next.t(`utility_kinds.${utilityKind.get('slug')}`).capitalize();
                json.indicatorName = objective.indicator().get('name');
                this.months().forEach(month => json[month] = formatValue(json[month], '', 0));
                return json;
            },
            columns: this.columns(),
            toolbar_actions: this.toolbarActions(),
            filter: false,
            info: false,
            length: false,
            paginate: false,
            sort: {
                column: 'year',
                order: 'desc'
            }
        });

        this.$el.html(this.table.el);

        this.listenTo(WiseMetering.objectives, 'add', function(objective) {
            collection.add(objective);
        });
    },

    newModel: function() {
        const key = this.model.get('type') === 'building' ? 'zone_id' : 'indicator_id';
        return new WiseMetering.Model.Objective({ [key]: this.model.id });
    },

    toolbarActions: function() {
        let actions = {};
        if (this.model.get('type') !== 'building') {
            actions.new = {
                view_call: () => new WiseMetering.Views.ManageObjective({
                    collection: WiseMetering.objectives,
                    indicators: new WiseMetering.Collection.Indicators,
                    model: this.newModel(),
                    type: 'new'
                })
            };
        }
        actions.edit = { view_call: model => new WiseMetering.Views.ManageObjective({ model, type: 'edit' }) };
        actions.delete = { view_call: model => new WiseMetering.Views.DestroyObjective({ model }) };
        return actions;
    }
});
