module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        ';
 _.each(availableIndicators, function(list){ ;
__p += '\n            <dt>\n                <label for=\'indicators\'>' +
((__t = ( list.kind )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <select class=\'indicators text\'>\n                    <option value=\'\'>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                    ';
 _.each(list.indicators, function(indicator){ ;
__p += '\n                        ';
 if(currentIndicators.include(indicator)){ ;
__p += '\n                            <option name=\'' +
((__t = ( form_prefix )) == null ? '' : __t) +
'[indicator_ids][]\' selected=\'selected\' value=\'' +
((__t = ( indicator.id )) == null ? '' : __t) +
'\'>' +
((__t = ( indicator.get('name') )) == null ? '' : __t) +
'</option>\n                        ';
 } else { ;
__p += '\n                            <option name=\'' +
((__t = ( form_prefix )) == null ? '' : __t) +
'[indicator_ids][]\' value=\'' +
((__t = ( indicator.id )) == null ? '' : __t) +
'\'>' +
((__t = ( indicator.get('name') )) == null ? '' : __t) +
'</option>\n                        ';
 } ;
__p += '\n                    ';
 }) ;
__p += '\n                </select>\n            </dd>\n        ';
 }) ;
__p += '\n    </dl>\n</form>\n';

}
return __p
}