module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'building_package\'>' +
((__t = ( i18next.t('site_packages.building_package') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select id=\'building_package\' name=\'building_package[package_id]\' class=\'companies text\' style="text-transform: capitalize;">\n                ';
 if (!packageId) { ;
__p += '\n                    <option disabled selected>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                ';
 } ;
__p += '\n                ';
 packages.forEach(function (pkg) { ;
__p += '\n                    <option ' +
((__t = ( pkg.id === packageId ? 'selected' : '' )) == null ? '' : __t) +
' ' +
((__t = ( pkg.get('active') ? '' : 'disabled' )) == null ? '' : __t) +
' value=\'' +
((__t = ( pkg.id )) == null ? '' : __t) +
'\'>\n                        ' +
((__t = ( pkg.get('name') )) == null ? '' : __t) +
'\n                    </option>\n                ';
 }) ;
__p += '\n            </select>\n        </dd>\n        ';
 if (!isTierEssentials) { ;
__p += '\n            <dt>\n                <label for=\'extra-wiseboxes\'>' +
((__t = ( i18next.t('site_packages.extra_wise_boxes') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <input class=\'text\' id=\'extra-wiseboxes\' name=\'building_package[extra_wiseboxes]\' type=\'text\' value=\'' +
((__t = ( wiseboxes_extra_limit )) == null ? '' : __t) +
'\'/>\n            </dd>\n        ';
 } ;
__p += '\n        <dt>\n            <label for=\'extra-devices\'>' +
((__t = ( i18next.t('site_packages.extra_devices') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'extra-devices\' name=\'building_package[extra_devices]\' type=\'text\' value=\'' +
((__t = ( devices_extra_limit )) == null ? '' : __t) +
'\'/>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}