import { Button } from '@/components/ui/button';
import { Input } from '@/components/wm';
import { cn } from '@/utils';
import { EyeIcon, EyeOffIcon } from 'lucide-react';
import * as React from 'react';
import { useState } from 'react';

const PasswordInput = React.forwardRef(({ className, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const disabled = props.value === '' || props.value === undefined || props.disabled;

    return (
            <div className="tw-relative w-full max-w-sm items-center space-x-2">
                <Input
                        type={showPassword ? 'text' : 'password'}
                        className={cn('hide-password-toggle tw-pr-10 tw-mt-[-8px]', className)}
                        ref={ref}
                        {...props}
                />
                <Button
                        type="button"
                        variant="ghost"
                        size="sm"
                        className="tw-absolute tw-right-0 tw-top-0 tw-h-full tw-px-3 tw-pb-2 tw-pt-0 hover:tw-bg-transparent"
                        onClick={() => setShowPassword((prev) => !prev)}
                        disabled={disabled}
                >
                    {showPassword && !disabled ? (
                            <EyeIcon className="tw-h-4 tw-w-4" aria-hidden="true"/>
                    ) : (
                            <EyeOffIcon className="tw-h-4 tw-w-4" aria-hidden="true"/>
                    )}
                    <span className="sr-only">{showPassword ? 'Hide password' : 'Show password'}</span>
                </Button>

                {/* hides browsers password toggles */}
                <style>{`
					.hide-password-toggle::-ms-reveal,
					.hide-password-toggle::-ms-clear {
						visibility: hidden;
						pointer-events: none;
						display: none;
					}
				`}</style>
            </div>
    );
});

PasswordInput.displayName = 'PasswordInput';

export { PasswordInput };
