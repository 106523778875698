WiseMetering.Model.IntegrationPartner = WiseMetering.Model.extend({
    getJsonFormatted: function() {
        const json = this.toJSON();

        json.description = i18next.t(`integration_partners.partners.${this.get('slug')}.description`) || 'n/a';
        json.benefits = i18next.t(`integration_partners.partners.${this.get('slug')}.benefits`) || 'n/a';
        json.default_tier = this.tiers('default_tier') || i18next.t(`integration_partners.no_default_tier`);
        json.tier_requirement = this.tiers('tier_requirement') || i18next.t(`integration_partners.no_tier_requirement`);

        return json;
    },

    getIndexURL: function() {
        const license = WiseMetering.integrationLicenses.findWhere({ partner_id: this.id });

        if (license) {
            return `/integration_licenses/${license.id}`;
        } else {
            return '/map';
        }
    },

    getClassName: function() {
        let capitalise = word => word.charAt(0).toUpperCase() + word.slice(1);
        return this.get('slug').split('_').map(word => capitalise(word)).join('');
    },

    tiers: function() {
        const tierName = WiseMetering.tiers.get(this.get('default_tier_id')).get('name');

        if (tierName === 'essentials') {
            return 'Essentials, Standard, Advanced and Premium';
        } else if (tierName === 'standard') {
            return 'Standard, Advanced and Premium';
        } else if (tierName === 'advanced') {
            return 'Advanced and Premium';
        } else if (tierName === 'premium') {
            return 'Premium';
        }
    }
});
