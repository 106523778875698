WiseMetering.Views.ShowAlarm = WiseMetering.Views.Modal.Show.extend({
    scheduleView: null,
    template: 'templates/alarms/show',
    width: 1150,
    ui: {
        schedule: '#alarm-schedule'
    },
    title: () => i18next.t('modal.title.alarms.show'),

    buttons: function() {
        const buttons = ['close'];
        if (this.model.get('schedule_id')) {
            buttons.push('edit');
        }
        if (this.model.get('indicator_id')) {
            buttons.push('open');
        }
        return buttons;
    },

    className: function() {
        if (this.model.get('schedule_id')) {
            return;
        }

        return 'mbx-info text-left';
    },

    edit: function() {
        new WiseMetering.Views.EditAlarm({ model: this.model });
    },

    initialize: function() {
        WiseMetering.Views.Modal.Show.prototype.initialize.apply(this);

        const
            alarmSchedule = this.model.schedule(),
            indicator = this.model.indicator(),
            periods = alarmSchedule.periods(),
            schedule = alarmSchedule.schedule();

        let unit = indicator.get('unit'), values = this.model.get('values');

        if (['W', 'Wh', 'VArh'].includes(unit)) {
            unit = `k${unit}`;
            values = {};
            Object.entries(this.model.get('values')).forEach(([key, hash]) => {
                values[key] = { max: hash['max'] / 1000, min: hash['min'] / 1000 };
            });
        }

        this.scheduleView = new WiseMetering.Views.AlarmSchedule({ periods, schedule, unit, values });
        this.ui.schedule.append(this.scheduleView.el);

        WiseMetering.modalBox.currentView.redraw();
    },

    open: function() {
        this.close();
        Backbone.history.navigate(this.model.indicator().nodeUrl(), { trigger: true });
    },

    serializeData: function() {
        return Object.assign(
            this.model.toJSON(), {
                created_at: WiseMetering.utils.formatDate(this.model.get('created_at')),
                indicator: this.model.indicator().get('name'),
                integration_assets: this.model.get('assets'),
                last_checked_humanized: this.model.last_checked_humanized(),
                notifications: this.model.notifications().join(', ')
            }
        );
    }
});
