import { Card as ShadCard, CardContent as ShadCardContent, CardDescription as ShadCardDescription, CardFooter as ShadCardFooter, CardTitle as ShadCardTitle } from '@/components/ui/card';
import { cn } from '@/utils';
import * as React from 'react';

const Card = React.forwardRef(({ className, ...props }, ref) => (
        <ShadCard
                ref={ref}
                className={cn('tw-flex tw-flex-col tw-h-full tw-border tw-border-solid tw-border-border tw-rounded-lg', className)}
                {...props}
        />
));
Card.displayName = 'Card';

const CardDescription = React.forwardRef(({ className, ...props }, ref) => (
        <ShadCardDescription
                ref={ref}
                className={cn('!tw-p-2 tw-flex tw-justify-between tw-items-center', className)}
                {...props}
        />
));
CardDescription.displayName = 'CardDescription';

const CardContent = React.forwardRef(({ className, ...props }, ref) => (
        <ShadCardContent
                ref={ref}
                className={cn('!tw-p-2', className)}
                {...props}
        />
));
CardContent.displayName = 'CardHeader';

const CardHeader = React.forwardRef(
        ({ className, title, subtitle, button, ...props }, ref) => (
                <div
                        ref={ref}
                        className={cn(
                                'tw-p-4 tw-bg-header-custom tw-rounded-t-xl tw-flex tw-items-center tw-justify-between tw-space-y-2 sm:tw-space-y-0 sm:tw-space-x-4',
                                className
                        )}
                        {...props}
                >
                    {/* Title and Subtitle */}
                    <div className="tw-flex tw-flex-col tw-justify-center">
                        <span className="tw-text-lg tw-font-bold tw-text-primary">{title}</span>
                        {subtitle && (
                                <span className="tw-text-sm tw-text-secondary">{subtitle}</span>
                        )}
                    </div>

                    {/* Button */}
                    <div className="tw-flex tw-items-center">{button}</div>
                </div>
        )
);
CardHeader.displayName = 'CardHeader';

const CardHeaderImage = React.forwardRef(({ className, size = '', ...props }, ref) => (
        <div
                ref={ref}
                className={cn('tw-overflow-hidden tw-rounded-t-lg tw-bg-cover tw-w-full', className)}
                style={{ width: size, margin: '0' }}
                {...props}
        />
));
CardHeaderImage.displayName = 'CardHeaderImage';

const CardText = React.forwardRef(({ className, title = '', children, ...props }, ref) => (
        <div
                ref={ref}
                className={cn('tw-mb-4', className)}
                {...props}
        >
            <h6 className="">{title}</h6>
            <p className="tw-text-justify">{children}</p>
        </div>
));
CardHeaderImage.displayName = 'CardText';

const CardStat = React.forwardRef(({ className, ...props }, ref) => (
        <div
                ref={ref}
                className={cn('tw-p-4 tw-text-2xl tw-font-bold tw-text-gray-800', className)}
                {...props}
        />
));
CardStat.displayName = 'CardText';

const CardFooter = React.forwardRef(({ className, children, ...props }, ref) => (
        <ShadCardFooter
                ref={ref}
                className={cn('tw-grow !tw-p-2', className)}
                {...props}
        >
            <div className="tw-mt-auto tw-w-full">
                {children}
            </div>
        </ShadCardFooter>
));
CardHeader.displayName = 'CardHeader';

const CardWrapper = ({ cardSize = 'default', className, children, ...props }) => {
    const childCount = React.Children.count(children);

    const sizeClasses = {
        small: `tw-grid tw-grid-cols-2 md:tw-grid-cols-${Math.min(childCount, 3)} lg:tw-grid-cols-${Math.min(childCount, 4)} xl:tw-grid-cols-${Math.min(childCount, 6)} tw-gap-2`,
        default: `tw-grid tw-grid-cols-1 md:tw-grid-cols-${Math.min(childCount, 2)} lg:tw-grid-cols-${Math.min(childCount, 3)} xl:tw-grid-cols-${Math.min(childCount, 4)} tw-gap-4`,
        large: `tw-grid tw-grid-cols-1 md:tw-grid-cols-${Math.min(childCount, 1)} lg:tw-grid-cols-${Math.min(childCount, 2)} xl:tw-grid-cols-${Math.min(childCount, 3)} tw-gap-6`
    };

    return (
            <div className={cn(sizeClasses[cardSize], className)} {...props}>
                {children}
            </div>
    );
};


const CardTitle = ShadCardTitle;

export {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardHeaderImage,
    CardStat,
    CardText,
    CardTitle,
    CardWrapper
};
