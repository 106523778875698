import { AlertBox } from '@/components/wm';
import { ForgotPassword } from '@/pages/Login/Content/forgot-password';
import { LoginForm } from '@/pages/Login/Content/login-form';
import { LoginOtp } from '@/pages/Login/Content/login-otp';
import { ResetPassword } from '@/pages/Login/Content/reset-password';
import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';
import { useState } from 'react';

export default function LoginPage({ initialAuthState = 'login', code = '' }) {
    const emptyAlert = { active: false, message: '', variant: '', title: '' };

    const
            [alert, setAlert] = useState(emptyAlert),
            [authState, setAuthState] = useState(initialAuthState), // login, otp, forgotPassword, resetPassword
            [credentials, setCredentials] = useState({ email: '', password: '' });

    const deactivateAlert = () => {
        setAlert(emptyAlert);
    };

    const handleAlert = (message, variant = '', title) => {
        deactivateAlert();

        setTimeout(() => {
            setAlert({ active: true, message, variant, title });
        }, 500);
    };

    const handleBackToLogin = () => {
        window.history.replaceState(null, '', '/login');
        setAuthState('login');
    };

    const handleForgotPassword = (email) => {
        setCredentials({ email, password: '' });
        setAuthState('forgotPassword');
    };

    const handleLoginSuccess = (email, password) => {
        setCredentials({ email, password });
        setAuthState('otp');
    };

    return (
            <div className="tw-grid tw-min-h-[100dvh] tw-w-full tw-grid-cols-1 lg:tw-grid-cols-2">
                {/* Left-side area */}
                <div className="tw-overflow-y-auto tw-overflow-x-hidden tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-screen tw-bg-backgroundGrey tw-p-6 dark:tw-bg-gray-800 lg:tw-p-10">
                    <div className="tw-w-full tw-max-w-xs sm:tw-max-w-sm 2xl:tw-max-w-md">
                        <div className="tw-mb-6 tw-flex tw-justify-center">
                            <img src="/images/wisemetering/color-logo-horizontal.png" alt=""/>
                        </div>
                        <div className="tw-h-[255px] tw-w-full">
                            <AnimatePresence mode="wait">
                                <motion.div
                                        key={authState} // Ensures component re-renders on authState change
                                        initial={{ x: '100%', opacity: 0 }}
                                        animate={{ x: 0, opacity: 1 }}
                                        exit={{ x: '-100%', opacity: 0 }}
                                        transition={{ duration: 0.5 }}
                                        className="tw-w-full tw-h-full"
                                >
                                    {authState === 'login' && (
                                            <LoginForm onOtpRequest={handleLoginSuccess} onAlert={handleAlert} onForgotPassword={handleForgotPassword}/>
                                    )}
                                    {authState === 'otp' && (
                                            <LoginOtp email={credentials.email} onAlert={handleAlert} password={credentials.password} backToLogin={handleBackToLogin}/>
                                    )}
                                    {authState === 'forgotPassword' && (
                                            <ForgotPassword loginEmail={credentials.email} onAlert={handleAlert} backToLogin={handleBackToLogin}/>
                                    )}
                                    {authState === 'resetPassword' && (
                                            <ResetPassword onAlert={handleAlert} code={code} backToLogin={handleBackToLogin}/>
                                    )}
                                </motion.div>
                            </AnimatePresence>
                        </div>
                        <div>
                            <div className="tw-w-full tw-max-w-sm 2xl:tw-max-w-md tw-absolute tw-top-0 tw-pr-14 sm:tw-pr-0">
                                <AlertBox
                                        active={alert.active}
                                        deactivateAlert={deactivateAlert}
                                        animation={true}
                                        animationProps={{ transition: { duration: 0.5 } }}
                                        variant={alert.variant}
                                        icon={alert.variant}
                                        title={alert.title}
                                        duration={5000}
                                >
                                    {alert.message}
                                </AlertBox>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Right-side background image */}
                <div className="tw-hidden tw-bg-[url('/images/placeholder.webp')] tw-bg-cover tw-bg-center tw-bg-no-repeat lg:tw-block">
                    <div className="tw-text-6xl tw-text-white tw-font-semibold tw-p-20 lg:tw-text-5xl xl:tw-text-6xl 2xl:tw-text-7xl">
                        <p>Towards</p>
                        <p>a Wise Future</p>
                    </div>
                </div>
            </div>

    );
}
