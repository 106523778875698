module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<table border=\'0\' cellpadding=\'0\' cellspacing=\'0\' class=\'display\' id=\'' +
((__t = ( id )) == null ? '' : __t) +
'\'></table>\n';

}
return __p
}