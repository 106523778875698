WiseMetering.Views.ZoneAutomationWiseBoxesAccordion = Backbone.Marionette.ItemView.extend({

    template: 'templates/zones/automation/wise_boxes',

    serializeData: function() {
        const wiseBoxes = WiseMetering.devices.wiseBoxes(this.model.get('building_id'));
        return { wiseBoxes };
    }

});
