WiseMetering.Views.EditOrganization = WiseMetering.Views.Crud.Save.extend({
    template: 'templates/ui/crud/edit',
    className: 'crud-info',
    form_prefix: 'organization',
    object: 'organization',
    title: () => i18next.t('modal.title.organizations.edit'),

    events: {
        'change .input-file': 'changeLogo'
    },

    ui: {
        logoPreviewImage: '.preview-image',
        PreviewImageSection: '.preview-image-wrapper'
    },

    changeLogo: function(event) {
        const [file] = event.target.files;
        if (file) {
            this.ui.PreviewImageSection.show();
            this.ui.logoPreviewImage.attr('src', URL.createObjectURL(file));
            this.logoFile = file;
        }
    },

    fields: function() {
        return {
            object: 'organization',
            list: [
                { name: 'name', label: i18next.t('common.name'), value: this.model.get('name'), type: 'text' },
                { name: 'type', label: i18next.t('common.type'), value: this.model.get('type'), options: WiseMetering.organizationTypes, slug: 'slug', type: 'select' },
                { name: 'logo', label: i18next.t('organizations.logo_label'), type: 'file', value: this.model.get('logo_url'), preview: true }
            ]
        };
    },

    getFormData: function() {
        const data = this.formSerializer();
        if (this.logoFile) {
            data.logo = this.logoFile;
        }
        return data;
    },

    onShow: function() {
        this.logoFile = null;
    },

    serializeData: function() {
        let json = this.model.toJSON();
        json.fields = this.fields();
        return json;
    },

    submitData: function(data) {
        let formData = new FormData();
        for (let key in data) {
            formData.append(key, data[key]);
        }

        // https://stopbyte.com/t/how-to-send-multipart-formdata-with-jquery-and-ajax/58/2
        return $.ajax({
            contentType: false,
            data: formData,
            processData: false,
            type: 'PUT',
            url: this.model.url(),
            success: function(response) {
                this.model.set(response);
                Backbone.history.navigate(this.model.url(), { trigger: true });
            }.bind(this),
            error: function(xhr) {
                tierError = JSON.parse(xhr.responseText).errors.tier_id;
                if (tierError) {
                    WiseMetering.layout.showTipper('error', tierError.capitalize());
                }
            }.bind(this)
        });
    },

    cancel: function() {
        Backbone.history.navigate(this.model.url(), { trigger: true });
    }
});
