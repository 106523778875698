module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'opportunity-title\'>' +
((__t = ( i18next.t('glossary.title') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input placeholder=\'Insert title\' class=\'text\' id=\'opportunity-title\' name=\'opportunity[title]\' value=\'' +
((__t = ( title )) == null ? '' : __t) +
'\'>\n        </dd>\n\n        <dt>\n            <label for=\'opportunity-description\'>' +
((__t = ( i18next.t('common.description') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <textarea placeholder=\'Insert description\' class=\'text\' id=\'opportunity-description\' name=\'opportunity[description]\'>' +
((__t = ( description )) == null ? '' : __t) +
'</textarea>\n        </dd>\n\n        <dt>\n            <label for=\'opportunity-utility-kind\'>' +
((__t = ( i18next.t('glossary.utility_kind') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' disabled id=\'opportunity-utility-kind\' name=\'opportunity[utility_kind_id]\'>\n                ';
 availableUtilityKinds.forEach(function (utilityKind) { ;
__p += '\n                    <option ' +
((__t = ( utility_kind_id === utilityKind.get('id') ? 'selected' : '' )) == null ? '' : __t) +
' value="' +
((__t = ( utilityKind.id )) == null ? '' : __t) +
'">' +
((__t = ( utilityKind.get('name') )) == null ? '' : __t) +
'</option>\n                ';
 }) ;
__p += '\n            </select>\n        </dd>\n\n        <dt>\n            <label for=\'opportunity-indicator\'>' +
((__t = ( i18next.t('glossary.indicator') )) == null ? '' : __t) +
' (' +
((__t = ( i18next.t('common.optional') )) == null ? '' : __t) +
')</label>\n        </dt>\n        <dd>\n            <select id=\'opportunity-indicator\' name=\'opportunity[indicator_id]\' class=\'indicators text\'>\n                <option disabled selected value=\'\'>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                ';
 indicators.each(function (indicator) { ;
__p += '\n                    <option ' +
((__t = ( indicator.id === indicator_id ? 'selected' : '' )) == null ? '' : __t) +
' value="' +
((__t = ( indicator.id )) == null ? '' : __t) +
'">\n                        ' +
((__t = ( indicator.get('name') )) == null ? '' : __t) +
'\n                    </option>\n                ';
 }) ;
__p += '\n            </select>\n        </dd>\n\n        ';
 if (completed_at) { ;
__p += '\n            <dt>\n                <label for="completed-at">' +
((__t = ( i18next.t('opportunities.completed_at') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <ul class="ctrl inline dp" style="padding:0">\n                    <li class="field border" style="width:100%">\n                        <input class="text" id="completed-at" maxlength="10" name="opportunity[completed_at]" type="text" value="' +
((__t = ( completed_at )) == null ? '' : __t) +
'">\n                    </li>\n                </ul>\n            </dd>\n        ';
 } ;
__p += '\n\n        <dt>\n            <label for=\'opportunity-priority\'>' +
((__t = ( i18next.t('common.priority') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'opportunity-priority\' name=\'opportunity[priority]\'>\n                <option value=\'1\' ' +
((__t = ( priority === 1 ? 'selected' : null )) == null ? '' : __t) +
'>1</option>\n                <option value=\'2\' ' +
((__t = ( priority === 2 ? 'selected' : null )) == null ? '' : __t) +
'>2</option>\n                <option value=\'3\' ' +
((__t = ( priority === 3 ? 'selected' : null )) == null ? '' : __t) +
'>3</option>\n            </select>\n        </dd>\n\n        <dt>\n            <label for=\'opportunity-consumption\'>' +
((__t = ( i18next.t('opportunities.consumption_savings') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'opportunity-consumption\' name=\'opportunity[consumption]\' value=\'' +
((__t = ( consumption )) == null ? '' : __t) +
'\'>\n        </dd>\n\n        <dt>\n            <label for=\'opportunity-cost\'>' +
((__t = ( i18next.t('opportunities.cost_savings') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'opportunity-cost\' name=\'opportunity[cost]\' value=\'' +
((__t = ( cost )) == null ? '' : __t) +
'\'>\n        </dd>\n\n        <dt>\n            <label for=\'opportunity-capex\'>' +
((__t = ( i18next.t('common.capex') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'opportunity-capex\' name=\'opportunity[capex]\' value=\'' +
((__t = ( capex )) == null ? '' : __t) +
'\'>\n        </dd>\n\n        <dd>\n            ';
 if (file) { ;
__p += '\n                <div class=\'file-link\' id=\'file-link\'>\n                    <span style=\'color: #8BC53F;\'>' +
((__t = ( WiseMetering.icon('download') )) == null ? '' : __t) +
'</span> <a class=\'link\' href=' +
((__t = ( file_link )) == null ? '' : __t) +
' target=\'_blank\'>' +
((__t = ( i18next.t('ui.click_here_to_download_file') )) == null ? '' : __t) +
'</a>\n                    <a href=\'#\' id=\'remove-file\' style=\'color: #224C4F;\'> (' +
((__t = ( i18next.t('common.remove') )) == null ? '' : __t) +
') </a>\n                </div>\n            ';
 } else { ;
__p += '\n                <input class=\'text\' accept=\'.xls, .xlsx, .csv\' id=\'upload-file\' name=\'file\' type=\'file\'/>\n            ';
 } ;
__p += '\n            <span id=\'spinner\' style=\'display: none; color: #224C4F;\'>\n                <i class=\'far fa-spinner fa-spin\'></i>\n                ' +
((__t = ( i18next.t(`ui.${file ? 'removing' : 'uploading'}_file`) )) == null ? '' : __t) +
'\n            </span>\n        </dd>\n\n        ';
 if (capex) { ;
__p += '\n            <div class="impact-analysis-box">\n                <dt>' +
((__t = ( i18next.t('opportunities.payback_period') )) == null ? '' : __t) +
'</dt>\n                <dd class="results">\n                    <div>\n                        <i class="fas fa-spinner fa-spin" aria-hidden="true" style="display:none"></i>\n                        <span id="payback">' +
((__t = ( payback )) == null ? '' : __t) +
'</span>\n                    </div>\n                </dd>\n            </div>\n        ';
 } ;
__p += '\n    </dl>\n</form>\n';

}
return __p
}