WiseMetering.Views.ZoneBenchmark = Backbone.Marionette.ItemView.extend({

    template: 'templates/zones/widgets/benchmark',
    className: 'dashboard full-height',
    widgets: {},

    serializeData: function() {
        return { widgets: this.widgets };
    },

    onBeforeRender: function() {
        if (this.model.activeEnergy() && this.model.get('area')) {
            this.widgets['benchmark'] = new WiseMetering.Views.Ui.Widget.Benchmark({
                el: '#' + generateUUID(), self: this.model.get('name')
            });
        }
    },

    onShow: function() {
        _.each(this.widgets, function(widget) {
            widget.render();
        });
    },

    onClose: function() {
        _.each(this.widgets, function(widget) {
            widget.close();
        });
    }

});
