module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(circuits.length === 0){ ;
__p += '\n    No results found\n';
 } else { ;
__p += '\n    ';
 circuits.forEach(function(circuit){ ;
__p += '\n        <input checked id=\'' +
((__t = ( circuit.id )) == null ? '' : __t) +
'\' name=\'circuit[ids][]\' style=\'margin-left: 0;\' type=\'checkbox\' value=\'' +
((__t = ( circuit.id )) == null ? '' : __t) +
'\'>\n        <label for=\'' +
((__t = ( circuit.id )) == null ? '' : __t) +
'\'>\n            ' +
((__t = ( circuit.name )) == null ? '' : __t) +
'\n            <br>\n        </label>\n    ';
 }) ;
__p += '\n';
 } ;
__p += '\n';

}
return __p
}