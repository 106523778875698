import { Button, Content, ContentHeader, Form, FormLabel, Select } from '@/components/wm';
import * as React from 'react';
import { useMemo, useState } from 'react';

export function Language() {
    const userPreferences = WiseMetering.userPreferences;

    const
            [loading, setLoading] = useState(false),
            [language, setLanguage] = useState(userPreferences.get('language') || 'en');

    const languageOptions = [
        { value: 'en', label: i18next.t('languages.en') },
        { value: 'pt', label: i18next.t('languages.pt') },
        { value: 'es', label: i18next.t('languages.es') }
    ];

    const getChangedAttributes = () => {
        return userPreferences.changedAttributes({ language });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const changed = getChangedAttributes();

        if (!changed) {
            setTimeout(() => {
                setLoading(false);
            }, 500);
            return;
        }

        try {
            await userPreferences.save(changed, { wait: true, patch: true });

            WiseMetering.layout.showTipper('success', i18next.t('ui.successfully_saved'));

            window.location.reload();

        } catch (err) {
            const errors = err.responseJSON.errors || null;
            let errorMessage = '';

            if (errors) {
                Object.keys(errors).forEach((key) => {
                    errorMessage += `${key}: ${errors[key]} <br>`;
                });
            } else {
                errorMessage = 'An error ocurred';
            }


            WiseMetering.layout.showTipper('error', errorMessage);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    const hasChanges = useMemo(() => {
        return language !== userPreferences.get('language');
    }, [language]);

    return (
            <Content>
                <ContentHeader icon="globe" title={i18next.t('common.language').capitalize()}/>
                <Form>
                    <FormLabel htmlFor="language">{i18next.t('common.language')}</FormLabel>
                    <Select
                            options={languageOptions}
                            value={language}
                            onChange={(value) => setLanguage(value)}
                    />
                    <div className="tw-mt-6">
                        <Button type="submit" loading={loading} className="tw-w-fit" onClick={handleSubmit} disabled={!hasChanges}>
                            {i18next.t('ui.buttons.save')}
                        </Button>
                    </div>
                </Form>
            </Content>
    );
}
