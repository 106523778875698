WiseMetering.Views.EditUser = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'user',
    input_readers: [{ type: 'checkbox', func: function(el) { return el.prop('checked'); } }],
    template: 'templates/users/edit',
    title: () => i18next.t('modal.title.users.edit'),

    serializeData: function() {
        let json = this.model.toJSON();
        json.myProfile = this.model.isLoggedInUser();
        return json;
    }
});
