WiseMetering.Views.NewBuilding = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/zones/new_building',
    className: 'mbx-info text-left',
    form_prefix: 'zone',
    title: 'New Building',
    input_readers: [{
        type: 'default',
        func: function(el) {
            return el.val() === '' ? null : el.val();
        }
    }],
    width: '75%',
    map: null,
    marker: null,
    defaultZoom: 2,

    ui: {
        imagePreviewImage: '.preview-building-image'
    },

    events: {
        'change .input-image': 'onChangeImage',
        'keyup #address': 'search'
    },

    getFormData: function() {
        const
            data = this.formSerializer(),
            tag_ids = [];

        if (data.description == null) {
            delete data['description'];
        }
        if (this.imageFile) {
            data.image = this.imageFile;
        }
        if (this.removeImage) {
            data.image = '';
        }
        Object.entries(data.tag_ids).forEach(([key, value]) => {
            if (value) {
                tag_ids.push(key);
            }
        });
        data.tag_ids = tag_ids;
        data.type = 'building';
        return data;
    },

    serializeData: function() {
        return Object.assign(
            {
                address: {
                    street: ''
                }
            },
            this.model.toJSON()
        );
    },

    onChangeImage: function(event) {
        const [file] = event.target.files;
        if (file) {
            this.ui.imagePreviewImage.attr('src', URL.createObjectURL(file));
            this.imageFile = file;
        }
    },

    onRender: function() {
        this.$('select#zone_category_id').append(
            WiseMetering.utils.optionsFromModels(
                WiseMetering.zoneCategories.where({ building: true }),
                'name',
                this.model.get('category_id')
            )
        );
    },

    onShow: function() {
        this.mapOptions = {
            zoom: this.defaultZoom,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControlOptions: { mapTypeIds: [] }
        };
        const center = new google.maps.LatLng(39.980205, -7.905591);
        this.createMap(center);
    },

    createMap: function(center) {
        var that = this;
        that.map = new google.maps.Map(document.getElementById('map_canvas'), that.mapOptions);
        google.maps.event.addListener(that.map, 'zoom_changed', function() {
            that.mapOptions.zoom = that.map.getZoom();
        });
        that.map.setCenter(center);
        that.marker = new google.maps.Marker({
            map: that.map,
            position: center,
            draggable: true
        });
        that.setLatLng(that.marker.getPosition());
        google.maps.event.addListener(that.marker, 'dragend', function() {
            center = that.marker.getPosition();
            that.map.setCenter(center);
            that.setLatLng(center);
        });
    },

    setLatLng: function(position) {
        this.$('input#zone_latitude').val(position.lat());
        this.$('input#zone_longitude').val(position.lng());
    },

    codeAddress: function() {
        var that = this;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': document.getElementById('address').value }, function(results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                if (that.mapOptions.zoom == that.defaultZoom) {
                    that.mapOptions.zoom = 15;
                }
                var position = results[0].geometry.location;
                that.createMap(position);
            } else {
                alert('Geocode was not successful for the following reason: ' + status);
            }
        });
    },

    search: function(event) {
        if ((event.keyCode || event.which) === 13) {
            event.stopPropagation();
            event.preventDefault();
            this.codeAddress();
        }
    },

    submitData: function(data) {
        let formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                if (value.length > 0) {
                    value.forEach(item => formData.append(`${key}[]`, item));
                } else {
                    formData.append(`${key}[]`, []);
                }
            } else {
                formData.append(key, value);
            }
        });

        // https://stopbyte.com/t/how-to-send-multipart-formdata-with-jquery-and-ajax/58/2
        return $.ajax({
            contentType: false,
            data: formData,
            processData: false,
            type: 'POST',
            url: this.model.urlRoot
        });
    },

    afterSave: function() {
        $.when(WiseMetering.indicators.fetch()).done(function() {
            WiseMetering.folders.fetch();
        });
    }
});
