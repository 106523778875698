import { cn } from '@/utils';
import * as React from 'react';

const TextArea = React.forwardRef(({ className, type = 'text', ...props }, ref) => {
    return (
            <textarea
                    type={type}
                    className={cn(
                            'tw-flex tw-min-h-[80px] tw-w-full tw-rounded-md tw-border tw-border-input tw-bg-background tw-px-3 tw-py-2 tw-text-sm tw-ring-offset-background placeholder:tw-text-muted-foreground focus:tw-border focus:tw-border-input focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-cursor-not-allowed disabled:tw-opacity-50',
                            className
                    )}
                    ref={ref}
                    {...props}
            />
    );
});
TextArea.displayName = 'TextArea';

export { TextArea };
