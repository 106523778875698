WiseMetering.Views.ZoneEditTariffs = WiseMetering.Views.Modal.Save.extend({

    template: 'templates/zones/tariffs/edit',
    className: 'mbx-info text-left',
    form_prefix: 'zone',
    title: 'Edit Zone',
    input_readers: [{
        type: 'default',
        func: function(el) { return el.val() === '' ? null : el.val(); }
    }],

    onRender: function() {
        const zoneTimezone = this.model.get('timezone') || WiseMetering.zones.find({ id: this.model.get('building_id') }).get('timezone');
        this.model.availableUtilityKinds().forEach(utility_kind => {
            const shortName = utility_kind.shortName();
            const tariff = this.model.tariff(utility_kind.get('name'));
            const options = WiseMetering.utils.optionsFromModels(WiseMetering.tariffs.byUtilityKindTimezone(utility_kind.get('name'), zoneTimezone), 'name', tariff?.id);
            this.$(`select#zone_${shortName}_tariff_id`).append(options);
        });
    },

    serializeData: function() {
        let json = this.model.toJSON();
        json.utility_kinds = this.model.availableUtilityKinds();
        return json;
    }
});
