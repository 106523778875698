import { Checkbox as ShadCheckbox } from '@/components/ui/checkbox';
import { cn } from '@/utils';
import * as React from 'react';

const Checkbox = React.forwardRef(
        ({ className, id, label, labelClassName, ...props }, ref) => {
            return (
                    <div className={cn('tw-flex tw-items-center tw-space-x-2', className)}>
                        <ShadCheckbox id={id} ref={ref} {...props} />
                        <label
                                className={cn(
                                        'tw-text-sm tw-text-foreground',
                                        labelClassName
                                )}
                                htmlFor={id}
                        >
                            {label}
                        </label>
                    </div>
            );
        }
);

Checkbox.displayName = 'Checkbox';

export { Checkbox };
