WiseMetering.Views.SitePackageEdit = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/site_packages/edit',
    className: 'mbx-info text-left',
    form_prefix: 'building_package',
    title: () => i18next.t('modal.title.site_packages.edit'),
    input_readers: [{
        type: 'default',
        func: function(el) {
            return el.val() === '' ? null : el.val();
        }
    }],

    serializeData: function() {
        let json = this.model.toJSON(),
            sitePackage = WiseMetering.sitePackages.findWhere({ building_id: this.model.id }),
            pack = sitePackage ? WiseMetering.packages.get(sitePackage.get('package_id')) : null;

        json.packages = this.getPackages();
        json.form_prefix = this.form_prefix;
        json.packageName = pack ? pack.get('name') : null;
        json.packageId = pack ? pack.id : null;
        json.devices_extra_limit = sitePackage ? sitePackage.get('devices_extra_limit') : 0;
        json.wiseboxes_extra_limit = sitePackage ? sitePackage.get('wiseboxes_extra_limit') : 0;
        json.isTierEssentials = WiseMetering.currentOrganization.is('essentials');
        return json;
    },

    getIndustryPackages: function(orgType) {
        return WiseMetering.packages.filter(function(model) {
            return model.get('name') !== 'micro' && model.get(orgType) && model.get('active');
        });
    },

    getDefaultPackages: function(orgType) {
        return WiseMetering.packages.where({ [orgType]: true, active: true });
    },

    getPackages: function() {
        let packagesArray;
        const orgType = WiseMetering.currentOrganization.get('type');

        if (WiseMetering.currentOrganization.is('essentials')) {
            packagesArray = WiseMetering.packages.where({ name: 'micro', [orgType]: true, active: true });
        } else if (orgType === 'industry') {
            packagesArray = this.getIndustryPackages(orgType);
        } else {
            packagesArray = this.getDefaultPackages(orgType);
        }

        this.packages = new WiseMetering.Collection(packagesArray);
        return this.sortPackages();
    },

    sortPackages: function() {
        this.packages.comparator = function(m) {
            switch (m.get('name')) {
                case 'micro':
                    return 1;
                case 'basic':
                    return 2;
                case 'x-small':
                    return 2;
                case 'small':
                    return 3;
                case 'full':
                    return 4;
                case 'medium':
                    return 5;
                case 'large':
                    return 6;
                case 'x-large':
                    return 7;
                default:
                    return Infinity;
            }
        };
        return this.packages.sort();
    },

    submitData: function(data) {
        return this.model.save(data, {
            url: `${this.model.buildingUrlRoot}/${this.model.id}/actions/change_package`,
            wait: true,
            patch: true,
            silent: true,
            success: function() {
                WiseMetering.sitePackages.fetch().done(function() {
                    this.model.trigger('change', this.model);
                }.bind(this));
            }.bind(this),
            error: function() {
                WiseMetering.layout.showTipper('error', 'Please contact your account manager to add/upgrade a package.', 2000);
            }.bind(this)
        });
    }
});
