WiseMetering.Views.Modal.Show = Backbone.Marionette.ItemView.extend({
    buttons: ['close'],

    events: {
        'click a': 'closeModal'
    },

    closeModal: function(event) {
        if (event.currentTarget.href) {
            this.modal.close();
        }
    },

    initialize: function(attributes) {
        $.extend(this, attributes);

        this.modal = new WiseMetering.Views.Modal.Base({
            buttons: this.buttons,
            closable: this.closable,
            draggable: this.draggable,
            edit: this.edit,
            activate: this.activate,
            discard: this.discard,
            mode: this.mode,
            model: this.model,
            ok: this.ok,
            open: this.open,
            reset: this.reset,
            showActivationButton: this.showActivationButton,
            showDiscardButton: this.showDiscardButton,
            title: this.title,
            width: this.width,
            yes: this.yes
        });

        WiseMetering.modalBox.show(this.modal);
        this.modal.body.show(this);
    }
});
