WiseMetering.Views.NoAccess = Backbone.Marionette.Layout.extend({
    template: 'templates/ui/no_access/no_access',
    regions: {
        noAccess: '.no-access',
        tiers: '.tier-accordion'
    },

    onRender: function() {
        this.noAccess.show(new WiseMetering.Views.NoAccessMessage);
        this.tiers.show(new WiseMetering.Views.OrganizationTiersAccordion({ model: WiseMetering.currentOrganization }));
    }
});
WiseMetering.currentUser;
