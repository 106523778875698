WiseMetering.Views.ScheduledReportsIndex = Backbone.Marionette.ItemView.extend({
    frequencies: { day: 'Daily', week: 'Weekly', month: 'Monthly', quarter: 'Quarterly', year: 'Yearly' },
    template: 'templates/ui/layout/empty',

    createTable() {
        const
            building_id = this.model ? this.model.id : null,
            collection = new WiseMetering.Collection.ScheduledReports(WiseMetering.scheduledReports.where({ building_id }));

        this.table = new WiseMetering.Views.Table({
            id: 'scheduled-reports',
            collection,
            modelSerializer: model => {
                const json = model.toJSON();
                let creator = WiseMetering.users.get(json.created_by);

                return {
                    ...json,
                    name: `${json.name} (${json.language})`,
                    created_at: WiseMetering.utils.formatDate(model.get('created_at')),
                    created_by: creator ? creator.get('name') : 'System',
                    frequency: this.frequencies[model.get('frequency')],
                    notifications: model.notifications()
                };
            },
            columns: {
                name: {
                    title: i18next.t('common.name'),
                    width: '25%'
                },
                frequency: {
                    title: i18next.t('common.frequency'),
                    className: 'center',
                    width: '10%'
                },
                notifications: {
                    title: i18next.t('common.notifications'),
                    width: '35%'
                },
                created_by: {
                    title: i18next.t('glossary.created_by'),
                    className: 'center',
                    width: '15%'
                },
                created_at: {
                    title: i18next.t('glossary.created_at'),
                    className: 'center',
                    width: '15%'
                }
            },
            toolbar_actions: {
                select_all: {},
                new: {
                    view_call: () => {
                        return new WiseMetering.Views.NewScheduledReport({
                            buildingId: building_id,
                            collection: WiseMetering.scheduledReports,
                            model: new WiseMetering.Model.ScheduledReport(),
                            title: i18next.t('modal.title.scheduled_reports.new')
                        });
                    }
                },
                show: {
                    view_call: model => new WiseMetering.Views.EditScheduledReport({ model })
                },
                edit: {
                    view_call: model => new WiseMetering.Views.EditScheduledReport({ model })
                },
                delete_all: {}
            }
        });

        $(this.el).html(this.table.el);
    },

    onClose() {
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
        this.stopListening(WiseMetering.scheduledReports);
    },

    onShow() {
        this.listenTo(WiseMetering.scheduledReports, 'add change', this.createTable.bind(this));
        this.createTable();
    }
});
