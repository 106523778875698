import { Container } from '@/components/wm';
import { Partner } from '@/pages/WiseStore/Content/partner';
import * as React from 'react';

export default function Wisestore({ integrationPartners, licenses, organizationId }) {
    const license = (partnerId) => {
        return licenses.find(license => license.partner_id === partnerId)
    };

    return (
        <Container variant="full">
            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-4">
                {integrationPartners.map((partner) => (
                    <Partner key={partner.id} partner={partner} license={license(partner.id)} organizationId={organizationId}/>
                ))}
            </div>
        </Container>
    );
}
