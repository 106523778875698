import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/Dialog';
import { Button } from '@/components/wm';
import React, { cloneElement, isValidElement, useState } from 'react';

const ModalSave = ({ description, model, onClose, mode = 'edit', children, title }) => {
    const [disabledButton, setDisabledButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(
            mode === 'edit' ? model : {} // Initialize empty for create mode
    );

    const initializeFormData = () => {
        React.Children.forEach(children, (child) => {
            if (isValidElement(child)) {
                const { property, value } = child.props;
                if (property && value !== undefined) {
                    setFormData((prev) => ({ ...prev, [property]: value }));
                }
            }
        });
    };

    React.useEffect(() => {
        initializeFormData();
    }, []);

    const handleInputChange = (key, value) => {
        setFormData((prev) => ({ ...prev, [key]: value }));
    };

    const handleSave = async (e) => {
        e.preventDefault();

        const
                backboneModel = mode === 'edit' ? WiseMetering.integrationLicenses.get(model.id) : new WiseMetering.Model.IntegrationLicense(),
                changedAttributes = backboneModel.changedAttributes(formData);

        if (!changedAttributes) {
            setTimeout(() => {
                setLoading(false);
            }, 500);
            return;
        }

        setLoading(true);

        try {
            await backboneModel.addLicenses(changedAttributes, { wait: true, patch: true });

            if (mode === 'create') {
                WiseMetering.integrationLicenses.add(backboneModel);
            }

            WiseMetering.layout.showTipper('success', i18next.t('ui.successfully_saved'));
            onClose();
        } catch (err) {
            console.error(err);

            const errors = err.responseJSON.errors || null;
            let errorMessage = '';

            if (errors) {
                Object.keys(errors).forEach((key) => {
                    errorMessage += `${key}: ${errors[key]} <br>`;
                });
            } else {
                errorMessage = 'An error occurred';
            }

            WiseMetering.layout.showTipper('error', errorMessage);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    if (!title) {
        title = mode === 'create' ? 'Create' : 'Update';
    }
    if (!description) {
        description = mode === 'create' ? 'Fill out the details to create a new entry.' : 'Update the details below.';
    }

    return (
            <Dialog open={true} onOpenChange={onClose}>
                <DialogContent className="tailwind-container">
                    <DialogHeader>
                        <DialogTitle>
                            {title}
                        </DialogTitle>
                        <DialogDescription>
                            {description}
                        </DialogDescription>
                    </DialogHeader>
                    <div className="tw-space-y-4">
                        {React.Children.map(children, child => {
                            if (isValidElement(child)) {
                                const props = child.props, key = props.property;

                                return cloneElement(child, {
                                    dataType: props.dataType,
                                    value: props.value || formData[key] || '',
                                    onChange: (value) => handleInputChange(key, value),
                                    type: props.type
                                });
                            }
                            return child;
                        })}
                    </div>
                    <DialogFooter>
                        <Button variant="ghost" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button onClick={handleSave} disabled={disabledButton} loading={loading}>
                            {mode === 'create' ? 'Create' : 'Save'}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
    );
};

export { ModalSave };
