WiseMetering.Views.Indicator = WiseMetering.Views.Content.extend({
    icon: 'indicators',

    initialize({ model, view }) {
        this.tabs = [
            {
                active: view === 'details',
                href: `/indicators/${model.id}/details`,
                id: 'details',
                name: i18next.t('common.details'),
                view: WiseMetering.Views.IndicatorDetailsTab
            },
            {
                active: view === 'plot',
                href: `/indicators/${model.id}/plot`,
                id: 'plot',
                name: i18next.t('common.plot'),
                view: WiseMetering.Views.IndicatorDashboard
            }
        ];

        if (this.model.utilityKind()) {
            this.tabs.push({
                active: view === 'objectives',
                href: `/indicators/${model.id}/objectives`,
                id: 'objectives',
                name: i18next.t('glossary.objectives'),
                view: WiseMetering.Views.IndicatorObjectives
            });
        }

        if (this.model.get('metals') && WiseMetering.currentUser.get('manager') && this.model.get('type') !== 'mirror') {
            this.tabs.push({
                active: view === 'data',
                href: `/indicators/${model.id}/data`,
                id: 'data',
                name: i18next.t('common.data'),
                view: WiseMetering.Views.IndicatorMetals
            });
        }
    }
});
