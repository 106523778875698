WiseMetering.Views.Ui.Widget.CarouselPie = WiseMetering.Views.Ui.Widget.Base.extend({
    templateID: 'templates/ui/widgets/carousel',
    colors: WiseMetering.WidgetColors.MultiColor,
    carouselWidgets: [],
    currentIndex: 0,
    events: {
        'click #button-prev': 'prevUtility',
        'click #button-next': 'nextUtility'
    },

    build: function() {
        this.drawCharts();
        this.renderWidgets();
    },

    buildBarChart: function(options) {
        const
            data = options.data,
            height = options.height - 50,
            title = options.subtitle;

        if (data.length) {
            const widget = new WiseMetering.Views.Ui.Widget.BarChart({ el: '#charts', data, useNormalization: false, title, height, unit: 'Wh', gridSize: 'carousel' });
            this.carouselWidgets.push(widget);
        }
    },

    buildPieChart: function(options) {
        const
            data = options.data,
            height = options.height - 50,
            title = options.subtitle;

        if (data.length) {
            const widget = new WiseMetering.Views.Ui.Widget.PieChart({ el: '#charts', data, useArea: false, title, height, unit: 'Wh', gridSize: 'carousel' });
            this.carouselWidgets.push(widget);
        }
    },

    close: function() {
        if (this.chart && this.chart.destroy && typeof (this.chart.destroy) === 'function') {
            this.chart.destroy();
        }
        this.$el.remove();
    },

    drawCharts: function() {
        this.chartsData.forEach(chartData => {
            switch (chartData.type) {
                case 'pie':
                    this.buildPieChart(chartData);
                    break;
                case 'bar':
                    this.buildBarChart(chartData);
                    break;
                default:
                    console.error('Unknown widget type for carousel');
            }
        });
    },

    nextUtility: function() {
        this.currentIndex = (this.currentIndex + 1) % this.$('#charts.carousel-wrapper').children().length;
        this.showSlide(this.currentIndex);
    },

    prevUtility: function() {
        this.currentIndex = (this.currentIndex - 1 + this.$('#charts.carousel-wrapper').children().length) % this.$('#charts.carousel-wrapper').children().length;
        this.showSlide(this.currentIndex);
    },

    renderWidgets: function() {
        this.carouselWidgets.forEach(widget => {
            widget.render(false);
            widget.build();
            widget.delegateEvents();
        });

        // const carousel_items = this.$('.carousel-item');
        const carousel_items = this.$('#charts.carousel-wrapper').children();
        if (carousel_items.length === 1) {
            this.$('.carousel-button').hide();
        }
    },

    showSlide: function(index) {
        const wrapper = this.$('.carousel-wrapper')[0];
        const itemWidth = this.$('#charts.carousel-wrapper').children()[0].offsetWidth;
        wrapper.style.transform = `translateX(-${index * itemWidth}px)`;
    }
});

