WiseMetering.Model.Event = WiseMetering.Model.extend({

    urlRoot: '/events',

    building: function() {
        return WiseMetering.zones.get(this.get('building_id'));
    },

    indicator: function() {
        return WiseMetering.indicators.get(this.get('indicator_id'));
    },

    alarm: function() {
        return WiseMetering.alarms.get(this.get('alarm_id'));
    },

    notifications: function() {
        var notifications = this.trigger() ? this.trigger() : 'N/A';
        if (notifications)
            return _.select(this.get('notifications'), function(n) {
                return WiseMetering.users.get(n.recipient_id);
            }).map(function(n) { return WiseMetering.users.get(n.recipient_id).get('name'); }).join(', ');
        else return null;
    }
});
