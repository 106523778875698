WiseMetering.Views.AddAllocation = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    template: 'templates/allocations/add',
    form_prefix: 'allocation',
    title: () => i18next.t('modal.title.allocations.new'),

    ui: {
        from: '#allocation_from'
    },

    events: {
        'change #allocation_utility_kind': 'onChangeUtilityKind'
    },

    serializeData: function() {
        let json = this.model.toJSON();
        json.from = this.model.getDate('from');
        json.utilityKinds = WiseMetering.utilityKinds;
        json.indicators = this.options.indicators;
        json.tariffs = this.options.tariffs;
        json.selectedUtilityKind = this.selectedUtilityKind;
        return json;
    },

    onRender: function() {
        this.ui.from.datepicker({
            dateFormat: 'yy-mm-dd',
            changeDay: true,
            changeMonth: true,
            changeYear: true
        });
    },

    onChangeUtilityKind: function(event) {
        event.preventDefault();

        this.model.set(this.formSerializer());
        this.selectedUtilityKind = this.$('.utility_kinds option:selected').val();
        this.updateIndicators();
        this.updateTariffs();
        this.render();
    },

    afterSave: function() {
        this.options.allocations.add(this.model);
    },

    updateIndicators: function() {
        let models = this.options.tenant.indicatorsByUtilityKind(this.selectedUtilityKind);
        this.options.indicators.reset();
        this.options.indicators.add(models);
    },

    updateTariffs: function() {
        let models = WiseMetering.tariffs.byUtilityKind(this.selectedUtilityKind);
        this.options.tariffs.reset();
        this.options.tariffs.add(models);
    }
});
