WiseMetering.MapStyles = [
    {
        featureType: 'administrative',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on'
            },
            {
                saturation: -100
            },
            {
                lightness: 20
            }
        ]
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'all',
        stylers: [
            {
                visibility: 'simplified'
            },
            {
                saturation: -60
            },
            {
                lightness: 10
            }
        ]
    },
    {
        featureType: 'landscape.natural',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on'
            },
            {
                saturation: -60
            },
            {
                lightness: 60
            },
            {
                color: '#e9e7e4'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off'
            },
            {
                saturation: -100
            },
            {
                lightness: 60
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'labels.text',
        stylers: [
            {
                color: '#a3a3a3'
            },
            {
                visibility: 'simplified'
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'on'
            },
            {
                color: '#d5e09d'
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'on'
            },
            {
                color: '#6f9543'
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
            {
                visibility: 'on'
            },
            {
                color: '#6f9543'
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'off'
            },
            {
                color: '#ff0000'
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'simplified'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on'
            },
            {
                saturation: -100
            },
            {
                lightness: 40
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
            {
                weight: '1.00'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on'
            },
            {
                color: '#ffffff'
            },
            {
                weight: '2.21'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                'invert_lightness': true
            },
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.text',
        stylers: [
            {
                color: '#a7a9ac'
            },
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'on'
            },
            {
                color: '#fffefe'
            }
        ]
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'road.local',
        elementType: 'geometry.stroke',
        stylers: [
            {
                visibility: 'on'
            },
            {
                color: '#d9d7d6'
            }
        ]
    },
    {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off'
            },
            {
                saturation: -100
            },
            {
                lightness: 60
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on'
            },
            {
                saturation: -10
            },
            {
                lightness: 30
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#c3e8f3'
            },
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'geometry.stroke',
        stylers: [
            {
                weight: '8.21'
            },
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'simplified'
            },
            {
                color: '#f4f3f3'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
            {
                lightness: '73'
            },
            {
                saturation: '0'
            },
            {
                gamma: '1'
            },
            {
                color: '#cdf2f7'
            },
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'on'
            },
            {
                color: '#7db3ba'
            },
            {
                weight: '3.49'
            },
            {
                lightness: '0'
            },
            {
                gamma: '1'
            }
        ]
    }
];
