module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'etl_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'etl_name\' name=\'etl[name]\' value=\'' +
((__t = ( name )) == null ? '' : __t) +
'\'>\n        </dd>\n        <dt>\n            <label for=\'etl_dsn\'>' +
((__t = ( i18next.t('etls.database_dsn') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'etl_dsn\' name=\'etl[dsn]\' placeholder=\'' +
((__t = ( i18next.t('etls.database_dsn_placeholder') )) == null ? '' : __t) +
'\' value=\'' +
((__t = ( dsn )) == null ? '' : __t) +
'\'>\n        </dd>\n        <dt>\n            <label for=\'etl_username\'>' +
((__t = ( i18next.t('etls.connection_username') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'etl_username\' name=\'etl[username]\' value=\'' +
((__t = ( username || null )) == null ? '' : __t) +
'\'>\n        </dd>\n        <dt>\n            <label for=\'etl_secret\'>' +
((__t = ( i18next.t('etls.connection_secret') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'etl_secret\' name=\'etl[secret]\'>\n        </dd>\n        <dt>\n            <label for=\'etl_rate\'>' +
((__t = ( i18next.t('glossary.rate') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'etl_rate\' name=\'etl[rate]\'>\n                ';
 for (const [key, value] of Object.entries(etl_rates)) { ;
__p += '\n                    <option value=\'' +
((__t = ( key )) == null ? '' : __t) +
'\' ' +
((__t = ( Number(key) === rate ? 'selected' : '' )) == null ? '' : __t) +
'>' +
((__t = ( value )) == null ? '' : __t) +
'</option>\n                ';
 } ;
__p += '\n            </select>\n        </dd>\n        <dt>\n            <label for="etl_mode">' +
((__t = ( i18next.t('common.mode') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'etl_mode\' name=\'etl[mode]\'>\n                <option ' +
((__t = ( mode === 'local' ? 'selected' : '' )) == null ? '' : __t) +
' value=\'local\'>' +
((__t = ( i18next.t('etls.local_mode') )) == null ? '' : __t) +
'</option>\n                <option ' +
((__t = ( mode === 'remote' ? 'selected' : '' )) == null ? '' : __t) +
' value=\'remote\'>' +
((__t = ( i18next.t('etls.remote_mode') )) == null ? '' : __t) +
'</option>\n            </select>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}