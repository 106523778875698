WiseMetering.Views.ZoneCosts = Backbone.Marionette.ItemView.extend({

    template: 'templates/zones/widgets/costs',
    className: 'dashboard full-height',
    widgets: {},

    serializeData: function() {
        return { widgets: this.widgets };
    },

    onBeforeRender: function() {
        if (this.model.activeEnergy() && this.model.get('tariff_id')) {
            this.widgets['costType'] = new WiseMetering.Views.Ui.Widget.Cost({ el: '#' + generateUUID(), type: 'type' });
            this.widgets['costPeriod'] = new WiseMetering.Views.Ui.Widget.Cost({ el: '#' + generateUUID(), type: 'period' });
        }
    },

    onShow: function() {
        _.each(this.widgets, function(widget) { widget.render(); });
    },

    onClose: function() {
        _.each(this.widgets, function(widget) { widget.close(); });
    }

});
