WiseMetering.Views.EditMarker = WiseMetering.Views.Modal.Save.extend({
    DAY_RATE: 24 * 3600,
    WEEK_RATE: 7 * 24 * 3600,
    MONTH_RATE: 30 * 24 * 3600,
    className: 'mbx-info text-left',
    form_prefix: 'event',
    template: 'templates/markers/edit',
    title: () => i18next.t('modal.title.markers.edit'),
    ui: {
        calculatedResults: '#calculated-results'
    },

    events: {
        'change select#event-reference-period': 'onChangedImpactPeriod',
        'click input#one-day': 'onMarkerPeriodChanged',
        'click input#one-week': 'onMarkerPeriodChanged',
        'click input#one-month': 'onMarkerPeriodChanged'
    },

    afterSave: function() {
        WiseMetering.markers.fetch();
    },

    calculateImpact: function() {
        this.$('.fa-spinner').show();
        this.ui.calculatedResults.hide();
        this.removeTemporaryErrorNotifications();
        this.model.getCalculatedImpact(this.getFormData(), this.form_prefix);
    },

    changeImpactPeriodStartDate: function() {
        const
            customDataInput = this.$('input#event-custom-reference-period-start'),
            impactPeriod = this.$('select#event-reference-period').val();

        customDataInput.prop('disabled', impactPeriod);
        if (impactPeriod) {
            this.updateImpactPeriodStartDate();
        }
    },

    getFormData: function() {
        const data = this.formSerializer(),

            attributes = {
                name: data['name'],
                description: data['description'],
                marker_at: data['marker_at'],
                marker_type_id: parseInt(data['marker_type_id'], 10),
                duration: data['duration'],
                reference_period_start: data['reference_period_start']
            };

        return Object.fromEntries(Object.entries(attributes).filter(([_, v]) => !!v));

    },

    getImpactPeriod: function() {
        let impactPeriod = '';
        const from = this.model.get('marker_at'),
            reference = this.model.get('reference_period_start');

        [this.DAY_RATE, this.WEEK_RATE, this.MONTH_RATE].forEach(function(rate) {
            if (Math.abs(rate - (from - reference)) <= 3600) {
                impactPeriod = rate;
            }
        });
        return impactPeriod;
    },

    getReferencePeriodOptions: function() {
        let options = [];
        if (this.model.get('duration') === this.DAY_RATE) {
            options = options.concat([
                [i18next.t('markers.compare_with_yesterday'), this.DAY_RATE],
                [i18next.t('markers.compare_with_last_week'), this.WEEK_RATE]
            ]);
        } else if (this.model.get('duration') === this.WEEK_RATE) {
            options = options.concat([
                [i18next.t('markers.compare_with_last_week'), this.WEEK_RATE]
            ]);
        }

        return options.concat([
            [i18next.t('markers.compare_with_last_month'), this.MONTH_RATE],
            [i18next.t('markers.compare_with_custom'), '']
        ]);
    },

    getReferenceStartAtDate: function(eventAt, duration) {
        if (duration === this.DAY_RATE) {
            return eventAt.add(-1, 'day').format('YYYY-MM-DD HH:mm');
        } else if (duration === this.WEEK_RATE) {
            return eventAt.add(-1, 'week').format('YYYY-MM-DD HH:mm');
        } else if (duration === this.MONTH_RATE) {
            return eventAt.add(-30, 'day').format('YYYY-MM-DD HH:mm');
        }
    },

    onChangedImpactPeriod: function() {
        this.changeImpactPeriodStartDate();
    },

    onMarkerAtClicked: function(options) {
        const eventAtDateEl = $('input#event-at-date');

        let predefined = {
            ...this.timeFormat(),
            changeMonth: true,
            changeYear: true,
            dateFormat: 'yy-mm-dd',
            maxDate: moment().endOf('day').toDate(),
            minDate: moment().add(-2, 'years').startOf('year').toDate(),
            onSelect: function(dateText) {
                eventAtDateEl.val(dateText);
                this.changeImpactPeriodStartDate();
            }.bind(this),
            showOtherMonths: true
        };

        eventAtDateEl.datetimepicker('destroy');
        eventAtDateEl.datetimepicker(Object.assign({}, predefined, options));
    },

    onMarkerPeriodChanged: function(event) {
        $('input#event-duration').val(event.currentTarget.value);
        this.onMarkerAtClicked();
        if (this.model.get('reference_period_start')) {
            this.changeImpactPeriodStartDate();
        }
    },

    onRender: function() {
        if (this.model.get('reference_period_start')) {
            const referencePeriodSelect = this.$('select#event-reference-period'),
                impactPeriod = this.getImpactPeriod();

            this.getReferencePeriodOptions().forEach(function(option) {
                const attributes = { value: option[1] };
                if (impactPeriod === option[1]) {
                    attributes['selected'] = 'selected';
                }
                referencePeriodSelect.append($('<option>').attr(attributes).html(option[0]));
            }.bind(this));

            this.setCurrentReferenceDate();
        }
    },

    onShow: function() {
        this.onMarkerAtClicked();
        if (this.model.get('reference_period_start')) {
            this.setReferencePeriodDatePicker();
        }
    },

    removeTemporaryErrorNotifications: function() {
        this.$('label').removeClass('err');
        this.$('label').find('.err-help').remove().unbind('mouseover mouseout');
        $(this.modal.errors.el).removeClass('active').empty();
    },

    serializeData: function() {
        const
            json = this.model.toJSON(),
            markerAt = this.model.get('marker_at'),
            timezone = this.model.building().get('timezone');

        json.marker_at = WiseMetering.utils.formatDate(markerAt, timezone, 'YYYY-MM-DD HH:mm');

        let results = this.model.results();
        if (results) {
            results = results.replace('\n', '<br/>');
        }
        json.results = results;

        return json;
    },

    setCurrentReferenceDate: function() {
        const
            referenceStart = this.model.get('reference_period_start'),
            timezone = this.model.building().get('timezone'),
            referenceDate = WiseMetering.utils.formatDate(referenceStart, timezone, 'YYYY-MM-DD HH:mm');

        this.updateReferenceDate(referenceDate);
    },

    setReferencePeriodDatePicker: function() {
        const
            eventDuration = parseInt(this.$('input#event-duration').val(), 10),
            impactPeriod = this.$('select#event-reference-period').val(),
            customDataInput = this.$('input#event-custom-reference-period-start');

        customDataInput.prop('disabled', impactPeriod);
        customDataInput.datetimepicker('destroy');
        customDataInput.datetimepicker({
            dateFormat: 'yy-mm-dd',
            changeHour: eventDuration === this.DAY_RATE,
            changeMonth: true,
            changeYear: true,
            showOtherMonths: true,
            timeFormat: eventDuration === this.DAY_RATE ? 'HH:00' : '00:00',
            minDate: moment().add(-2, 'years').startOf('year').add(1, 'day').toDate(),
            maxDate: moment().endOf('day').toDate(),
            onSelect: function(dateText) {
                this.updateReferenceDate(dateText);
            }.bind(this)
        });
    },

    timeFormat: function() {
        if (!this.model.get('duration')) {
            return {
                changeMinute: true,
                stepMinute: 15,
                timeFormat: 'HH:mm'
            };
        } else if (this.model.get('duration') === this.DAY_RATE) {
            return {
                changeMinute: false,
                timeFormat: 'HH:00'
            };
        } else {
            return {
                changeHour: false,
                changeMinute: false,
                timeFormat: '00:00'
            };
        }
    },

    updateImpactPeriodStartDate: function() {
        const
            markerAt = this.$('input#event-at-date').val(),
            impactPeriod = this.$('select#event-reference-period').val(),
            referenceDate = this.getReferenceStartAtDate(moment(markerAt), parseInt(impactPeriod, 10));

        this.updateReferenceDate(referenceDate);
    },

    updateReferenceDate: function(date) {
        this.$('input#event-custom-reference-period-start').val(date);
        this.$('input#event-reference-period-start-value').val(date);
        this.calculateImpact();
    }
});
