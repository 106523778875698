WiseMetering.Views.DatelkaConfigurationsTable = WiseMetering.Views.TableIndex.extend({
    icon: 'integrations',
    name: () => 'datelka',

    createTable: function() {
        this.collection = this.model.configurations();

        this.table = new WiseMetering.Views.Table({
            id: 'configurations',
            collection: this.collection,
            modelSerializer: model => {
                let
                    color = 'orange',
                    state = model.get('state');

                if (state === 'active') {
                    color = 'green';
                }

                return {
                    id: model.id,
                    buildingName: model.building().get('name'),
                    name: model.get('name'),
                    url: model.get('url'),
                    updated_at: WiseMetering.utils.formatDate(model.get('updated_at')),
                    state: { color, state }
                };
            },
            columns: {
                state: {
                    mRender: ({ color, state }) => JST['templates/ui/components/circle']({
                        color: WiseMetering.SemaphorColors[color],
                        title: state.capitalize()
                    }),
                    sWidth: '20px'
                },
                name: i18next.t('common.name'),
                buildingName: i18next.t('glossary.building'),
                url: 'url',
                updated_at: i18next.t('glossary.last_change')
            },
            toolbar_actions: {
                new: {
                    label: i18next.t('ui.actions.new'),
                    view_call: () => new WiseMetering.Views.NewDatelkaConfiguration({
                        collection: WiseMetering.integrationConfigurations,
                        model: new WiseMetering.Model.IntegrationConfiguration({ license_id: this.model.id })
                    })
                },
                edit: {
                    label: i18next.t('ui.actions.edit'),
                    view_call: model => new WiseMetering.Views.EditDatelkaConfiguration({ model })
                },
                show: {
                    label: i18next.t('ui.actions.show'),
                    view_call: model => new WiseMetering.Views.ShowDatelkaConfiguration({ model })
                },
                delete: {
                    view_call: model => new WiseMetering.Views.Modal.Destroy({
                            model,
                            title: () => i18next.t('modal.title.integration_configurations.destroy')
                        }
                    )
                }
            }
        });

        $(this.body.el).html(this.table.el);
    },

    onClose: function() {
        this.stopListening(WiseMetering.integrationConfigurations);

        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    onShow: function() {
        this.listenTo(WiseMetering.integrationConfigurations, 'remove add change', this.createTable.bind(this));

        this.createTable();
    }
});
