module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'mbx_head\' id=\'modal-header\'>\n    <div class=\'mbx_toolbox\'>\n        ';
 if(closable){ ;
__p += '\n            <a class=\'mbx_btn_close\' href=\'#\' id=\'close\'></a>\n        ';
 } ;
__p += '\n    </div>\n    <div id=\'modal-title\'>\n        ' +
((__t = ( title )) == null ? '' : __t) +
'\n    </div>\n</div>\n<div class=\'mbx_body\'>\n    <div id=\'modal-errors\'></div>\n    <div class=\'mbx_cont\' id=\'modal-body\' style=\'height: auto; max-height: 600px; overflow-y: auto;\'></div>\n    <div class=\'mbx_btns\' id=\'modal-footer\'>\n        <div class=\'mbx_btns_pod\'>\n            ';
 if(buttons.indexOf('validate') != -1){ ;
__p += '\n                <input class=\'toolbar_button item_float\' id=\'validate\' type=\'button\' value=\'' +
((__t = ( i18next.t('ui.buttons.validate') )) == null ? '' : __t) +
'\'>\n            ';
 } ;
__p += '\n            ';
 if(buttons.indexOf('open') !== -1){ ;
__p += '\n                <input class=\'toolbar_button item_float\' id=\'open\' type=\'button\' value=\'' +
((__t = ( i18next.t('ui.buttons.open') )) == null ? '' : __t) +
'\'>\n            ';
 } ;
__p += '\n            ';
 if(buttons.indexOf('edit') !== -1){ ;
__p += '\n                <input class=\'toolbar_button item_float\' id=\'edit\' type=\'button\' value=\'' +
((__t = ( i18next.t('ui.buttons.edit') )) == null ? '' : __t) +
'\'>\n            ';
 } ;
__p += '\n            ';
 if(buttons.indexOf('reset') !== -1){ ;
__p += '\n                <input class=\'toolbar_button item_float\' id=\'reset\' type=\'button\' value=\'' +
((__t = ( i18next.t('ui.buttons.reset') )) == null ? '' : __t) +
'\'>\n            ';
 } ;
__p += '\n            ';
 if(buttons.indexOf('yes') !== -1){ ;
__p += '\n                <input class=\'toolbar_button item_float\' id=\'yes\' type=\'button\' value=\'' +
((__t = ( i18next.t('ui.buttons.yes') )) == null ? '' : __t) +
'\'>\n            ';
 } ;
__p += '\n            ';
 if(buttons.indexOf('no') !== -1){ ;
__p += '\n                <input class=\'toolbar_button item_float\' id=\'no\' type=\'button\' value=\'' +
((__t = ( i18next.t('ui.buttons.no') )) == null ? '' : __t) +
'\'>\n            ';
 } ;
__p += '\n            ';
 if(buttons.indexOf('ok') !== -1){ ;
__p += '\n                <input class=\'toolbar_button item_float\' id=\'ok\' type=\'button\' value=\'' +
((__t = ( i18next.t('ui.buttons.ok') )) == null ? '' : __t) +
'\'>\n            ';
 } ;
__p += '\n            ';
 if(buttons.indexOf('save') !== -1){ ;
__p += '\n                <input class=\'toolbar_button item_float\' id=\'save\' type=\'button\' value=\'' +
((__t = ( i18next.t('ui.buttons.save') )) == null ? '' : __t) +
'\'>\n            ';
 } ;
__p += '\n            ';
 if(buttons.indexOf('cancel') !== -1){ ;
__p += '\n                <input class=\'toolbar_button item_float\' id=\'cancel\' type=\'button\' value=\'' +
((__t = ( i18next.t('ui.buttons.cancel') )) == null ? '' : __t) +
'\'>\n            ';
 } ;
__p += '\n            ';
 if(buttons.indexOf('close') !== -1){ ;
__p += '\n                <input class=\'toolbar_button item_float\' id=\'close\' type=\'button\' value=\'' +
((__t = ( i18next.t('ui.buttons.close') )) == null ? '' : __t) +
'\'>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n</div>\n';

}
return __p
}