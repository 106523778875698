WiseMetering.Views.NewInfraspeakIntegration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration',
    template: 'templates/integrations/infraspeak/new',
    title: () => i18next.t('modal.title.integrations.new'),

    ui: {
        assetsList: '#assets-list',
        buildings: '#building_id',
        indicators: '#indicator_id',
        configurations: '#configuration_id',
        indicator: '#indicator_id',
        name: 'input#integration_name',
        search: 'input#asset-search',
        spinner: '#spinner'
    },

    events: {
        'keyup input#asset-search': 'search',
        'change @ui.buildings': 'onBuildingChange'
    },

    initialize: function(options) {
        WiseMetering.Views.Modal.Save.prototype.initialize.call(this, options);

        this.externalAssets = null;
        this.partner_id = WiseMetering.integrationLicenses.get(this.model.get("license_id")).get('partner_id');
    },

    fetchExternalAssets: function(searchText) {
        this.modal.block();

        const payload = {
            license_id: this.model.get('license_id'),
            partner_id: this.partner_id,
            building_id: this.ui.buildings.val(),
            configuration_id: this.ui.configurations.val(),
            indicator_id: this.ui.indicators.val(),
            search_text: searchText
        };

        $.ajax({
            url: `/integrations/external_assets`,
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(payload),
            success: function(data) {
                this.externalAssets = data;
                this.ui.assetsList.empty();
                this.externalAssets.forEach(function(asset, index) {
                    this.ui.assetsList.append(
                        `<input class="vertical-middle" id="integration[assets_attributes][${index}]" name="integration[assets_attributes][]" type="checkbox" value="${index}"></input>`,
                        `&nbsp;`,
                        `<label for='integration[assets_attributes][${index}]'>${asset.name}</label><br/>`
                    );
                }.bind(this));
                this.modal.unblock();
            }.bind(this),
            error: function() {
                WiseMetering.layout.showTipper('error', i18next.t('integrations.validation_failed'));
                $('#save').prop('disabled', true);
                this.modal.unblock();
            }.bind(this)
        });
    },

    getFormData: function() {
        var data = this.formSerializer();
        const assets_attributes = [];
        const currentAssets = this.externalAssets;

        data.assets_attributes.forEach((selected, index) => {
            if (selected) {
                const asset = currentAssets[index];

                const asset_info = {
                    name: asset['name'],
                    asset_reference: asset['asset_reference'],
                    local_reference: asset['local_reference'],
                }
                assets_attributes.push(asset_info);
            }
        });

        data.assets_attributes = assets_attributes;
        data.partner_id = this.partner_id;

        return data;
    },

    onBuildingChange: function () {
        const selectedBuildingId = this.ui.buildings.val();

        if (!selectedBuildingId) {
            this.ui.configurations.empty();
            this.ui.indicators.empty();
            return;
        }

        this.ui.spinner.show();
        const indicators = WiseMetering.utils.optionsFromModels(WiseMetering.indicators.where({ building_id: selectedBuildingId }));
        const configurations = WiseMetering.utils.optionsFromModels(WiseMetering.integrationConfigurations.where({ building_id: selectedBuildingId }));

        this.ui.indicators.html(indicators);
        this.ui.configurations.html(configurations);
    },

    onShow: function() {
        const buildings = WiseMetering.utils.optionsFromModels(WiseMetering.zones.buildings().models);
        const defaultOption = `<option disabled selected>${i18next.t('ui.please_select_option')}</option>`;

        this.ui.buildings.html(defaultOption + buildings);
    },

    search: function(event) {
        if (event && (event.keyCode || event.which) !== 13) {
            return;
        }

        if (this.ui.search.val().length < 3) {
            return;
        }
        this.externalAssets = this.fetchExternalAssets(this.ui.search.val());
    }
});
