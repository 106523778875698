module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'accordion\' style=\'margin-bottom: 0;\'>\n    <div class=\'accordion-group\'>\n        <div class=\'accordion-heading\'>\n            <div class=\'accordion-toggle\'>\n                <div class=\'svg_icon\'>\n                    ' +
((__t = ( WiseMetering.icon('attributes') )) == null ? '' : __t) +
'\n                </div>\n                ' +
((__t = ( i18next.t('glossary.seus') )) == null ? '' : __t) +
'\n            </div>\n        </div>\n        <div class=\'accordion-body\'>\n            <div class=\'accordion-inner\'>\n                ';
 if (analysis) { ;
__p += '\n                    <div class=\'per-box\'>\n                        <dl>\n                            <dt>' +
((__t = ( i18next.t('seus.pearson_correlation_coefficient') )) == null ? '' : __t) +
'\n                                <span data-placement=\'bottom\' data-toggle=\'tooltip\' title="' +
((__t = ( i18next.t('seus.pearson_tooltip') )) == null ? '' : __t) +
'">\n                                    <i class="' +
((__t = (WiseMetering.icons.questionCircle)) == null ? '' : __t) +
'" aria-hidden=\'true\'></i>\n                                </span>\n                            </dt>\n                            <dd>\n                                ' +
((__t = ( pearsonCoefficient )) == null ? '' : __t) +
'\n                                <span style="text-transform:capitalize;color:' +
((__t = ( pearsonEvaluationColor )) == null ? '' : __t) +
';' +
((__t = ( analysis.pearson_evaluation === 'very strong' ? 'font-weight:bold' : '' )) == null ? '' : __t) +
'">\n                                    (' +
((__t = ( analysis.pearson_evaluation )) == null ? '' : __t) +
' ' +
((__t = ( i18next.t('seus.pearson_correlation_coefficient') )) == null ? '' : __t) +
')\n                                </span>\n                            </dd>\n\n                            <dt>' +
((__t = ( i18next.t('seus.r_squared') )) == null ? '' : __t) +
'\n                                <span data-placement=\'bottom\' data-toggle=\'tooltip\' title="' +
((__t = ( i18next.t('seus.r_squared_tooltip') )) == null ? '' : __t) +
'">\n                                    <i class="' +
((__t = (WiseMetering.icons.questionCircle)) == null ? '' : __t) +
'" aria-hidden=\'true\'></i>\n                                </span>\n                            </dt>\n                            <dd>\n                                ' +
((__t = ( rSquared )) == null ? '' : __t) +
'\n                                <span style="text-transform:capitalize;color:' +
((__t = ( rSquaredEvaluationColor )) == null ? '' : __t) +
';' +
((__t = ( analysis.r_squared_evaluation === 'very strong' ? 'font-weight:bold' : '' )) == null ? '' : __t) +
'">\n                                    (' +
((__t = ( i18next.t('seus.r_squared') )) == null ? '' : __t) +
' ' +
((__t = ( analysis.r_squared_evaluation )) == null ? '' : __t) +
')\n                                </span>\n                            </dd>\n\n                            <dt>' +
((__t = ( i18next.t('seus.analyis_period') )) == null ? '' : __t) +
':</dt>\n                            <dd>' +
((__t = ( from )) == null ? '' : __t) +
' ' +
((__t = ( i18next.t('seus.to') )) == null ? '' : __t) +
' ' +
((__t = ( to )) == null ? '' : __t) +
'</dd>\n\n                            <dt>' +
((__t = ( i18next.t('seus.data_points') )) == null ? '' : __t) +
':</dt>\n                            <dd>' +
((__t = ( dataPoints )) == null ? '' : __t) +
'</dd>\n                        </dl>\n                    </div>\n                    <div class=\'per-box\'>\n                        <div id=\'spinner\' align=\'center\' style=\'color:#224C4F;font-size: 20px;\'>\n                            <i class=\'far fa-spinner fa-spin\'></i>\n                        </div>\n                        <div id="regression-chart"></div>\n                    </div>\n                ';
 } else { ;
__p += '\n                    <div class=\'per-box\'>\n                        ' +
((__t = ( i18next.t('seus.no_information_to_display') )) == null ? '' : __t) +
'\n                    </div>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}