WiseMetering.Views.DetailsAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/seus/details',

    events: {
        'click #edit_attributes': 'editAttributes'
    },

    modelEvents: {
        'change': 'render'
    },

    editAttributes: function(event) {
        event.stopPropagation();

        let models = WiseMetering.indicators.where({ building_id: this.model.get('building_id') });
        new WiseMetering.Views.EditSeu({
            indicators: new WiseMetering.Collection.Indicators,
            variables: new WiseMetering.Collection.Indicators(models),
            model: this.model
        });
    },

    serializeData: function() {
        let json = this.model.toJSON();
        json.indicator = this.model.indicator();
        json.variable = this.model.variable();
        json.relevance = this.model.relevance() ? `${this.model.relevance().toFixed(2)}%` : null;
        return json;
    }
});
